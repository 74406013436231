import Icon from "@Atom/Icon";
import TabHeader from "@Atom/TabHeader";
import Toast from "@Atom/Toast";
import { useDebounce } from "@Hooks/useDebounce";
import ModalTableClient from "@Molecule/_modal/TableClient";
import { getAllClient } from "@Services/clients/getAllClient";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ChooseClientPage() {
  const [dataClients, setDataClients] = useState([]);
  const navigate = useNavigate();
  const [isOpenModalClient, setIsOpenModalClient] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isSavedClient, setIsSavedClient] = useState(false);
  const [client, setClient] = useState(
    JSON.parse(localStorage.getItem("currentClient")) || ""
  );

  useEffect(() => {
    setIsOpenModalClient(true);
  }, []);

  const handleSaveClient = () => {
    setIsSavedClient(true);
    if (client) {
      localStorage.setItem("currentClient", JSON.stringify(client));
      setTimeout(() => {
        setIsSavedClient(false);
        navigate("/work-location");
        setIsOpenModalClient(false);
      }, 1500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isSavedClient) {
        setIsSuccessChangeClient(true);
      }
    }, 1600);
  }, [isSavedClient]);

  const [isSuccessChangeClient, setIsSuccessChangeClient] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const debounceQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (
      client?.name !==
      JSON.parse(localStorage.getItem("currentClient"))?.name?.toString()
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [client]);

  setTimeout(() => {
    if (isSavedClient) {
      setIsSuccessChangeClient(true);
    }
  }, 2100);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("currentClient"))?.name?.toString() !==
      client?.name
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [client]);

  useEffect(() => {
    if (
      client?.name !==
      JSON.parse(localStorage.getItem("currentClient"))?.name?.toString()
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [client]);

  const fetchAllClient = useCallback(async () => {
    try {
      const { data } = await getAllClient(page, limit, debounceQuery);
      setDataClients(data);
    } catch (error) {
      console.log("error get data client: ", error);
    }
  }, [page, limit, debounceQuery]);

  useEffect(() => {
    fetchAllClient();
  }, [page, limit, fetchAllClient]);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const templateDataClients = useMemo(() => {
    return {
      data: dataClients?.data?.map((c) => ({
        id: c?._id,
        name: c?.name,
        code: c?.code,
        location: "-",
      })),
      totalData: dataClients?.totalData,
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama Klien" isSorted />,
          renderData: (row) => (
            <div
              onClick={() => {
                setClient(row);
              }}
              style={{ cursor: "pointer" }}
            >
              <span>{row.name}</span>
            </div>
          ),
        },
        {
          name: "location",
          title: <TabHeader text="Lokasi" isSorted />,
          renderData: (row) => <span>{row.location}</span>,
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <>
              <div style={{ opacity: client?.name === row.name ? "1" : "0" }}>
                <Icon icon={"check"} color={"#2C5364"} size={"24"} />
              </div>
            </>
          ),
        },
      ],
    };
  }, [client, dataClients?.data, dataClients?.totalData]);
  return (
    <>
      <Toast
        isopen={isSuccessChangeClient}
        color="green"
        text="Berhasil mengubah klien"
        onClose={() => setIsSuccessChangeClient(false)}
      />

      <ModalTableClient
        isOpenModalClient={isOpenModalClient}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        client={client}
        dataTable={templateDataClients}
        handleSaveClient={handleSaveClient}
        isDisabledBtnSave={isDisabledBtn}
        isSavedClient={isSavedClient}
        setIsOpenModalClient={setIsOpenModalClient}
        isChooseClient
      />
    </>
  );
}
