import { API } from "configs";

export function updateSequencePatrolPoint(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await API.patch(`/checkpoint-admin/sequence`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
