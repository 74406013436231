import { API } from "configs";

export function getAllClient(page = 1, limit = 10, query = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/client?page=${page}&limit=${limit}&search=${query}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
