import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import useOutsideClick from "@Hooks/useOutsideClick";
export default function ListLocationWorkSchedule({ data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const template = useMemo(() => {
    return {
      data: data?.data?.map((l, idx) => ({
        id: idx,
        month: l?.month,
        amountInMorning: l?.counts.P,
        amountInAfternoon: l?.counts.S,
        amountInEvening: l?.counts.M,
        amountOff: l?.counts.OFF,
        amountNonShift: l?.counts.NS,
        amountPersonil: "-",
        status: l?.isUploaded ? "Sudah Diatur" : "Belum Diatur",
      })),

      columns: [
        {
          name: "month",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Bulan" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.month}</span>
            </div>
          ),
        },
        {
          name: "amountInMorning",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Masuk Pagi" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInMorning}</span>
            </div>
          ),
        },
        {
          name: "amountInAfternoon",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Masuk Siang" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInAfternoon}</span>
            </div>
          ),
        },
        {
          name: "amountInEvening",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Masuk Malam" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInEvening}</span>
            </div>
          ),
        },
        {
          name: "amountOff",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Off" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountOff}</span>
            </div>
          ),
        },

        {
          name: "",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Non Shift" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountNonShift}</span>
            </div>
          ),
        },
        {
          name: "amountPersonil",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Anggota" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountPersonil}</span>
            </div>
          ),
        },
        {
          name: "status",
          width: "150px",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Status" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.status} status={row.status.toLowerCase()}>
              <span>{row?.status}</span>
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
  }, [data]);

  const refYear = useRef(null);
  const outsideClick = useOutsideClick(refYear);
  const [isOpenFilterListYear, setIsOpenFilterListYear] = useState(false);
  useEffect(() => {
    if (isOpenFilterListYear && outsideClick) {
      setIsOpenFilterListYear(false);
    }
  }, [isOpenFilterListYear, outsideClick]);

  const activeFilterYear = +sessionStorage.getItem("yearScheduleWorkLocation");
  const listYearFilter = [2024, 2025, 2026, 2027];
  const [selectedYear, setSelectedYear] = useState(
    activeFilterYear || listYearFilter[0]
  );

  useEffect(() => {
    sessionStorage.setItem("yearScheduleWorkLocation", selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    navigate(`${pathname}?year=${selectedYear}`);
  }, [pathname, selectedYear, navigate]);

  const nameLocation = sessionStorage.getItem("nameDetailInfoWorkLocation");

  useEffect(() => {
    navigate(`${pathname}?q=q&year=${selectedYear}`, { replace: true });
  }, [navigate, pathname, selectedYear]);

  return (
    <div className={Styles.wrapperListWorkSchedule}>
      <header className={`${Styles.header}`}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/work-location")}
            className={Styles.back}
          />
          <h3>{nameLocation}</h3>
        </div>
        <div
          className={Styles.selectedDate}
          onClick={() => setIsOpenFilterListYear(!isOpenFilterListYear)}
          is_open={isOpenFilterListYear.toString()}
        >
          <Icon icon={"calendar"} className={Styles.settings} />
          <span>{selectedYear}</span>
          {isOpenFilterListYear && (
            <div className={Styles.wrapFilterYear} ref={refYear}>
              {listYearFilter.map((el, idx) => (
                <span
                  is_active={(el === selectedYear).toString()}
                  onClick={() => setSelectedYear(el)}
                  key={idx}
                >
                  {el}
                </span>
              ))}
            </div>
          )}
        </div>
      </header>
      <Table
        className={Styles.tableRadius}
        data={template.data}
        columns={template.columns}
        withNumbering
        widthNumber="20px"
        onClickRow={(row) => {
          sessionStorage.setItem("selectedMonthNumber", row.id);
          navigate(`detail/${row.month}`);
        }}
        classNameCustomTh={Styles.thSticky}
        classNameCustomTd={Styles.tdSticky}

        // expandComponent={(row) => (
        //   <div className={Styles.wrapperExpand}>
        //     <div className={Styles.listTableMobile}>
        //       <div className={Styles.totalVoucherM}>
        //         <h2>Total Voucher</h2>
        //         <p>{row?.totalVoucher}</p>
        //       </div>
        //       <di v className={Styles.priceM}>
        //         <h2>Price</h2>
        //         <p>{row?.price}</p>
        //       </di>
        //     </div>
        //     <div className={Styles.buttonGenerate}>Generate</div>
        //   </div>
        // )}
      />
    </div>
  );
}
