import React, { Fragment, Suspense, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import EmptyHandlerWorkSchedule from "@Atom/EmptyHandlerWorkSchedule";
import Icon from "@Atom/Icon";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { useNavigate } from "react-router-dom";
export default function ListWorkSchedule() {
  const navigate = useNavigate();
  const cardTemplate = useMemo(() => {
    return [
      {
        id: "1",
        image: "https://placehold.co/281x200/salmon/white",
        name: "Modena Gancy 1",
        personil: "40",
      },
      {
        id: "2",
        image: "https://placehold.co/281x200/salmon/white",
        name: "Modena Gancy 2",
        personil: "40",
      },
      {
        id: "3",
        image: "https://placehold.co/281x200/salmon/white",
        name: "Modena Gancy 3",
        personil: "40",
      },
      {
        id: "4",
        image: "https://placehold.co/281x200/salmon/white",
        name: "Modena Gancy 4",
        personil: "40",
      },
      {
        id: "5",
        image: "https://placehold.co/281x200/salmon/white",
        name: "Modena Gancy 5",
        personil: "40",
      },
      {
        id: "6",
        image: "https://placehold.co/281x200/salmon/white",
        name: "Modena Gancy 6",
        personil: "40",
      },
    ];
  }, []);
  const [isOpenAddLocation, setIsOpenAddLocation] = useState(false);
  const listDataWorkSchedule = [];
  return (
    <div className={Styles.containerListWorkSchedule}>
      <div className={Styles.maxCenteringContain}>
        <div className={Styles.titlePage}>
          <h2>Jadwal Kerja</h2>
        </div>
        {listDataWorkSchedule.length === 1 ? (
          <EmptyHandlerWorkSchedule />
        ) : (
          <div className={Styles.cardLocation}>
            {cardTemplate.map((el, idx) => (
              <Fragment key={idx}>
                <div className={Styles.card}>
                  <img alt="image-location" src={el?.image} />
                  <div className={Styles.description}>
                    <div className={Styles.personil}>
                      <strong>{el?.name}</strong>
                      <div>
                        <Icon icon={"people"} className={Styles.peopleIcon} />
                        <span>{el?.personil} Personil</span>
                      </div>
                    </div>
                    <div
                      className={Styles.btnCard}
                      onClick={() =>
                        navigate(`detail/${el?.id}?scheduleName=${el?.name}`)
                      }
                    >
                      <span>Atur Jadwal Kerja</span>
                      <Icon icon={"arrow-back"} className={Styles.arrowIcon} />
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
            {isOpenAddLocation && (
              <Suspense fallback={<p>Loading component...</p>}>
                <ModalAddLocation
                  isOpen={isOpenAddLocation}
                  onClose={() => setIsOpenAddLocation(false)}
                />
              </Suspense>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
