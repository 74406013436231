import React, { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { useNavigate } from "react-router-dom";
import Icon from "@Atom/Icon";
import ModalStatusReport from "../Activity/ModalStatus";

export default function ReportPatrol() {
  const dataLocations = [];
  const [isOpenModalAddReport, setIsOpenModalAddReport] = useState();
  const navigate = useNavigate();
  const cardTemplate = useMemo(() => {
    return [
      {
        id: "1",
        image: "https://placehold.co/281x200/black/white",
        name: "Modena Gancy 1",
        personil: "40",
      },
      {
        id: "2",
        image: "https://placehold.co/281x200/black/white",
        name: "Modena Gancy 2",
        personil: "40",
      },
      {
        id: "3",
        image: "https://placehold.co/281x200/black/white",
        name: "Modena Gancy 3",
        personil: "40",
      },
      {
        id: "4",
        image: "https://placehold.co/281x200/black/white",
        name: "Modena Gancy 4",
        personil: "40",
      },
      {
        id: "5",
        image: "https://placehold.co/281x200/black/white",
        name: "Modena Gancy 5",
        personil: "40",
      },
      {
        id: "6",
        image: "https://placehold.co/281x200/black/white",
        name: "Modena Gancy 6",
        personil: "40",
      },
    ];
  }, []);
  const [isOpenAddLocation, setIsOpenAddLocation] = useState(false);
  const [isOpenStatusAlert, setIsOpenStatusAlert] = useState(false);
  const [isOpenStatusStandBy, setIsOpenStatusStandBy] = useState(false);

  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (isOpenStatusAlert || isOpenStatusStandBy) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [isOpenStatusAlert, isOpenStatusStandBy]);

  return (
    <div className={Styles.containerReportActivity}>
      {dataLocations.length === 1 ? (
        <>
          <CustomEmptyHandlerPage
            description="Silahkan lakukan penambahan lokasi kerja terlebih dahulu untuk melihat laporan aktifitas"
            onClick={() => setIsOpenModalAddReport(true)}
          />
          {isOpenModalAddReport && (
            <ModalAddLocation
              isOpen
              isFilled
              onClose={() => setIsOpenModalAddReport(false)}
            />
          )}
        </>
      ) : (
        <div className={Styles.mainContainListReport}>
          <strong>Laporan Patroli</strong>

          <div className={Styles.informationPersonil}>
            <div className={Styles.card}>
              <div>
                <div style={{ background: "#F9ECD3", borderColor: "#E78B00" }}>
                  <Icon icon={"Information"} color={"#E78B00"} size={"32"} />
                </div>
                <div>
                  <span>Status Siaga (Bulan Ini)</span>
                  <h3>40</h3>
                </div>
              </div>
              <div onClick={() => setIsOpenStatusStandBy(true)}>
                <Icon
                  icon={"maximize"}
                  color={"#2C5364"}
                  size={"20"}
                  className={Styles.icon}
                />
              </div>
            </div>
            <div
              className={Styles.card}
              style={{ borderBottom: "3px solid #D42701" }}
            >
              <div>
                <div style={{ background: "#F2E5E2" }}>
                  <Icon
                    icon={"information-alert"}
                    color={"#D42701"}
                    size={"32"}
                  />
                </div>
                <div>
                  <span>Status Waspada (Bulan Ini)</span>
                  <h3>40</h3>
                </div>
              </div>
              <div onClick={() => setIsOpenStatusAlert(true)}>
                <Icon
                  icon={"maximize"}
                  color={"#2C5364"}
                  size={"20"}
                  className={Styles.icon}
                />
              </div>
            </div>
          </div>

          {isOpenStatusStandBy && (
            <ModalStatusReport
              isTransition={isTransition}
              onClose={() => {
                setIsOpenStatusStandBy(false);
              }}
              isStandBy={isOpenStatusStandBy}
              textHead={"Daftar Laporan Dengan Status Siaga"}
            />
          )}

          {isOpenStatusAlert && (
            <ModalStatusReport
              isTransition={isTransition}
              onClose={() => {
                setIsOpenStatusAlert(false);
              }}
              textHead={"Daftar Laporan Dengan Status Waspada"}
            />
          )}

          <div className={Styles.cardLocation}>
            {cardTemplate.map((el, idx) => (
              <Fragment key={idx}>
                <div className={Styles.card}>
                  <img alt="image-location" src={el?.image} />
                  <div className={Styles.description}>
                    <div className={Styles.personil}>
                      <strong>{el?.name}</strong>
                      <div>
                        <Icon icon={"people"} className={Styles.peopleIcon} />
                        <span>{el?.personil} Personil</span>
                      </div>
                    </div>
                    <div
                      className={Styles.btnCard}
                      onClick={() =>
                        navigate(`detail/${el?.id}?locationName=${el?.name}`)
                      }
                    >
                      <span>Lihat Laporan</span>
                      <Icon icon={"arrow-back"} className={Styles.arrowIcon} />
                    </div>
                  </div>
                </div>
                {/* {cardTemplate.length === idx + 1 && (
                  <div
                    className={`${Styles.card} ${Styles.addBtnCard}`}
                    onClick={() => setIsOpenAddLocation(true)}
                  >
                    <Icon icon={"add-circle"} color={"#2C5364"} size={"32"} />
                    <span style={{ fontSize: "14px" }}>Tambah Lokasi</span>
                  </div>
                )} */}
              </Fragment>
            ))}
          </div>
          {isOpenAddLocation && (
            <Suspense fallback={<p>Loading component...</p>}>
              <ModalAddLocation
                isOpen={isOpenAddLocation}
                onClose={() => setIsOpenAddLocation(false)}
              />
            </Suspense>
          )}
        </div>
      )}
    </div>
  );
}
