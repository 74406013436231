// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SECURITY_LOGO: require("@Assets/Images/logo-permata-security.png"),
  MWS_LOGO: require("@Assets/Images/mws-logo.png"),
  PDF: require("@Assets/Images/pdf.png"),
  AVA_DEFAULT: require("@Assets/Images/ava-default.png"),
  BG_LINEAR_SECURITY: require("@Assets/Images/bg-permata-security.png"),
  BUILDING_EMPTY: require("@Assets/Images/building-for-empty.png"),
  RAILWAY_STATION: require("@Assets/Images/railway-station.png"),
  POINT_NAVIGATION: require("@Assets/Images/point-navigation.png"),
  EXAMPLE_QR: require("@Assets/Images/example-qr.png"),
  EMPTY_PERSONAL_GUARD: require("@Assets/Images/empty-guard-person.png"),
  LOADER_GRADIENT: require("@Assets/Images/loader-gradient.png"),
  GROUP_DOT_PDF: require("@Assets/Images/mask-dot-group.png"),
  RECTANGLE_PDF_LEFT: require("@Assets/Images/Rectangle-pdf-left.png"),
  RECTANGLE_PDF_RIGHT: require("@Assets/Images/Rectangle-pdf-right.png"),
  RECTANGLE_PDF_LEFT_POST: require("@Assets/Images/Rectangle-pdf-left-pos.png"),
  RECTANGLE_PDF_RIGHT_POST: require("@Assets/Images/Rectangle-pdf-right-pos.png"),
  MWS_SECURITY_LOGO: require("@Assets/Images/MWS-SECURITY-LOGO.png"),
  SCHEMA_ABSENCE: require("@Assets/Images/empty-schema-setting.png"),
};
