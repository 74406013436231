import React from "react";
import Styles from "./style.module.scss";
import Tab from "@Atom/Tab";
export default function Tabs({
  listTabs = ["tabs 1", "tabs 2", "tabs 3"],
  setIsActive = () => { },
  isActive,
  style,
  isV2 = false,
  options = []
}) {
  const handleActiveTabs = (idx) => {
    setIsActive(idx);
  };

  if (isV2) {

    return (
      <div className={Styles.container}>
        {options?.map((o, oI) => (
          <Tab
            key={oI}
            data={o}
          />
        ))}
      </div>
    )
  }
  return (
    <div className={Styles.wrapperTabs} style={style}>
      {listTabs?.map((item, idx) => (
        <div
          key={idx}
          className={Styles.wrapper}
          isactive={(isActive === idx)?.toString()}
          onClick={() => handleActiveTabs(idx)}
        >
          <h2>{item}</h2>
          <span className={Styles.tabIndicator}></span>
        </div>
      ))}
    </div>
  );
}
