import SideBar from "@Molecule/SideBar";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "moment/locale/id";

export default function MainLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const lastPathname = sessionStorage.getItem("savedPathname");
    if (lastPathname && lastPathname !== pathname) {
      navigate(lastPathname);
    }
  }, [navigate, pathname]);

  setTimeout(() => {
    sessionStorage.removeItem("savedPathname");
  }, 500);

  return (
    <SideBar>
      <Outlet />
    </SideBar>
  );
}
