import Styles from "./style.module.scss";

export default function Skeleton({ h = "20px", w = "100%" }) {
  return (
    <div
      className={Styles.wrapperLoadSkeleton}
      style={{ height: h, width: w }}
    ></div>
  );
}
export const CircleSkeleton = ({ h = "20px", w = "100%" }) => {
  return (
    <div
      className={Styles.circleSkeleton}
      style={{ height: h, width: w }}
    ></div>
  );
};

export const BoxSkeleton = ({ h = "40px", w = "" }) => {
  return (
    <div className={Styles.boxSkeleton} style={{ height: h, width: w }}></div>
  );
};
