import Modal from "@Atom/Modal";
import Spinner from "@Atom/Spinner";
import React from "react";
import Styles from "./style.module.scss";
export default function LoadingSpinner({
  text = "Loading...",
  description = "Harap tungggu data sedang ditambahkan",
}) {
  return (
    <Modal isOpen>
      <div className={Styles.wrapLoadingSpinner}>
        <Spinner width="50px" />
        <div className={Styles.wrapText}>
          <strong className={Styles.titleLoad}>{text}</strong>
          <p className={Styles.description}>{description} </p>
        </div>
      </div>
    </Modal>
  );
}
