import Skeleton from "@Atom/Skeleton";
import React from "react";
import Styles from "./style.module.scss";
import SkeletonTable from "@Molecule/Skeleton/Table";
export function SkeletonListMembersLocation() {
  return (
    <div className={Styles.viewerSkeletonCards}>
      <Skeleton w="200px" />
      <div className={Styles.viewRow}>
        <Skeleton h="30px" w="130px" />
        <Skeleton h="30px" w="130px" />
      </div>
      <div className={Styles.between}>
        <div className={Styles.viewRow}>
          <Skeleton h="30px" w="250px" />
          <Skeleton h="30px" w="100px" />
        </div>
        <div>
          <Skeleton h="30px" w="200px" />
        </div>
      </div>

      <SkeletonTable listLength={12} />
    </div>
  );
}
