import Button from "@Atom/Button";
import DatePicker from "@Atom/DatePicker";
import InputField from "@Atom/InputField";
import React, { useEffect } from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import Icon from "@Atom/Icon";
export default function FormDataStudied({
  setInputDataStudied = () => {},
  inputDataStudied = [],
  handleAddStudied = () => {},
  handleDeleteFormStudied = () => {},
  idx = 0,
  isErrorFormStudied,
  setIsErrorFormStudied = () => {},
  topRef,
}) {
  const handleChangeInput = (idx, name, value) => {
    setInputDataStudied((prev) => {
      const updatedData = [...prev];
      updatedData[idx] = {
        ...updatedData[idx],
        [name]: value,
      };
      return updatedData;
    });
    setIsErrorFormStudied((prevErrors) => {
      const updatedDataError = [...prevErrors];
      updatedDataError[idx] = {
        ...updatedDataError[idx],
        [name]: false,
      };
      return updatedDataError;
    });
  };

  const { width } = useWindowSize();
  const dataDropdownStudies = ["Formal", "Non Formal"];
  const isNonFormal = inputDataStudied[idx]?.typeStudy === "Non Formal";
  useEffect(() => {
    setInputDataStudied((prev) => {
      const updatedData = [...prev];
      updatedData[idx] = {
        ...updatedData[idx],
        typeStudy: updatedData[idx]?.typeStudy || "",
        dateStartJoin: updatedData[idx]?.dateStartJoin || "",
        dateEndJoin: updatedData[idx]?.dateEndJoin || "",
        nameUniversity: updatedData[idx]?.nameUniversity || "",
        faculty: updatedData[idx]?.faculty || "",
        ipk: updatedData[idx]?.ipk || "",
        noDiploma: updatedData[idx]?.noDiploma || "",
        city: updatedData[idx]?.city || "",
      };
      return updatedData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDataStudied[idx]?.typeStudy, idx, setInputDataStudied]);

  return (
    <section className={Styles.containInput} ref={idx === 0 ? topRef : null}>
      <div className={Styles.sticky}>
        <div>
          <h2>Informasi Data Pendidikan {idx + 1}</h2>
          {inputDataStudied?.length > 1 && (
            <Icon
              icon={"trash"}
              className={Styles.icon}
              onClick={handleDeleteFormStudied}
            />
          )}
        </div>
        <div className={Styles.divider}></div>
      </div>

      <InputField
        isLabel
        textLabel="Jenis Pendidikan"
        isRequired
        placeholder="Pilih jenis pendidikan"
        isDropdown
        setValue={(val) => handleChangeInput(idx, `typeStudy`, val)}
        value={inputDataStudied[idx]?.typeStudy}
        dataMapDropdown={dataDropdownStudies}
        isError={isErrorFormStudied[idx]?.typeStudy}
        textError="Jenis Pendidikan Belum di pilih"
      />
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <DatePicker
          textLabel={isNonFormal ? "Dari" : "Tanggal Mulai"}
          placeholder="Pilih Tanggal"
          isAddLabel
          isRequired
          setValue={(val) => handleChangeInput(idx, "dateStartJoin", val)}
          value={inputDataStudied[idx]?.dateStartJoin}
          isError={isErrorFormStudied[idx]?.dateStartJoin}
          textError={"Tanggal Mulai Belum di isi"}
        />
        <DatePicker
          textLabel={isNonFormal ? "Sampai" : "Tanggal Berakhir"}
          placeholder="Pilih Tanggal"
          isAddLabel
          isRequired
          setValue={(val) => handleChangeInput(idx, "dateEndJoin", val)}
          value={inputDataStudied[idx]?.dateEndJoin}
          textError={"Tanggal Berakhir Belum di isi"}
          isError={isErrorFormStudied[idx]?.dateEndJoin}
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel={
            isNonFormal ? "Nama Penyelenggara" : "Nama Sekolah/Universitas"
          }
          isRequired
          placeholder="Nama sekolah atau universitas"
          setValue={(val) => handleChangeInput(idx, "nameUniversity", val)}
          value={inputDataStudied[idx]?.nameUniversity}
          isError={isErrorFormStudied[idx]?.nameUniversity}
          textError={
            isNonFormal
              ? "Nama Penyelenggara Belum di isi"
              : "Nama Sekolah/Universitas Belum di isi"
          }
        />
        <InputField
          isLabel
          textLabel={isNonFormal ? " Nama Kursus" : "Fakultas/Jurusan"}
          isRequired
          placeholder="Fakultas Atau Jurusan"
          setValue={(val) => handleChangeInput(idx, "faculty", val)}
          value={inputDataStudied[idx]?.faculty}
          isError={isErrorFormStudied[idx]?.faculty}
          textError={
            isNonFormal
              ? "Nama Kursus Belum di isi"
              : "Fakultas/Jurusan Belum di isi"
          }
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel={isNonFormal ? "Kode Sertifikat" : "IPK/NEM"}
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput(idx, "ipk", val)}
          value={inputDataStudied[idx]?.ipk}
          isError={isErrorFormStudied[idx]?.ipk}
          textError={"IPK/NEM Belum di isi"}
        />
        <InputField
          isLabel
          textLabel={isNonFormal ? "Kota" : "Nomor Ijazah"}
          isRequired
          placeholder="0"
          setValue={(val) =>
            handleChangeInput(idx, isNonFormal ? "city" : "noDiploma", val)
          }
          value={
            isNonFormal
              ? inputDataStudied[idx]?.city
              : inputDataStudied[idx]?.noDiploma
          }
          isError={
            isNonFormal
              ? isErrorFormStudied[idx]?.city
              : isErrorFormStudied[idx]?.noDiploma
          }
          textError={
            isNonFormal ? "Kota Belum di isi" : "Nomor Ijazah Belum di isi"
          }
        />
      </div>
      {!isNonFormal && (
        <div
          style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
        >
          <InputField
            isLabel
            textLabel="Kota"
            isRequired
            placeholder="Kota"
            setValue={(val) => handleChangeInput(idx, "city", val)}
            value={inputDataStudied[idx]?.city}
            isError={isErrorFormStudied[idx]?.city}
            textError="Kota Belum di isi"
          />
        </div>
      )}
      {idx === inputDataStudied.length - 1 && (
        <Button
          style={{
            width: "100%",
            justifyContent: "center",
            background: "white",
            color: "#2C5364",
          }}
          isAddButton
          isborder
          text="Tambah Pendidikan"
          onClick={handleAddStudied}
          icon={"add-circle"}
          colorIcon={"#2C5364"}
        />
      )}
    </section>
  );
}
