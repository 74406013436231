import { API } from "configs";

export function getDetailInfoWorkLocation(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/location/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
