export const dataToSendPersonal = {
  name: "",
  telephone: "",
  email: "",
  nik: "",
  dateNik: "",
  isLifeTimeNik: false,
  placeOfBirth: "",
  dateOfBirth: "",
  gender: "",
  religion: "",
  bodyHeight: "",
  bloodType: "",
  maritalStatus: "",
  npwp: "",
  birthMothersName: "",
  address: "",
  rt: "",
  rw: "",
  village: "",
  district: "",
  regency: "",
  province: "",
  postCode: "",
  domicilies: {
    address: "",
    rt: "",
    rw: "",
    village: "",
    district: "",
    regency: "",
    province: "",
    postCode: "",
  },
  dataFamiliesAndContact: {
    kk: "",
    name: "",
    contactRelations: "",
    telephone: "",
    address: "",
  },
  familiesAndContactNotTheSameHouse: {
    name: "",
    contactRelations: "",
    telephone: "",
    address: "",
  },
  nameAccountBank: "",
  bank: "",
  bankNumber: "",
  facebook: "",
  instagram: "",
  twitter: "",
  linkedIn: "",
  hobby: "",
  clinic: "",
};

export const dataFamilies = {
  families1: {
    contactRelations: "",
    name: "",
    placeOfBirth: "",
    dateOfBirth: "",
    nik: "",
    gender: "",
    jobStatus: "",
  },
  families2: {
    contactRelations: "",
    name: "",
    placeOfBirth: "",
    dateOfBirth: "",
    nik: "",
    gender: "",
    jobStatus: "",
  },
};

export const dataStudy = [
  {
    id: "1",
    typeStudy: "",
    dateStartJoin: "",
    dateEndJoin: "",
    nameUniversity: "",
    faculty: "",
    ipk: "",
    noDiploma: "",
    city: "",
  },
];

export const dataJobs = [
  {
    id: "1",
    dateStartJoin: "",
    dateEndJoin: "",
    companyName: "",
    position: "",
    takeSallary: "",
    contactCompany: "",
    jobDesc: "",
  },
];

export const dataPrestation = [
  {
    id: "1",
    yearOfAchive: "",
    prestation: "",
    description: "",
  },
];
