import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";
import React from "react";
import Styles from "./style.module.scss";
export function SkeletonSchemaSetting() {
  return (
    <div className={Styles.viewerSkeletonCards}>
      <div className={Styles.between}>
        <Skeleton h="30px" w="300px" />
        <Skeleton h="30px" w="200px" />
      </div>
      <div className={Styles.wrapCards}>
        {Array.from({ length: 8 }).map((_, idx) => (
          <BoxSkeleton h="200px" w="100%" key={idx} />
        ))}
      </div>
    </div>
  );
}
