import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import React from "react";

export default function Tooltip({
  text = "Text Hovered",
  isActive = false,
  children,
  width = "unset",
  whiteSpace = "noWrap",
  ...props
}) {
  return (
    <div className={Styles.wrapperTooltip}>
      <div {...props} className={Styles.target}>
        {children}
      </div>
      {!isActive && (
        <div
          className={Styles.toolTip}
          style={{ width: width, whiteSpace: whiteSpace }}
        >
          <span>{text}</span>
          <Icon icon={"popover-arrow-left"} className={Styles.popover} />
        </div>
      )}
    </div>
  );
}
