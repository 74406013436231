import React, { useId, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
export default function SearchBar({
  placeholder = "Search",
  setValue = () => {},
  value,
  onClickSearchIcon = () => {},
  background = "#fff",
  size = "", // normal | m | s |
  ...props
}) {
  const handleChange = (e) => {
    setValue(e?.target?.value);
  };
  const searchField = useId();
  const focusRef = useRef(null);
  const [isFocusInput, setIsFocusInput] = useState(false);

  return (
    <div
      className={Styles.wrapperSearch}
      onClick={() => {
        focusRef.current.focus();
      }}
      style={{ background: background }}
      size_searchbar={size}
      isfocus={isFocusInput.toString()}
    >
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        id={searchField}
        ref={focusRef}
        onFocus={() => setIsFocusInput(true)}
        onBlur={() => setIsFocusInput(false)}
        {...props}
      />
      <Icon
        icon={"search-normal"}
        size={"16"}
        color={"#BBC3CE"}
        onClick={onClickSearchIcon}
        className={Styles.iconSearch}
      />
    </div>
  );
}
