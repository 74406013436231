import Button from "@Atom/Button";
import DatePicker from "@Atom/DatePicker";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import React from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";

export default function FormDataPrestation({
  idx = 0,
  handleDeleteFormPrestation = () => {},
  handleAddFormPrestation = () => {},
  setInputDataPrestation = () => {},
  inputDataPrestation = [],
  setAddPrestation = () => {},
  isErrorFormPrestation,
  setIsErrorFormPrestation = () => {},
  topRef,
}) {
  const handleChangeInput = (idx, name, value) => {
    setInputDataPrestation((prev) => {
      const updatedData = [...prev];
      updatedData[idx] = {
        ...updatedData[idx],
        [name]: value,
      };
      return updatedData;
    });
    setIsErrorFormPrestation((error) => {
      const errorUpdate = [...error];
      errorUpdate[idx] = {
        ...errorUpdate[idx],
        [name]: false,
      };
      return errorUpdate;
    });
  };

  const { width } = useWindowSize();

  return (
    <section className={Styles.containInput} ref={idx === 0 ? topRef : null}>
      <div className={Styles.sticky}>
        <div>
          <h2>Informasi Data Prestasi {idx + 1}</h2>
          <Icon
            icon={"trash"}
            className={Styles.icon}
            onClick={
              inputDataPrestation?.length === 1
                ? setAddPrestation
                : handleDeleteFormPrestation
            }
          />
        </div>
        <div className={Styles.divider}></div>
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <DatePicker
          textLabel="Tahun Pencapaian"
          placeholder="Pilih bulan dan tahun"
          isAddLabel
          isRequired
          value={inputDataPrestation[idx]?.yearOfAchive}
          setValue={(val) => handleChangeInput(idx, "yearOfAchive", val)}
          isError={isErrorFormPrestation[idx]?.yearOfAchive}
          textError="Tahun Pencapaian belum di pilih"
        />
        <InputField
          isLabel
          textLabel="Prestasi yang dimiliki"
          isRequired
          placeholder="Prestasi yang dimiliki"
          value={inputDataPrestation[idx]?.prestation}
          setValue={(val) => handleChangeInput(idx, "prestation", val)}
          isError={isErrorFormPrestation[idx]?.prestation}
          textError="Prestasi belum di isi"
        />
      </div>
      <InputField
        isLabel
        textLabel="Keterangan"
        isRequired
        placeholder="Keterangan"
        isTextArea
        value={inputDataPrestation[idx]?.description}
        setValue={(val) => handleChangeInput(idx, "description", val)}
        isError={isErrorFormPrestation[idx]?.description}
        textError="Keterangan belum di isi"
      />
      {idx === inputDataPrestation.length - 1 && (
        <Button
          style={{
            width: "100%",
            justifyContent: "center",
            background: "white",
            color: "#2E3192",
          }}
          isAddButton
          isborder
          text="Tambah Prestasi"
          onClick={handleAddFormPrestation}
          icon={"add-circle"}
          colorIcon={"#2E3192"}
        />
      )}
    </section>
  );
}
