import useWindowSize from '@Hooks/useWindowSize'
import Styles from './style.module.scss'

export default function Text({
  fontFamily = 'Roboto, sans-serif',
  minFontSize = 12,
  maxFontSize = 16,
  minFontWeight = 400,
  maxFontWeight = 500,
  lineHeight = '100%',
  fontStyle = 'normal',
  children,
  className = '',
  color = 'inherit',
  ...props
}) {

  const { width } = useWindowSize()

  return (
    <p
      {...props}
      className={`${Styles.text} ${className}`}
      style={{
        fontSize: `calc(${minFontSize}px + (${maxFontSize} - ${minFontSize}) * ((100vw - 390px) / (1440 - 390)))`,
        fontWeight: width > 768 ? maxFontWeight : minFontWeight,
        lineHeight,
        fontStyle,
        color
      }}
    >
      {children}
    </p>
  )
}