import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "@Molecule/Tabs";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import SearchBar from "@Atom/SearchBar";
import { fileBaseUrl } from "configs";
import ModalAddGuardPos from "@Molecule/_modal/AddGuardPos";
import { updateGuardPost } from "@Services/GuardPost/updateGuardPost";
import Toast from "@Atom/Toast";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import CustomDatePicker from "@Atom/CustomDatePicker";
const TagQR = lazy(() => import("@Molecule/_modal/TagQR"));
const ModalActivity = lazy(() => import("./ModalDetail/Activity"));
const HistoryActivity = lazy(() => import("./HistoryActivity"));
const ModalHandOver = lazy(() => import("./ModalDetail/HandOver"));
export default function DetailGuardPos({ data }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [isOpenInfoLocation, setIsOpenInfoLocation] = useState(false);
  const listTabs = ["Detail Pos", "Riwayat Aktifitas", "Riwayat Serah Terima"];
  const [activeTab, setActivetab] = useState(
    +sessionStorage.getItem("detailActiveTabsGuardPos") || 0
  );
  const templateDataPersonil = useMemo(() => {
    return {
      data: [
        {
          id: 1,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 6,
        },
        {
          id: 2,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 10,
        },
        {
          id: 3,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 9,
        },
        {
          id: 4,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 5,
        },
      ],
      columns: [
        {
          name: "name",
          title: <TabHeader isSorted text="Nama" />,
        },
        {
          name: "shift",
          title: <TabHeader isSorted text="Shift" />,
          hideOnMobile: true,
        },
        {
          name: "progressActivity",
          title: <TabHeader isSorted text="Progress Aktifitas" />,
          renderData: (row) => (
            <div className={Styles.progress}>
              <div className={Styles.loadProgress}>
                <div
                  style={{ width: (row.progressActivity * 100) / 10 + "%" }}
                ></div>
              </div>
              <span>{row.progressActivity} / 10</span>
            </div>
          ),
        },
      ],
    };
  }, []);

  const templatePosInventory = useMemo(() => {
    return {
      data: data?.inventories?.map((inv) => ({
        id: inv?.num,
        name: inv?.name,
        condition: inv?.remark,
      })),
      columns: [
        {
          name: "name",
          title: <TabHeader isSorted text="Nama Barang" />,
        },
        {
          name: "condition",
          title: (
            <div className={Styles.flexEnd}>
              <TabHeader isSorted text="Kondisi Barang" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.flexEnd}>
              <span
                className={Styles.condition}
                condition={row.condition?.toLowerCase()}
              >
                {row.condition?.toLowerCase() === "good"
                  ? "Baik"
                  : row.condition?.toLowerCase() === "damaged"
                  ? "Rusak"
                  : "Hilang"}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [data?.inventories]);

  useEffect(() => {
    sessionStorage.setItem("detailActiveTabsGuardPos", activeTab);
  }, [activeTab]);

  const templatePosRetrivied = useMemo(() => {
    return {
      data: [
        {
          id: 1,
          name: "KTP",
          amount: "4",
        },
        {
          id: 2,
          name: "KTP",
          amount: "4",
        },
        {
          id: 3,
          name: "Paket",
          amount: "4",
        },
        {
          id: 4,
          name: "KTP",
          amount: "4",
        },
      ],
      columns: [
        {
          name: "name",
          title: <TabHeader isSorted text="Nama Barang" />,
          renderData: (row) => (
            <div className={Styles.withPhoto}>
              <img alt="" src="https://placehold.co/32x32" />
              <span>{row.name}</span>
            </div>
          ),
        },
        {
          name: "amount",
          title: (
            <div className={Styles.flexEnd}>
              <TabHeader isSorted text="Jumlah Barang" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.flexEnd} style={{ paddingRight: "1rem" }}>
              <span>{row.amount}</span>
            </div>
          ),
        },
      ],
    };
  }, []);

  const templateDataActivities = useMemo(() => {
    return Array.from({ length: 10 }).map((el, idx) => ({
      id: idx,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: idx === 1 ? "Aman" : "Siaga",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
    }));
  }, []);

  const [openModalActivity, setOpenModalActivity] = useState(false);
  const [showDataModalActivity, setShowDataModalActivity] = useState([]);
  useEffect(() => {
    const filterDataModalActivity = templateDataActivities.filter(
      (el) => el.id === openModalActivity
    );
    setShowDataModalActivity(filterDataModalActivity);
  }, [openModalActivity, templateDataActivities]);

  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (openModalActivity === showDataModalActivity[0]?.id) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [openModalActivity, showDataModalActivity]);

  const [openModalHandOver, setOpenModalHandOver] = useState(false);
  const [isOpenModalQR, setIsOpenModalQR] = useState(false);

  const backPath = pathname.split("/").slice(0, -1).join("/");

  //

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  const [inputLocation, setInputLocation] = useState({
    image: data?.imageURI,
    name: data?.name,
    personil: data?.totalPersonnel,
    fieldPosInventory: data?.inventories?.map((inv) => ({
      id: inv?._id,
      nameInventory: inv?.name,
      amount: inv?.amount,
    })),
    guardRadius: data?.radius,
    description: data?.description,
  });
  const fileRef = useRef(null);

  const [loadingPost, setLoadingPost] = useState(false);

  const [toastStatus, setToastStatus] = useState({
    success: false,
    error: false,
  });

  const handleSubmit = async () => {
    setLoadingPost(true);
    setIsOpenInfoLocation(false);
    const formData = new FormData();
    formData.append("file", inputLocation.image);
    formData.append("name", inputLocation.name?.trim());
    formData.append("totalPersonnel", inputLocation.personil);

    for (let f = 0; f < inputLocation.fieldPosInventory.length; f++) {
      const inventory = inputLocation.fieldPosInventory[f];
      formData.append(`inventories[${f}][name]`, inventory.nameInventory);
      formData.append(`inventories[${f}][amount]`, inventory.amount);
    }

    formData.append("description", inputLocation.description?.trim());
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("radius", inputLocation.guardRadius);

    try {
      const res = await updateGuardPost(data?._id, formData);
      if (res.status === 200) {
        setToastStatus((prev) => ({
          ...prev,
          success: true,
        }));
        setLoadingPost(false);
        navigate(pathname, {
          replace: true,
        });
      }
    } catch (error) {
      console.log(`error add location: ${error}`);
      setLoadingPost(false);
      setToastStatus((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  return (
    <div className={`${Styles.containerDetailGuardPos}`}>
      <header className={`${Styles.header}`}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate(backPath)}
            className={Styles.back}
          />
          <h3>{capitalizeEachWord(data?.name)}</h3>
        </div>
        <Icon
          icon={"setting-2"}
          className={Styles.settings}
          onClick={() => setIsOpenInfoLocation(true)}
        />
      </header>
      <div className={Styles.wrapTabs}>
        <Tabs
          listTabs={listTabs}
          setIsActive={setActivetab}
          isActive={activeTab}
        />
        {width < 768 ? (
          <PDFBarcodeDownload
            checkpoint={data?.name}
            id={data?._id}
            description={data?.description}
            headerSubText="QR Code Pos Penjagaan"
          >
            <Button
              className={Styles.detailQr}
              text="Download QR Code"
              icon={"scan-barcode"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
            />
          </PDFBarcodeDownload>
        ) : (
          <Button
            text="Detail QR Code"
            icon={"scan-barcode"}
            colorIcon={"#2C5364"}
            style={{ background: "transparent", color: "#2C5364" }}
            onClick={() =>
              window.open(
                `/pdf-preview?dataQuery=barcode&id=${data?._id}&postName=${data?.name}&headerSubText=QR Code Pos Penjagaan&description=${data?.description}`,
                "_blank"
              )
            }
          />
        )}
      </div>

      {(toastStatus.error || toastStatus.success) && (
        <Toast
          isopen
          text={
            toastStatus.error
              ? "Gagal Memeperbarui Lokasi"
              : "Berhasil Memeperbarui Lokasi"
          }
          color={toastStatus.error ? "red" : "green"}
          onClose={() =>
            setToastStatus((prev) => ({
              ...prev,
              error: false,
              success: false,
            }))
          }
        />
      )}

      {loadingPost && !isOpenInfoLocation && (
        <LoadingSpinner text="Sedang menyimpan..." />
      )}

      {isOpenInfoLocation && (
        <Suspense fallback={<p>loading...</p>}>
          <ModalAddGuardPos
            isOpen={isOpenInfoLocation}
            onClose={() => setIsOpenInfoLocation(false)}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            inputLocation={inputLocation}
            fileRef={fileRef}
            onSubmit={() => handleSubmit()}
            setInputLocation={setInputLocation}
            isFilled
          />
        </Suspense>
      )}

      {isOpenModalQR && (
        <Suspense fallback={<p>Loading Modal...</p>}>
          <TagQR
            onClose={() => setIsOpenModalQR(false)}
            isOpen={isOpenModalQR}
          />
        </Suspense>
      )}

      {activeTab !== 0 && (
        <div className={Styles.viewerFilter}>
          <div className={Styles.filter}>
            <SearchBar />
            <Button
              text="Filter"
              icon={"setting-4"}
              colorIcon={"#2C5364"}
              style={{
                background: "transparent",
                color: "#2C5364",
              }}
            />
          </div>

          <CustomDatePicker
            isForModal
            customStyle={{ position: "absolute", left: "-180px" }}
          >
            <Button
              icon={"calendar"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
              text="Pilih Tanggal"
            />
          </CustomDatePicker>
        </div>
      )}
      {activeTab === 0 ? (
        <div className={Styles.viewerTableDetailGuard}>
          <div className={Styles.wrapPhotoAndPointLocation}>
            <div className={Styles.image}>
              <strong>Foto Pos</strong>
              <img src={fileBaseUrl + data?.imageURI} alt="image-location" />
            </div>
            <div className={Styles.map}>
              <strong>Lokasi Pos</strong>
              <div className={Styles.map}>
                <Map
                  height="268px"
                  coordinate={{
                    latitude: +data?.latitude,
                    longitude: data?.longitude,
                  }}
                  isRadius
                  radius={+data?.radius}
                  isPreview
                  zoom={18}
                />
              </div>
            </div>
          </div>
          <div className={Styles.tableRow}>
            <div className={Styles.labelHeader}>
              <strong>Personil Bertugas</strong>
              <div className={Styles.selectDate}>
                <span>Sen, 28 Agustus 2024</span>
                <Icon icon={"calendar"} color={"#2C5364"} size={"14"} />
              </div>
            </div>
            <Table
              className={Styles.table}
              data={templateDataPersonil.data}
              columns={templateDataPersonil.columns}
              totalData={templateDataPersonil.data.length}
              totalPage={3}
              withNumbering
              isDetailChart
              limit={5}
            />
          </div>
          <div className={Styles.tableTwoRow}>
            <div>
              <div className={Styles.labelHeader}>
                <strong>Inventaris Pos</strong>
                <div className={Styles.refresh}>
                  <Icon icon={"repeat"} color={"#2C5364"} size={"14"} />
                </div>
              </div>
              <Table
                className={Styles.table}
                data={templatePosInventory.data}
                columns={templatePosInventory.columns}
                totalData={templatePosInventory.data.length}
                totalPage={3}
                withNumbering
                isDetailChart
                limit={5}
              />
            </div>
            <div>
              <div className={Styles.labelHeader}>
                <strong>Barang Titipan Pos</strong>
                <div className={Styles.refresh}>
                  <Icon icon={"repeat"} color={"#2C5364"} size={"14"} />
                </div>
              </div>
              <Table
                className={Styles.table}
                data={templatePosRetrivied.data}
                columns={templatePosRetrivied.columns}
                totalData={templatePosRetrivied.data.length}
                totalPage={3}
                withNumbering
                isDetailChart
                limit={5}
              />
            </div>
          </div>
        </div>
      ) : activeTab === 1 ? (
        <Suspense fallback={<p>Loading component...</p>}>
          <HistoryActivity
            onClick={(id) => setOpenModalActivity(id)}
            templateData={templateDataActivities}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<p>Loading component...</p>}>
          <HistoryActivity
            isHandOver
            onClick={() => setOpenModalHandOver(true)}
            templateData={templateDataActivities}
          />
        </Suspense>
      )}

      {activeTab === 1 &&
        openModalActivity === showDataModalActivity[0]?.id && (
          <Suspense fallback={<p>Loading component...</p>}>
            <ModalActivity
              isOpen
              isTransition={isTransition}
              setOpenModalActivity={setOpenModalActivity}
              showDataModalActivity={showDataModalActivity}
              onClose={() => setOpenModalActivity(false)}
            />
          </Suspense>
        )}

      {activeTab === 2 && openModalHandOver && (
        <Suspense fallback={<p>Loading component...</p>}>
          <ModalHandOver
            isOpen={openModalHandOver}
            setOpenModalHandOver={setOpenModalHandOver}
            showDataModalActivity={showDataModalActivity}
            onClose={() => setOpenModalHandOver(false)}
          />
        </Suspense>
      )}
    </div>
  );
}
