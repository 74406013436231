export const capitalizeEachWord = (sentences) => {
  if (!sentences) return;
  const word = sentences.split(" ");
  const capital = word.map((el) => {
    return el.split("")[0].toUpperCase();
  });
  const resultsSentence = word.map((el, idx) => {
    const lowerCase = el.toLowerCase().substring(1);
    return capital[idx] + lowerCase;
  });
  return resultsSentence.join(" ");
};

// BY CHAT GPT 🥲
// export const capitalizeEachWord = (sentences) => {
//   return sentences
//     .split(" ")
//     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//     .join(" ");
// };
