import { useEffect, useId, useRef, useState } from "react";
import style from "./style.module.scss";
import Icon from "@Atom/Icon";
import Checkbox from "@Atom/Checkbox";
import popoverTooltip from "@Assets/Icon/popoverTooltip.svg";
import useOutsideClick from "@Hooks/useOutsideClick";

export default function DatePicker({
  placeholder = "placeholder",
  type = "date",
  setValue = () => {},
  value = "",
  textLabel = "Placeholder",
  isAddLabel = false,
  isRequired = false,
  isAddCheckbox = false,
  isChecked = false,
  setIsChecked = () => {},
  isError = false,
  textError = "Error Message",
  ...props
}) {
  const ref = useRef(null);
  const handleDateAndTimeChange = (e) => {
    setValue(e?.target?.value);
  };
  const [isFocus, setIsFocus] = useState(false);
  const reff = useRef();
  const boxReff = useOutsideClick(reff);
  useEffect(() => {
    if (boxReff && isFocus) {
      setIsFocus(false);
    }
  }, [boxReff, isFocus]);
  const fieldDate = useId();
  return (
    <>
      <div
        className={style.container}
        ref={reff}
        is-add-checkbox={isAddCheckbox?.toString()}
      >
        {isAddLabel && (
          <label htmlFor={fieldDate}>
            <span className={style.text}>{textLabel}</span>
            {isRequired && <span>*</span>}
          </label>
        )}
        <input
          type={type === "date" ? "date" : type === "time" ? "time" : "date"}
          isselected={value ? "true" : "false"}
          onChange={handleDateAndTimeChange}
          value={value}
          className={style.input}
          ref={ref}
          data-text={placeholder}
          is-focus={isFocus?.toString()}
          disabled={isChecked ? true : false}
          is-disabled={isChecked?.toString()}
          is-error={isError?.toString()}
          id={fieldDate}
          {...props}
        />
        {type === "date" ? (
          <Icon
            icon={"calendar"}
            color={"#C2C2C2"}
            size={"24px"}
            className={style.icon}
            is-add-label={isAddLabel?.toString()}
            onClick={() => {
              setIsFocus(true);
              !isChecked && ref.current?.showPicker();
            }}
          />
        ) : type === "time" ? (
          <Icon
            icon={"clock"}
            color={"#C2C2C2"}
            size={"24px"}
            className={style.icon}
            is-add-label={isAddLabel?.toString()}
            onClick={() => {
              setIsFocus(true);
              !isChecked && ref.current?.showPicker();
            }}
          />
        ) : (
          <Icon
            icon={"calendar"}
            color={"#C2C2C2"}
            size={"24px"}
            is-add-label={isAddLabel?.toString()}
            className={style.icon}
            onClick={() => {
              setIsFocus(true);
              !isChecked && ref.current?.showPicker();
            }}
          />
        )}
        <section style={{ display: "flex", gap: "10px" }}>
          {isAddCheckbox && (
            <div className={style.isAddCheckbox}>
              <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
              <span>Seumur hidup</span>
            </div>
          )}
          {isError && (
            <div
              className={style.error}
              style={isAddCheckbox ? { position: "relative" } : {}}
            >
              <Icon icon={"info-circle"} size={"16"} color={"#D42701"} />
              <span>{textError}</span>
              <img
                src={popoverTooltip}
                alt="error-chevron"
                width={"20"}
                height={"20"}
              />
            </div>
          )}
        </section>
      </div>
    </>
  );
}
