import WithBg from "@Atom/WIthBg";
import React from "react";
import { Outlet } from "react-router-dom";

export default function SettingsPage() {
  return (
    <WithBg>
      <Outlet />
    </WithBg>
  );
}
