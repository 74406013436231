import { API } from "configs";

export function getAllMembersPersonil(locationID, page, limit, search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/member?locationID=${locationID}&page=${page}&limit=${limit}&search=${search}`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
