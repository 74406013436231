import React from "react";
import Styles from "./style.module.scss";
export default function RadioButton({
  isDisabled,
  setIsActive = () => {},
  isActive,
}) {
  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <div
      className={Styles.wrapperRadioButton}
      isactive={isActive?.toString()}
      onClick={isDisabled ? () => {} : handleClick}
      is-disabled={isDisabled?.toString()}
    >
      <div className={Styles.dotsChecked}></div>
    </div>
  );
}
