import Text from '@Atom/Text'
import Styles from './style.module.scss'
import { useState } from 'react'
import Dropdown from '@Atom/Dropdown'
import Icon from '@Atom/Icon'

export default function Tab({
  data
}) {

  const [expand, setExpand] = useState(false)
  // const datao = {
  //   title: '',
  //   isActive: false,
  //   onClick: ()=> {},
  //   subs: [
  //     {
  //       title: '',
  //       isActive: false,
  //       onClick: ()=> {},
  //     }
  //   ]
  // }
  return (
    <div
      className={`${Styles.container} ${data?.isActive ? Styles.active : ''}`}
      onClick={!data?.subs?.length
        ?
        data?.onClick
        : () => { }
      }
      onMouseEnter={()=> setExpand(true)}
      onMouseLeave={()=> setExpand(false)}
    >
      <Text
        maxFontWeight={400}
        maxFontSize={14}
        minFontSize={12}
        className={Styles.text}
      >
        {data?.title}
      </Text>
      {
        !!data?.subs?.length
        &&
        <Icon icon={'arrow-down'} className={`${Styles.icon} ${expand ? Styles.transform : ''}`} size={22} />
      }
      {
        expand
        &&
        <Dropdown
          variant='tab'
          className={Styles.dropdown}
          options={data?.subs}
        />
      }
    </div>
  )
}