import { API } from "configs";

export function changeStatusEmployee(idNumber = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.put(
        `/employee/status/${idNumber}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
