import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import DatePicker from "@Atom/DatePicker";
import { formatPrice } from "helpers/formatPrice";
export default function FormDataJobs({
  handleDeleteFormJob = () => {},
  idx = 0,
  setInputDataJobs = () => {},
  inputDataJobs = [],
  handleAddFormJobs = () => {},
  setIsAddDataJobs = () => {},
  isErrorFormJobs = [false],
  setIsErrorFormJobs = () => {},
  topRef,
}) {
  const handleChangeInput = (idx, name, value) => {
    setInputDataJobs((prev) => {
      const updatedData = [...prev];
      if (name === "takeSallary") {
        const numericValue = value?.replace(/[^\d]/g, "");
        if (/^\d*$/.test(numericValue)) {
          updatedData[idx] = {
            ...updatedData[idx],
            [name]: numericValue,
          };
        } else {
          return prev;
        }
      } else {
        updatedData[idx] = {
          ...updatedData[idx],
          [name]: value,
        };
      }
      return updatedData;
    });
    setIsErrorFormJobs((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[idx] = {
        ...updatedErrors[idx],
        [name]: false,
      };
      return updatedErrors;
    });
  };

  const { width } = useWindowSize();
  return (
    <section className={Styles.containInput} ref={idx === 0 ? topRef : null}>
      <div className={Styles.sticky}>
        <div>
          <h2>Informasi Data Pekerjaan {idx + 1}</h2>
          <Icon
            icon={"trash"}
            className={Styles.icon}
            onClick={
              inputDataJobs?.length === 1
                ? () => setIsAddDataJobs(false)
                : () => handleDeleteFormJob()
            }
          />
        </div>
        <div className={Styles.divider}></div>
      </div>

      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <DatePicker
          isAddLabel
          textLabel="Tanggal Bergabung"
          isRequired
          placeholder="Pilih bulan dan tahun"
          value={inputDataJobs[idx]?.dateStartJoin}
          setValue={(val) => handleChangeInput(idx, "dateStartJoin", val)}
          isError={isErrorFormJobs[idx]?.dateStartJoin}
          textError="Tanggal Bergabung belum di pilih"
        />
        <DatePicker
          isAddLabel
          textLabel="Tanggal Berakhir"
          isRequired
          placeholder="Pilih bulan dan tahun"
          value={inputDataJobs[idx]?.dateEndJoin}
          setValue={(val) => handleChangeInput(idx, "dateEndJoin", val)}
          isError={isErrorFormJobs[idx]?.dateEndJoin}
          textError="Tanggal Berakhir belum di pilih"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Nama Perusahaan"
          isRequired
          placeholder="Nama Perusahaan"
          value={inputDataJobs[idx]?.companyName}
          setValue={(val) => handleChangeInput(idx, "companyName", val)}
          isError={isErrorFormJobs[idx]?.companyName}
          textError="Nama Perusahaan belum di isi"
        />
        <InputField
          isLabel
          textLabel="Jabatan"
          isRequired
          placeholder="Jabatan"
          value={inputDataJobs[idx]?.position}
          setValue={(val) => handleChangeInput(idx, "position", val)}
          isError={isErrorFormJobs[idx]?.position}
          textError="Jabatan belum di isi"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Gaji Yang Diterima"
          isRequired
          placeholder="Rp"
          value={formatPrice(inputDataJobs[idx]?.takeSallary)}
          setValue={(val) => handleChangeInput(idx, "takeSallary", val)}
          isError={isErrorFormJobs[idx]?.takeSallary}
          textError="Gaji Yang Diterima belum di isi"
        />
        <InputField
          isLabel
          textLabel="Kontak Perusahaan (HRD/Nomor Perusahaan)"
          isRequired
          placeholder="+62"
          value={inputDataJobs[idx]?.contactCompany}
          setValue={(val) => handleChangeInput(idx, "contactCompany", val)}
          isError={isErrorFormJobs[idx]?.contactCompany}
          textError="Kontak Perusahaan belum di isi"
        />
      </div>
      <InputField
        isLabel
        textLabel="Deskripsi Pekerjaan"
        isRequired
        placeholder="Tulis deskripsi pekerjaan"
        isTextArea
        value={inputDataJobs[idx]?.jobDesc}
        setValue={(val) => handleChangeInput(idx, "jobDesc", val)}
        isError={isErrorFormJobs[idx]?.jobDesc}
        textError="Deskripsi Pekerjaan belum di isi"
      />
      {idx === inputDataJobs.length - 1 && (
        <Button
          style={{
            width: "100%",
            justifyContent: "center",
            background: "white",
            color: "#2C5364",
          }}
          isAddButton
          isborder
          text="Tambah Pekerjaan"
          onClick={handleAddFormJobs}
          icon={"add-circle"}
          colorIcon={"#2C5364"}
        />
      )}
    </section>
  );
}
