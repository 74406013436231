import ExcelJS from "exceljs";
import moment from "moment";

function generateDaysForMonth(year, month) {
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  return Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

export default async function generateExcelTemplate(
  year,
  month,
  monthString,
  namesAndNrk = []
) {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Employees");

    const days = generateDaysForMonth(year, month);

    const lastDayColumn = days.length + 4; // Meng-offset 4 kolom
    worksheet.mergeCells(2, 5, 2, lastDayColumn);
    worksheet.getCell("E2").value = `Bulan ${month}`;
    worksheet.getCell("E2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("B2").value = "No";
    worksheet.getCell("C2").value = "NRK";
    worksheet.getCell("D2").value = "Nama";

    for (let i = 0; i < namesAndNrk.length; i++) {
      const element = namesAndNrk[i];
      worksheet.getCell(`B${i + 4}`).value = (i + 1).toString();
      worksheet.getCell(`C${i + 4}`).value = element?.employeeID;
      worksheet.getCell(`D${i + 4}`).value = element?.name;
    }

    worksheet.mergeCells("B2:B3");
    worksheet.mergeCells("C2:C3");
    worksheet.mergeCells("D2:D3");

    ["B2", "C2", "D2"].forEach((cell) => {
      worksheet.getCell(cell).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    days.forEach((day, index) => {
      worksheet.getCell(3, index + 5).value = day;
    });

    const dropdownOptions = ['"P,S,M,Off,Non Shift"'];

    for (let row = 4; row <= 104; row++) {
      for (let index = 0; index < days.length; index++) {
        worksheet.getCell(row, index + 5).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: dropdownOptions,
          showErrorMessage: true,
          errorTitle: "Invalid Option",
          error: "Please select a valid option from the list.",
        };
      }
    }

    const headerBorders = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    ["B2", "C2", "D2", "E2"].forEach((cell) => {
      worksheet.getCell(cell).border = headerBorders;
    });

    days.forEach((_, index) => {
      worksheet.getCell(3, index + 5).border = headerBorders;
    });

    // Mengunduh file Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Template Jadwal Bulan ${monthString}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
}
