import React from "react";
import Styles from "./style.module.scss";
import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";
export default function SkeletonDetailInfoLocation() {
  return (
    <div className={Styles.viewerSkeletonDetailInfoLocation}>
      <div className={Styles.between}>
        <Skeleton h="30px" w="200px" />
        <Skeleton h="30px" w=" 30px" />
      </div>
      <BoxSkeleton h="250px" />
      <div className={Styles.spaceAround}>
        <Skeleton w="100%" h="63px" />
        <Skeleton w="100%" h="63px" />
        <Skeleton w="100%" h="63px" />
      </div>
      <div className={Styles.viewRow}>
        <BoxSkeleton h="221px" w="100%" />
        <BoxSkeleton h="221px" w="100%" />
      </div>
    </div>
  );
}
