import Button from "@Atom/Button";
import Styles from "./style.module.scss";
import empty from "@Assets/Images/emptyHandlerOrganization.png";
export default function TableEmptyHandler({
  description = "There is no data available at the moment",
  onClick = () => {},
  textButton = "Text Button",
  isAddButton = false,
}) {
  return (
    <div className={Styles.containerEmpty}>
      <img src={empty} alt="empty-images" />
      <span>{description}</span>
      {isAddButton && (
        <Button text={textButton} isAddButton onClick={onClick} />
      )}
    </div>
  );
}
