import { SkeletonDetailGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/Detail";
import DetailLocation from "@Organism/WorkLocation/ListLocation/Detail";
import { getDetailInfoWorkLocation } from "@Services/WorkLocation/getDetailInfoWorkLocation";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const { id } = route?.params;
  const getDataDetail = getDetailInfoWorkLocation(id);

  return defer({
    getData: getDataDetail,
  });
};

export default function DetailLocationPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailGuardPos />}>
      <Await errorElement={<h1>Error get data !</h1>} resolve={getData}>
        {(data) => <DetailLocation data={data?.data?.data} />}
      </Await>
    </Suspense>
  );
}
