import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";
import Button from "@Atom/Button";
import SelectDate from "@Atom/SelectDate";
import Checkbox from "@Atom/Checkbox";
import SearchBar from "@Atom/SearchBar";
import ImagesNoShift from "@Assets/Images/empty-handler-no-shift.png";
import useQuery from "@Hooks/useQuery";
const ModalActivity = lazy(() =>
  import("@Organism/WorkLocation/GuardPos/Detail/ModalDetail/Activity")
);
const HistoryActivity = lazy(() =>
  import("@Organism/WorkLocation/GuardPos/Detail/HistoryActivity")
);
export default function DetailReportSOS() {
  const navigate = useNavigate();
  const query = useQuery();
  const nameLocation = query.get("locationName");
  const listTabs = ["Shift Pagi", "Shift Sore", "Shift Malam"];
  const nameSessionStorage = "activeTabDetailReportPage";
  const [activeTab, setActiveTab] = useState(
    +sessionStorage.getItem(nameSessionStorage) || 0
  );
  const templateData = [
    {
      id: 1,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 2,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 3,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 4,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 5,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 6,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 7,
      name: "Anton ",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
    {
      id: 8,
      name: "Anton lorem ",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
  ];

  const [isOpenModalActivity, setIsOpenModalActivity] = useState(false);
  const [isTransition, setTransition] = useState(false);
  useEffect(() => {
    if (isOpenModalActivity) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [isOpenModalActivity]);

  useEffect(() => {
    sessionStorage.setItem(nameSessionStorage, activeTab);
  }, [activeTab]);

  const [isCheckedListMembersOfDuty, setIsCheckedListMembersOfDuty] = useState(
    []
  );

  const listMembersOfDuty = useMemo(() => {
    return [
      {
        id: 1,
        image: "https://placehold.co/20x20",
        name: "Anton William",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 2,
        image: "https://placehold.co/20x20",
        name: "Anton JR",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 3,
        image: "https://placehold.co/20x20",
        name: "Jamal",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 4,
        image: "https://placehold.co/20x20",
        name: "King Sadat",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 5,
        image: "https://placehold.co/20x20",
        name: "Anton Aja",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 6,
        image: "https://placehold.co/20x20",
        name: "William",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 7,
        image: "https://placehold.co/20x20",
        name: "Ardhi",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 8,
        image: "https://placehold.co/20x20",
        name: "Yudha",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 9,
        image: "https://placehold.co/20x20",
        name: "Marko",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 10,
        image: "https://placehold.co/20x20",
        name: "Anton peter",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 11,
        image: "https://placehold.co/20x20",
        name: "William pale",
        pos: "Modena Gancy",
        level: "Secpri",
      },
      {
        id: 12,
        image: "https://placehold.co/20x20",
        name: "Anton William pederson",
        pos: "Modena Gancy",
        level: "Secpri",
      },
    ];
  }, []);

  const [toRenderMembersFiltered, setToRenderMembersFiltered] = useState([]);
  // const [isCheckedListMembersOfDuty, setIsCheckedListMembersOfDuty] = useState([]); // Untuk status checked

  const handleCheckedListMembersOfDuty = (id) => {
    setIsCheckedListMembersOfDuty((prev) => {
      const updateCheckedListMembers = [...prev];
      updateCheckedListMembers[id] = !updateCheckedListMembers[id];

      setToRenderMembersFiltered((prevFiltered) => {
        let updatedFiltered = [...prevFiltered];

        if (updateCheckedListMembers[id]) {
          // Jika anggota dicentang, tambahkan ke toRenderMembersFiltered
          const toAppend = listMembersOfDuty.find((el) => el.id === id);
          if (toAppend && !updatedFiltered.some((el) => el.id === id)) {
            updatedFiltered = [...updatedFiltered, toAppend];
          }
        } else {
          // Jika anggota di-uncheck, hapus dari toRenderMembersFiltered
          updatedFiltered = updatedFiltered.filter((el) => el.id !== id);
        }

        return updatedFiltered;
      });

      return updateCheckedListMembers;
    });
  };

  const [isCheckedFilterStatus, setIsCheckedFilterStatus] = useState([
    false,
    false,
    false,
  ]);

  const handleCheckedFilterStatus = (idx) => {
    setIsCheckedFilterStatus((prev) => {
      const updatedStatus = [...prev];
      updatedStatus[idx] = !updatedStatus[idx];
      return updatedStatus;
    });
  };

  const [selectedDate, setSelectedDate] = useState("");
  return (
    <div className={Styles.containerDetailReportActivity}>
      <header className={`${Styles.header}`}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/report/sos")}
            className={Styles.back}
          />
          <h3>{nameLocation}</h3>
        </div>
        <Button
          isAddButton
          isLeftIcon
          icon={"download"}
          style={{ background: "transparent", color: "#2C5364" }}
          colorIcon={"#2C5364"}
          text="Download Report"
        />
      </header>
      <div className={Styles.wrapViewContain}>
        <div className={Styles.left}>
          <div>
            <SelectDate
              setValue={() => setSelectedDate()}
              value={selectedDate}
            />
            <div className={Styles.divider}></div>
            <div className={Styles.selectStatusLocation}>
              <strong>Status Lokasi</strong>
              {["Aman", "Siaga", "Waspada"].map((c, idx) => (
                <div key={idx}>
                  <Checkbox
                    isChecked={isCheckedFilterStatus[idx]}
                    setIsChecked={() => handleCheckedFilterStatus(idx)}
                  />
                  <span>{c}</span>
                </div>
              ))}
            </div>
            <div className={Styles.divider}></div>
          </div>

          <div className={Styles.listPersonil}>
            <strong>Daftar Anggota Bertugas</strong>
            <SearchBar size="s" placeholder="Cari Anggota" />
            <div
              className={`${Styles.selectStatusLocation} ${Styles.overflow}`}
            >
              {listMembersOfDuty.map((l, idx) => (
                <div key={idx}>
                  <Checkbox
                    setIsChecked={() => handleCheckedListMembersOfDuty(l.id)}
                    isChecked={isCheckedListMembersOfDuty[l.id]}
                  />
                  <div className={Styles.profile}>
                    <img src={l?.image} alt="profile-picture" />
                    <div>
                      <strong>{l?.name}</strong>
                      <span>
                        {l?.level} • {l?.pos}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={Styles.right}>
          <div className={Styles.wrapSelectedPersonil}>
            {toRenderMembersFiltered.map((m, idx) => (
              <Button
                key={`members-checked-${idx}`}
                isNoBorder
                style={{
                  background: "#E6F0F4",
                  color: "#2C5364",
                  borderRadius: "20px",
                }}
                onClickIcon={() => {
                  // Hapus member dari toRenderMembersFiltered
                  setToRenderMembersFiltered((prev) =>
                    prev.filter((el) => el?.id !== m?.id)
                  );

                  // Reset status checked di isCheckedListMembersOfDuty
                  setIsCheckedListMembersOfDuty((prev) => {
                    const updatedCheckedList = [...prev];
                    updatedCheckedList[m.id] = false; // Set isChecked ke false
                    return updatedCheckedList;
                  });
                }}
                icon={"close-circle"}
                iconSize={"10"}
                colorIcon={"#2C5364"}
                text={m?.name}
              />
            ))}
          </div>
          <div className={Styles.rightContain}>
            <div className={Styles.wrapTabs}>
              {listTabs.map((t, idx) => (
                <div
                  key={idx}
                  className={Styles.tabs}
                  is-active={(activeTab === idx).toString()}
                  onClick={() => setActiveTab(idx)}
                >
                  <span>{t}</span>
                </div>
              ))}
            </div>

            {isOpenModalActivity && (
              <Suspense fallback={<p>Loading...</p>}>
                <ModalActivity
                  isTransition={isTransition}
                  isOpen
                  setOpenModalActivity={() => setIsOpenModalActivity(false)}
                />
              </Suspense>
            )}
            {activeTab === 0 ? (
              <Suspense fallback={<p>Loading...</p>}>
                <HistoryActivity
                  templateData={templateData}
                  onClick={() => setIsOpenModalActivity(true)}
                />
              </Suspense>
            ) : activeTab === 1 ? (
              <EmptyHandler />
            ) : (
              <Suspense fallback={<p>Loading...</p>}>
                <HistoryActivity
                  templateData={templateData}
                  onClick={() => setIsOpenModalActivity(true)}
                />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const EmptyHandler = () => {
  return (
    <div className={Styles.wrapEmptyHandler}>
      <img src={ImagesNoShift} alt="image-empty" />
      <div>
        <strong>Data Tidak Ditemukan</strong>
        <span>Tidak ada data dengan kriteria filter tersebut</span>
      </div>
    </div>
  );
};
