import React, { useCallback, useRef, useState } from "react";
import Styles from "./style.module.scss";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Autocomplete,
  Circle,
} from "@react-google-maps/api";
import SearchBar from "@Atom/SearchBar";

const libraries = ["places"];

export default function Map({
  coordinate,
  isCustomStyle = false,
  height = "100%",
  width = "100%",
  isAutoComplete = false,
  setSelectedLocation = () => {},
  selectedLocation = {
    latitude: 0,
    longitude: 0,
  },
  options = {},
  isRadius = false,
  radius = 10,
  styles,
  isPreview = false,
  zoom = 15,
}) {
  // default to jakarta
  const defaultCoordinate = {
    latitude: -6.2,
    longitude: 106.816666,
  };

  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [markers, setMarkers] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const mapRef = useRef();

  const panTo = useCallback(async ({ lat, lng }) => {
    await mapRef.current?.panTo({ lat, lng });
    await mapRef.current?.setZoom(14);
    setMarkers({
      lat,
      lng,
      time: new Date(),
    });
  }, []);

  const onMapLoad = useCallback(
    async (map) => {
      if (coordinate?.latitude) {
        await panTo({
          lat: +coordinate?.latitude,
          lng: +coordinate?.longitude,
        });
      } else {
        navigator.geolocation.getCurrentPosition(
          async (position) =>
            await panTo({
              lat: position.coords?.latitude,
              lng: position.coords?.longitude,
            }),
          (error) => {
            console.error("Geolocation error:", error);
            panTo({
              lat: defaultCoordinate.latitude,
              lng: defaultCoordinate.longitude,
            }); // Default to Jakarta
          }
        );
      }
      mapRef.current = map;
    },
    [
      coordinate?.latitude,
      coordinate?.longitude,
      panTo,
      defaultCoordinate.latitude,
      defaultCoordinate.longitude,
    ]
  );

  const center = {
    lat:
      +coordinate?.latitude ||
      selectedLocation?.latitude ||
      defaultCoordinate.latitude,
    lng:
      +coordinate?.longitude ||
      selectedLocation?.longitude ||
      defaultCoordinate.longitude,
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded)
    return (
      <div className={Styles.rightWrapper}>
        <div className={Styles.mapWrapperLoading}>Loading...</div>
      </div>
    );

  const onLoadAutocomplete = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        await panTo({ lat, lng });
        setSelectedLocation({ latitude: lat, longitude: lng });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ latitude: lat, longitude: lng });
    setMarkers({
      lat,
      lng,
      time: new Date(),
    });
    await panTo({ lat, lng });
  };

  return (
    <div className={Styles.containerMap}>
      {isAutoComplete && (
        <div className={Styles.searchBox}>
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
            className={Styles.search}
          >
            <div style={{ width: "100%" }}>
              <SearchBar placeholder="Cari alamat lokasi..." />
            </div>
          </Autocomplete>
        </div>
      )}
      <GoogleMap
        mapContainerStyle={
          isCustomStyle ? styles : { height: height, width: width }
        }
        zoom={zoom}
        center={center}
        onLoad={onMapLoad}
        options={options}
        onClick={isPreview ? () => {} : handleMapClick}
      >
        {!!markers && (
          <>
            <Marker
              key={markers?.time?.toISOString()}
              position={{ lat: markers?.lat, lng: markers?.lng }}
              onClick={() => {}}
              disabled
            />
            {isRadius && (
              <Circle
                center={{ lat: markers?.lat, lng: markers?.lng }}
                radius={radius}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 1,
                  fillColor: "rgba(255, 0, 0, 0.5)",
                  fillOpacity: 0.35,
                  zIndex: 99,
                }}
              />
            )}
          </>
        )}
      </GoogleMap>
    </div>
  );
}
