import React from "react";
import Styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";

const data = [
  {
    header: `Kami sangat menghargai privasi Anda dan berkomitmen untuk melindungi informasi pribadi yang Anda berikan kepada kami. Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda. Dengan menggunakan layanan kami, Anda menyetujui penggunaan informasi Anda sesuai dengan kebijakan ini. Harap membaca kebijakan privasi ini secara menyeluruh sebelum menggunakan layanan kami.`,
    chapters: [
      {
        title: "Pengumpulan jenis data pribadi",
        description: [
          "Informasi yang Diberikan oleh Pengguna: Kami dapat mengumpulkan informasi pribadi yang Anda berikan secara sukarela ketika Anda menggunakan layanan kami, termasuk namun tidak terbatas pada nama, alamat email, nomor telepon, data swafoto dan informasi demografis lainnya.",
          "Data Lokasi. Kami mengumpulkan informasi tentang lokasi Anda saat Anda menggunakan Layanan Kami. Lokasi Anda dapat ditentukan dengan berbagai tingkat akurasi menggunakan GPS, alamat IP, data sensor dari perangkat Anda, Informasi tentang yang ada di dekat perangkat Anda, seperti titik akses Wi-Fi dan menara seluler.",
        ],
      },
      {
        title: "Penggunaan Data pribadi anda",
        headDesc:
          "Atas Data Pribadi Anda yang Kami kumpulkan, Kami dapat menggunakannya untuk tujuan berikut ataupun tujuan lain yang diizinkan oleh Hukum yang Berlaku (“Tujuan”) : ",
        description: [
          "Penggunaan fitur-fitur yang terdapat pada Layanan Kami. Sebagai contoh untuk Layanan MyWorkSpace, maka Kami dapat menggunakan Data Pribadi Anda berupa swafoto untuk keperluan pencatatan kehadiran dan aktivitas.",
          "Mendaftarkan Anda sebagai pengguna pada Platform Kami, dan juga untuk mengadministrasikan dan/atau mengelola akun Anda.",
          "Menginformasikan Anda atas aktivitas yang terjadi dalam Platform Kami atau platform/sistem lain yang terhubung dengan Platform Kami.",
          "Berinteraksi langsung dengan Anda serta memberitahu Anda mengenai segala pembaruan atau perubahan pada Layanan",
          "Mengirimkan Anda informasi sehubungan dengan penggunaan Layanan",
          "Melakukan pemeliharaan peningkatan, pemecahan masalah, pengembangan dan personalisasi/kustomisasi Layanan untuk memenuhi kebutuhan dan preferensi Anda",
          "Memberikan bantuan atau menyelesaikan aduan sehubungan dengan masalah operasional yang terjadi pada Layanan",
        ],
        subDesc: [
          "Kami menggunakan berbagai macam teknologi guna memproses Data Pribadi Anda untuk Tujuan yang disebutkan di atas. Kami menggunakan sistem otomatis yang menganalisis konten Anda untuk memberikan hal-hal seperti hasil pencarian yang disesuaikan atau fitur lainnya yang disesuaikan dengan cara Anda menggunakan layanan Kami. Kami juga menganalisis konten Anda untuk membantu Kami mendeteksi penyalahgunaan seperti spam, malware, dan konten ilegal.",
          "Kami mungkin menggabungkan informasi yang Kami kumpulkan di Layanan Kami di seluruh perangkat Anda untuk Tujuan yang dijelaskan di atas. Bergantung pada setelan akun Anda, aktivitas Anda di situs dan aplikasi lain mungkin dikaitkan dengan Data Pribadi Anda untuk meningkatkan Layanan.",
          "Data Pribadi Anda akan disimpan untuk jangka waktu yang dibutuhkan untuk mencapai Tujuan dan/atau paling singkat 2 (dua) tahun, mana yang paling lama.",
        ],
      },
      {
        title: "Keamanan Informasi",
        description: [
          "Kami mengambil langkah-langkah yang wajar untuk melindungi informasi pribadi Anda dari akses yang tidak sah atau penggunaan yang tidak sah. Kami menggunakan tindakan keamanan fisik, teknis, dan administratif yang sesuai untuk melindungi informasi pribadi Anda.",
        ],
      },
      {
        title: "Pengungkapan Informasi kepada Pihak Ketiga",
        description: [
          "Kami tidak akan menjual, menyewakan, atau menukar informasi pribadi Anda kepada pihak ketiga tanpa izin Anda, kecuali jika diwajibkan oleh hukum. Kami dapat mengungkapkan informasi kepada penyedia layanan pihak ketiga yang bekerja atas nama kami untuk memberikan layanan kepada Anda, tetapi mereka diwajibkan untuk menjaga kerahasiaan informasi tersebut.",
        ],
      },

      {
        title: "Pengendalian Privasi Anda",
        description: [
          `Anda memiliki hak untuk mengakses dan memperbarui. Jika Anda ingin melakukan perubahan pada informasi Anda atau memiliki pertanyaan tentang kebijakan privasi kami, silakan hubungi kami menggunakan informasi yang tercantum di bagian "Hubungi Kami" di bawah ini.`,
        ],
      },

      {
        title: "Perubahan Kebijakan Privasi",
        description: [
          `Kami dapat mengubah kebijakan privasi ini dari waktu ke waktu. Perubahan akan diberlakukan saat kebijakan privasi yang diperbarui diposting di situs kami. Kami mendorong Anda untuk memeriksa kebijakan privasi ini secara berkala untuk memahami bagaimana informasi pribadi Anda dilindungi.`,
        ],
      },
      {
        title: "Persetujuan Anda",
        description: [
          `Dengan menggunakan layanan kami, Anda menyetujui pengumpulan dan penggunaan informasi Anda sesuai dengan kebijakan privasi ini.`,
        ],
      },
      {
        title: "Hubungi Kami",
        description: [
          `Jika Anda memiliki pertanyaan atau masalah mengenai kebijakan privasi kami, silakan hubungi kami melalui alamat email atau alamat surat yang tercantum di bawah ini:`,
        ],
      },
    ],

    moreInfo: {
      email: "info@permataindonesia.com",
      phone: "(021) 24-101-355",
      office:
        "Jl. Kebayoran Lama No. 226, Kode Pos 12220, Jakarta Selatan, Indonesia",
    },
    footer: `Kebijakan privasi ini berlaku untuk informasi yang dikumpulkan melalui layanan kami dan tidak berlaku untuk informasi yang dikumpulkan oleh pihak ketiga yang mungkin kami tautkan di situs kami. Kami tidak bertanggung jawab atas praktik privasi pihak ketiga tersebut.Terima kasih telah membaca kebijakan privasi kami.`,
  },
];

export default function PrivacyAndPolicy({
  isSinglePage = false,
  onClickBackBtn = () => {},
}) {
  const navigate = useNavigate();
  return (
    <div className={`${Styles.Body}`}>
      <div className={Styles.Header}>
        <span>Kebijakan Privasi</span>
        <img
          src={Images.MWS_LOGO}
          alt="company-logo"
          onClick={isSinglePage ? () => {} : () => navigate("/")}
        />
      </div>
      <div className={Styles.Content}>
        {!isSinglePage && (
          <div className={Styles.backButton} onClick={onClickBackBtn}>
            <Icon
              icon={"arrow-back"}
              color={"#2E3192"}
              className={Styles.backIcon}
              size={"16"}
            />
            <span>Kembali</span>
          </div>
        )}
        <div className={Styles.backgroundLinear}></div>
        <div
          className={Styles.Paragraph}
          is_single_page={isSinglePage?.toString()}
        >
          <span>{data[0].header}</span>
          <strong></strong>
        </div>
        <div className={Styles.Chapter}>
          {data[0]?.chapters?.map((each, index) => (
            <div className={Styles.WrapperChapter} key={`${each} ${index}`}>
              <span>
                {index + 1}. {each.title}
              </span>
              {each.headDesc && (
                <div className={Styles.HeadDesc}>
                  <span>
                    Atas Data Pribadi Anda yang Kami kumpulkan, Kami dapat
                    menggunakannya untuk tujuan berikut ataupun tujuan lain yang
                    diizinkan oleh Hukum yang Berlaku (“<b>Tujuan</b>”) :
                  </span>
                </div>
              )}
              {each.description.length > 1 ? (
                <ul>
                  {each.description.map((eachSub, idx) => (
                    <li key={idx}>{eachSub}</li>
                  ))}
                </ul>
              ) : (
                <label>{each.description}</label>
              )}

              {each.subDesc &&
                each.subDesc.map((each, index) => (
                  <div className={Styles.SubDesc} key={index}>
                    <span key={index}>{each}</span>
                  </div>
                ))}
            </div>
          ))}
        </div>
        <div className={Styles.MoreInfo}>
          <span>Email : {data[0].moreInfo.email}</span>
          <span>Telepon : {data[0].moreInfo.phone}</span>
          <span>Kantor : {data[0].moreInfo.office}</span>
        </div>
        <div className={Styles.Footer}>
          <span>{data[0].footer}</span>
        </div>
      </div>
    </div>
  );
}
