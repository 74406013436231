import React from "react";
import Styles from "./style.module.scss";
import EmptyStepImage from "@Assets/Images/emptyStep.png";
export default function EmptyHandlerFamily() {
  return (
    <div className={Styles.wrapperEmptyFamily}>
      <img src={EmptyStepImage} alt="empty-images" />
      <section className={Styles.description}>
        <h2>Tidak Ada Data Keluarga yang Harus Diisi</h2>
        <p>
          Karena status kawin adalah belum kawin, maka tidak ada data keluarga
          yang perlu diisi
        </p>
      </section>
    </div>
  );
}
