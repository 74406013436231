import React, { Fragment, useEffect, useId, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import popoverTooltip from "@Assets/Icon/popoverTooltip.svg";
import { makeRandomString } from "helpers/makeRandomString";
import { fileBaseUrl } from "configs";
import Button from "@Atom/Button";

export default function InputField({
  isDisabled,
  isError = false,
  textError = "Error Message",
  isRequired,
  isLabel,
  textLabel = "Text Label",
  placeholder = "Placeholder",
  isDropdown,
  isLeftDropdown,
  isTextArea,
  dataMapDropdown = [
    "placeholder 1",
    "placeholder 2",
    "placeholder 3",
    "placeholder 4",
  ],
  isDropdownWithID = false,
  value = "",
  setValue = () => {},
  type = "text",
  isMoney = false,
  isNumber = false,
  name = "",
  isButtonChart = false,
  isAutoComplete = false,
  isLoading = false,
  listAutoComplete = [
    {
      id: "",
      imageURL: "https://placehold.co/14x14",
      name: "silas",
      position: "Posisi",
    },
    {
      id: "",
      imageURL: "https://placehold.co/14x14",
      name: "papare",
      position: "Posisi",
    },
  ],
  closeAutoComplete = () => {},
  setAutoComplete = (name) => {},
  isAddElement = false,
  textElement = "Text",
  onClickAddBtnListDropdown = () => {},
  isAddBtnListDropdown = false,
  textBtnAddListDropdown = "Button",
  setSelectedDropdownID = () => {},
  isForLoginMWS = false,
  isselecttime = false,
  // onSaveSelectTime = () => {},
  typeInputSelect = "minute",
  isFilled = false,
  // setHour = () => {},
  // setMinute = () => {},
  // hour,
  // minute,
  ...props
}) {
  const handleTypeMoney = (value) => {
    if (isMoney) {
      const isNumber = value.split(".").join("");
      if (+isNumber) {
        setValue(parseInt(isNumber).toLocaleString("id-ID"));
      } else if (value === "") {
        setValue("");
      }
    }
  };

  const handleChange = (e) => {
    if (isNumber) {
      const value = e?.target?.value;
      if (/^[+-]?\d*\.?\d*$/.test(value)) {
        setValue(value);
      }
    } else if (isDropdown) {
      setValue(e);
      setIsOpenDropdown(false);
    } else {
      setValue(e?.target?.value);
    }
  };

  const fieldInput = useId();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const handleChangeDropdown = () => {
    if (isDisabled) {
      return null;
    } else {
      setIsOpenDropdown(!isOpenDropdown);
    }
  };
  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);
  useEffect(() => {
    if (boxOutsideClick && isOpenDropdown) {
      setIsOpenDropdown(false);
    }
  }, [boxOutsideClick, isOpenDropdown]);

  // FUNCTION SELECT TIME

  const [isOpenSelectTime, setIsOpenSelectTime] = useState(false);

  const boxRefSelectTime = useRef();
  const boxOutsideClickSelectTime = useOutsideClick(boxRefSelectTime);
  useEffect(() => {
    if (boxOutsideClickSelectTime && isOpenSelectTime) {
      setIsOpenSelectTime(false);
    }
  }, [boxOutsideClickSelectTime, isOpenSelectTime]);

  const [triggerInputHour, setTriggerInputHour] = useState(false);
  const [triggerInputMinute, setTriggerInputMinute] = useState(false);

  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isAM, setIsAM] = useState(true);

  const handleHourChange = (direction) => {
    setHour((prevHour) => {
      let newHour = direction === "up" ? +prevHour + 1 : +prevHour - 1;
      if (+newHour > 12) return 1;
      if (+newHour < 1) return 12;
      setTriggerInputHour(false);
      return +newHour;
    });
  };

  const handleMinuteChange = (direction) => {
    setMinute((prevMinute) => {
      let newMinute = direction === "up" ? +prevMinute + 1 : +prevMinute - 1;
      if (newMinute > 59) return 0;
      if (newMinute < 0) return 59;
      setTriggerInputMinute(false);
      return +newMinute;
    });
  };

  const handleInputChangeSelectTime = (e, type) => {
    const value = e.target.value.replace(/\D/g, "");

    if (type === "hour") {
      setTriggerInputHour(true);
      if (value > 12) {
        return setHour(1);
      } else {
        setHour(value);
      }
    } else if (type === "minute") {
      setTriggerInputMinute(true);
      if (value > 59) {
        return setMinute(0);
      } else {
        setMinute(value);
      }
    }
  };

  const handleSaveSelectTime = () => {
    setValue((prev) => ({
      ...prev,
      time: `${
        +hour === 0
          ? `0${hour}`
          : String(+hour).startsWith("00")
          ? String(+hour).replace("00", "0")
          : !String(+hour).startsWith("0") && +hour < 10
          ? `0${+hour.toString()}`
          : +hour.toString()
      }:${
        +minute === 0
          ? `0${minute}`
          : !String(+minute).startsWith("0") && +minute < 10
          ? `0${+minute}`
          : +minute
      } ${isAM ? "AM" : "PM"}`,
    }));
    setTimeout(() => {
      setIsOpenSelectTime(false);
    }, 50);
  };

  // END FUNCTION SELECT TIME

  return (
    <div className={Styles.wrapperInputField}>
      {isLabel && (
        <label htmlFor={fieldInput}>
          <p className={Styles.text}>{textLabel}</p>
          {isRequired && <span>*</span>}
        </label>
      )}
      <div
        className={Styles.mainInput}
        istext-area={isTextArea?.toString()}
        is-add-element={isAddElement?.toString()}
        data_text={textElement}
      >
        {isTextArea ? (
          <textarea
            id={fieldInput}
            type={type}
            placeholder={placeholder}
            isvalue={value ? "true" : "false"}
            value={value}
            onChange={
              isMoney ? (e) => handleTypeMoney(e?.target?.value) : handleChange
            }
            className={Styles.textField}
            isdisabled={isDisabled?.toString()}
            disabled={isDisabled}
            iserror={isError?.toString()}
            isdropdown={isDropdown?.toString()}
            {...props}
            name={name}
            cols="30"
            rows="10"
          >
            {isError && (
              <div className={Styles.errorTextArea}>
                <Icon
                  icon={"info-circle"}
                  style={{ flexShrink: "0" }}
                  size={"16"}
                  color={"#D42701"}
                />
                <span>{textError}</span>
                <img src={popoverTooltip} alt="error-chevron" />
              </div>
            )}
          </textarea>
        ) : (
          <input
            id={fieldInput}
            type={type}
            placeholder={placeholder}
            isvalue={value ? "true" : "false"}
            value={value}
            onChange={
              isMoney ? (e) => handleTypeMoney(e?.target?.value) : handleChange
            }
            className={Styles.textField}
            isdisabled={isDisabled?.toString()}
            disabled={isDisabled}
            iserror={isError?.toString()}
            isdropdown={isDropdown?.toString()}
            isselecttime={isselecttime.toString()}
            name={makeRandomString(5) + "field"}
            {...props}
          />
        )}
        {isAutoComplete && (
          <div className={Styles.dropdownAuto}>
            {listAutoComplete.map((el, idx) => (
              <div
                className={Styles.autoComplete}
                onClick={() => {
                  closeAutoComplete();
                  setAutoComplete(el);
                }}
                key={idx}
              >
                <div className={Styles.namesAndPhoto}>
                  <img src={fileBaseUrl + el.imageURL} alt="profile-picture" />
                  <span>{el.name}</span>
                </div>
                <div className={Styles.position}>
                  <span>{el.position}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {isDropdown && (
          <div className={Styles.dropdown} {...props}>
            <div
              className={Styles.wrapper}
              isopen={isOpenDropdown?.toString()}
              onClick={handleChangeDropdown}
              isleft={isLeftDropdown?.toString()}
              isdisabled={isDisabled?.toString()}
              ref={boxRef}
              ischooseitems={value ? "true" : "false"}
              iserror={isError?.toString()}
            >
              <span ischooseitems={value ? "true" : "false"}>
                {value || placeholder}
              </span>
              {isButtonChart ? (
                <div className={Styles.btnChart}>
                  <span>
                    <span>Pilih</span> <span>Chart</span>
                  </span>
                </div>
              ) : (
                <Icon
                  icon={"arrow-down"}
                  size={"16"}
                  className={Styles.chevron}
                  isopen={isOpenDropdown?.toString()}
                />
              )}
            </div>
            {!isButtonChart && (
              <div
                className={Styles.dropdownItems}
                isopen={isOpenDropdown?.toString()}
              >
                <ul is-loading={isLoading?.toString()}>
                  {isLoading
                    ? [1, 2, 3, 4].map((_, idx) => (
                        <Fragment key={idx}>
                          <li></li>
                        </Fragment>
                      ))
                    : dataMapDropdown?.map((item, idx) => (
                        <Fragment key={idx}>
                          <li
                            onClick={
                              isDropdownWithID
                                ? () => {
                                    handleChange(item?.list);
                                    setSelectedDropdownID(item?.id);
                                  }
                                : () => handleChange(item)
                            }
                          >
                            {isDropdownWithID ? item?.list : item}
                          </li>
                          {isAddBtnListDropdown &&
                            idx === dataMapDropdown.length - 1 && (
                              <Button
                                text={textBtnAddListDropdown}
                                isAddButton
                                isLeftIcon
                                style={{
                                  background: "white",
                                  color: "#2C5364",
                                }}
                                isNoBorder
                                colorIcon={"#2C5364"}
                                onClick={onClickAddBtnListDropdown}
                              />
                            )}
                        </Fragment>
                      ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {isselecttime && (
          <div className={Styles.containerSelectTime}>
            <div
              className={Styles.wrapper}
              isopen={isOpenDropdown?.toString()}
              onClick={() => setIsOpenSelectTime(!isOpenSelectTime)}
              isleft={isLeftDropdown?.toString()}
              isdisabled={isDisabled?.toString()}
              ref={boxRef}
              ischooseitems={value ? "true" : "false"}
              iserror={isError?.toString()}
            >
              <Icon
                icon={"clock-solid"}
                size={"18"}
                className={`${Styles.chevron} ${isFilled && Styles.filled} `}
                isopen={isOpenDropdown?.toString()}
              />
              <span ischooseitems={value ? "true" : "false"}>
                {value || placeholder}
              </span>
            </div>
            {isOpenSelectTime && (
              <div className={Styles.timeInput} ref={boxRefSelectTime}>
                <div className={Styles.contain}>
                  <div className={Styles.timeField}>
                    <Icon
                      onClick={() => handleHourChange("up")}
                      icon={"arrow-down"}
                      color={"black"}
                      size={"16"}
                      className={`${Styles.arrowUp} ${Styles.icon}`}
                    />
                    <input
                      type="text"
                      value={
                        triggerInputHour ? hour : hour < 10 ? `0${hour}` : hour
                      }
                      onChange={(e) => handleInputChangeSelectTime(e, "hour")}
                      className={Styles.inputIndicator}
                      maxLength={2}
                    />
                    <Icon
                      onClick={() => handleHourChange("down")}
                      icon={"arrow-down"}
                      color={"black"}
                      size={"16"}
                      className={Styles.icon}
                    />
                  </div>

                  <div className={Styles.colon}>:</div>

                  <div className={Styles.timeField}>
                    <Icon
                      onClick={() => handleMinuteChange("up")}
                      icon={"arrow-down"}
                      color={"black"}
                      size={"16"}
                      className={`${Styles.arrowUp} ${Styles.icon}`}
                    />
                    <input
                      type="text"
                      value={
                        triggerInputMinute
                          ? minute
                          : minute < 10
                          ? `0${minute}`
                          : minute
                      }
                      onChange={(e) => handleInputChangeSelectTime(e, "minute")}
                      className={Styles.inputIndicator}
                      maxLength={2}
                    />

                    <Icon
                      onClick={() => handleMinuteChange("down")}
                      icon={"arrow-down"}
                      color={"black"}
                      size={"16"}
                      className={Styles.icon}
                    />
                  </div>

                  <div className={Styles.ampmToggle}>
                    <button
                      onClick={() => setIsAM(true)}
                      className={isAM ? Styles.selected : ""}
                    >
                      AM
                    </button>
                    <button
                      onClick={() => setIsAM(false)}
                      className={!isAM ? Styles.selected : ""}
                    >
                      PM
                    </button>
                  </div>
                </div>
                <Button
                  style={{ width: "100%", justifyContent: "center" }}
                  text="Ok"
                  onClick={() => handleSaveSelectTime()}
                />
              </div>
            )}
          </div>
        )}

        {isError && !isForLoginMWS && (
          <div className={Styles.error}>
            <Icon
              icon={"info-circle"}
              style={{ flexShrink: "0" }}
              size={"16"}
              color={"#D42701"}
            />
            <span>{textError}</span>
            <img src={popoverTooltip} alt="error-chevron" />
          </div>
        )}
      </div>
    </div>
  );
}
