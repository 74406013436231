import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "@Molecule/Tabs";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import SearchBar from "@Atom/SearchBar";
import { fileBaseUrl } from "configs";
import ModalAddPatrolPoint from "@Molecule/_modal/AddPatrolPoint";
import { updatePatrolPoint } from "@Services/PatrolPoint/updatePatrollPoint";
import Modal from "@Atom/Modal";
import Spinner from "@Atom/Spinner";
import Toast from "@Atom/Toast";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";

const ModalActivity = lazy(() =>
  import("../../GuardPos/Detail/ModalDetail/Activity")
);
const HistoryActivity = lazy(() =>
  import("../../GuardPos/Detail/HistoryActivity")
);
export default function DetailPatrolPoint({ data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpenInfoLocation, setIsOpenInfoLocation] = useState(false);
  const listTabs = ["Detail Titik Patroli", "Riwayat Patroli"];
  const [activeTab, setActivetab] = useState(
    +sessionStorage.getItem("detailActiveTabsPatrolPoint") || 0
  );
  const { width } = useWindowSize();
  const template = useMemo(() => {
    return {
      data: [
        {
          id: 1,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 6,
        },
        {
          id: 2,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 10,
        },
        {
          id: 3,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 9,
        },
        {
          id: 4,
          name: "Junaedi Gunawan",
          shift: "Shift 1",
          progressActivity: 5,
        },
      ],
      columns: [
        {
          name: "name",
          title: <TabHeader isSorted text="Nama" />,
        },
        {
          name: "shift",
          title: <TabHeader isSorted text="Shift" />,
          hideOnMobile: true,
        },
        {
          name: "progressActivity",
          title: <TabHeader isSorted text="Progress Patroli" />,
          renderData: (row) => (
            <div className={Styles.progress}>
              <div className={Styles.loadProgress}>
                <div
                  style={{ width: (row.progressActivity * 100) / 10 + "%" }}
                ></div>
              </div>
              <span>{row.progressActivity} / 10</span>
            </div>
          ),
        },
      ],
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("detailActiveTabsPatrolPoint", activeTab);
  }, [activeTab]);

  const templateDataActivities = useMemo(() => {
    return [
      {
        id: 1,
        name: "Anton Sinaga",
        profilePicture: "https://placehold.co/20x20",
        date: "Senin, September 12 2024",
        time: "14:23:46, WIB",
        shift: "Pagi",
        activity: "Patroli 1",
        statusLocation: "Aman",
        frontImage: "https://placehold.co/650x253/orange/blue",
        bottomImage: "https://placehold.co/650x253",
        rightImage: "https://placehold.co/650x253",
        leftImage: "https://placehold.co/650x253",
        reportDescription:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
      },
      {
        id: 2,
        name: "Anton Sinaga",
        profilePicture: "https://placehold.co/20x20",
        date: "Senin, September 12 2024",
        time: "14:23:46, WIB",
        shift: "Pagi",
        activity: "Patroli 2",
        statusLocation: "Waspada",
        frontImage: "https://placehold.co/650x253/orange/blue",
        bottomImage: "https://placehold.co/650x253",
        rightImage: "https://placehold.co/650x253",
        leftImage: "https://placehold.co/650x253",
        reportDescription:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
      },
      {
        id: 3,
        name: "Anton Sinaga",
        profilePicture: "https://placehold.co/20x20",
        date: "Senin, September 12 2024",
        time: "14:23:46, WIB",
        shift: "Pagi",
        activity: "Patroli 3",
        statusLocation: "Siaga",
        frontImage: "https://placehold.co/650x253/orange/blue",
        bottomImage: "https://placehold.co/650x253",
        rightImage: "https://placehold.co/650x253",
        leftImage: "https://placehold.co/650x253",
        reportDescription:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
      },
    ];
  }, []);

  const [openModalActivity, setOpenModalActivity] = useState(false);
  const [showDataModalActivity, setShowDataModalActivity] = useState([]);
  useEffect(() => {
    const filterDataModalActivity = templateDataActivities.filter(
      (el) => el.id === openModalActivity
    );
    setShowDataModalActivity(filterDataModalActivity);
  }, [openModalActivity, templateDataActivities]);

  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (openModalActivity === showDataModalActivity[0]?.id) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [openModalActivity, showDataModalActivity]);

  useEffect(() => {
    if (isOpenInfoLocation) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    }
  }, [isOpenInfoLocation]);

  // const [isOpenModalQR, setIsOpenModalQR] = useState(false);

  const backPath = pathname.split("/").slice(0, -1).join("/");

  // update

  const [isSuccessUpdatePatrolPoint, setIsSuccessUpdatePatrolPoint] =
    useState(false);
  const [isErrorUpdatePatrolPoint, setIsErrorUpdatePatrolPoint] =
    useState(false);

  const [isLoadingHit, setIsLoadingHit] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: +data?.latitude,
    longitude: +data?.longitude,
  });
  const [inputPatrolPoint, setInputPatrolPoint] = useState({
    name: capitalizeEachWord(data?.description),
    image: data?.imageURI,
  });

  const fileRef = useRef(null);

  const handleAddPatrolPoint = async () => {
    setIsLoadingHit(true);
    setIsOpenInfoLocation(false);
    const formData = new FormData();
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("description", inputPatrolPoint.name);
    formData.append("file", inputPatrolPoint.image);

    try {
      const res = await updatePatrolPoint(data?._id, formData);
      if (res.status === 200) {
        navigate(pathname, { replace: true });
        setIsSuccessUpdatePatrolPoint(true);
        setIsLoadingHit(false);
      }
    } catch (error) {
      setIsLoadingHit(false);
      console.log("error add patrol point", error);
      setIsErrorUpdatePatrolPoint(true);
    }
  };

  //

  return (
    <div className={`${Styles.containerDetailGuardPos}`}>
      <Toast
        isopen={isErrorUpdatePatrolPoint}
        color="red"
        onClose={() => {
          setIsErrorUpdatePatrolPoint(false);
        }}
        text={"Gagal memperbarui lokasi"}
      />

      <Toast
        isopen={isSuccessUpdatePatrolPoint}
        color="green"
        onClose={() => {
          setIsSuccessUpdatePatrolPoint(false);
        }}
        text={"Berhasil memperbarui lokasi"}
      />

      {isLoadingHit && (
        <Modal isOpen>
          <div style={{ background: "white" }} className={Styles.loadingPost}>
            <Spinner width="44px" />
          </div>
        </Modal>
      )}

      <header className={`${Styles.header}`}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate(backPath)}
            className={Styles.back}
          />
          <h3>{capitalizeEachWord(data?.name)}</h3>
        </div>
        <Icon
          icon={"setting-2"}
          className={Styles.settings}
          onClick={() => setIsOpenInfoLocation(true)}
        />
      </header>
      <div className={Styles.wrapTabs}>
        <Tabs
          listTabs={listTabs}
          setIsActive={setActivetab}
          isActive={activeTab}
        />

        {width < 768 ? (
          <PDFBarcodeDownload
            checkpoint={capitalizeEachWord(data?.name)}
            id={data?._id}
            description={capitalizeEachWord(data?.description)}
            headerSubText="QR Code Titik Patroli"
          >
            <Button
              className={Styles.detailQr}
              text="Download QR Code"
              icon={"scan-barcode"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
            />
          </PDFBarcodeDownload>
        ) : (
          <Button
            text="Detail QR Code"
            icon={"scan-barcode"}
            colorIcon={"#2C5364"}
            style={{ background: "transparent", color: "#2C5364" }}
            onClick={() =>
              window.open(
                `/pdf-preview?dataQuery=barcode&id=${
                  data?._id
                }&postName=${capitalizeEachWord(
                  data?.name
                )}&headerSubText=QR Code Titik Patroli &description=${capitalizeEachWord(
                  data?.description
                )}`,
                "_blank"
              )
            }
          />
        )}
      </div>
      {activeTab !== 0 && (
        <div className={Styles.viewerFilter}>
          <div className={Styles.filter}>
            <SearchBar />
            {/* <Button
              text="Filter"
              icon={"setting-4"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
            /> */}
          </div>
          <Button
            icon={"calendar"}
            colorIcon={"#2C5364"}
            style={{ background: "transparent", color: "#2C5364" }}
            text="Pilih Tanggal"
          />
        </div>
      )}

      {isOpenInfoLocation && (
        <Suspense fallback={<p>loading...</p>}>
          <ModalAddPatrolPoint
            isOpen
            isFilled
            onClose={() => {
              setIsOpenInfoLocation(false);
              setTransition(false);
            }}
            isTransition={isTransition}
            fileRef={fileRef}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            inputLocation={inputPatrolPoint}
            setInputLocation={setInputPatrolPoint}
            onSubmit={() => handleAddPatrolPoint()}
          />
        </Suspense>
      )}

      {activeTab === 0 ? (
        <div className={Styles.viewerTableDetailGuard}>
          <div className={Styles.wrapPhotoAndPointLocation}>
            <div className={Styles.image}>
              <strong>Foto Lokasi Patroli</strong>
              <img src={fileBaseUrl + data?.imageURI} alt="image-location" />
            </div>
            <div className={Styles.map}>
              <strong>Lokasi Patroli</strong>
              <div className={Styles.map}>
                <Map
                  height="268px"
                  coordinate={{
                    latitude: +data?.latitude,
                    longitude: +data?.longitude,
                  }}
                  isPreview
                />
              </div>
            </div>
          </div>
          <div className={Styles.tableRow}>
            <div className={Styles.labelHeader}>
              <strong>Personil Bertugas</strong>
              <div className={Styles.selectDate}>
                <span>Sen, 28 Agustus 2024</span>
                <Icon icon={"calendar"} color={"#2C5364"} size={"14"} />
              </div>
            </div>
            <Table
              className={Styles.table}
              data={template.data}
              columns={template.columns}
              totalData={template.data.length}
              totalPage={3}
              withNumbering
              isDetailChart
            />
          </div>
          <div className={Styles.tableTwoRow}></div>
        </div>
      ) : (
        activeTab === 1 && (
          <Suspense fallback={<p>Loading component...</p>}>
            <HistoryActivity
              onClick={setOpenModalActivity}
              templateData={templateDataActivities}
            />
          </Suspense>
        )
      )}

      {activeTab === 1 &&
        openModalActivity === showDataModalActivity[0]?.id && (
          <Suspense fallback={<p>Loading component...</p>}>
            <ModalActivity
              isOpen
              isTransition={isTransition}
              setOpenModalActivity={setOpenModalActivity}
              showDataModalActivity={showDataModalActivity}
            />
          </Suspense>
        )}
    </div>
  );
}
