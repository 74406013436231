export const getDaysInMonth = (month, year, type = 'dates') => {


  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return type === 'amount' ? new Date(year, month, 0).getDate() : days
}