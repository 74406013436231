import { API } from "configs";

export default function login(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post(`/auth/login/`, payload);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyOtp(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post(`/auth/login/verifyOtp`, payload);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
