import axios from "axios";

export function fetchListsBank() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        // `https://dev.myworkspace.id/api/v3/bank/list-365`
        `https://api1.myworkspace.id/api/v3/bank`,
        {
          headers: {
            Authorization: "d8ca278b34465a0e69cb5145c447b411",
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
