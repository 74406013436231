import { SkeletonListPatrolPoint } from "@Organism/Skeletons/WorkLocations/PatrolPoint/List";
import ListPatrolPoint from "@Organism/WorkLocation/PatrolPoint/List";
import { getAllPatrolPoint } from "@Services/PatrolPoint/getAllPatrollPoint";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const search = params?.get("search") || "";

  const { id } = route?.params;
  const fetchAllDataPatrolPoint = getAllPatrolPoint(page, limit, id, search);

  return defer({
    getAllDataPatrolPoint: fetchAllDataPatrolPoint,
  });
};

export default function ListPatrolPointPage() {
  const { getAllDataPatrolPoint } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListPatrolPoint />}>
      <Await
        errorElement={<h1>Error get data !</h1>}
        resolve={getAllDataPatrolPoint}
      >
        {(data) => <ListPatrolPoint data={data} />}
      </Await>
    </Suspense>
  );
}
