import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function ErrorBubble({
  errorText = '',
  marginTop = '8px',
  marginBottom = '0px',
  noTip = false,
  isV2=false,
  justifyContent = 'flex-start',
}) {

  return (
    <div className={`${Styles.container} ${Styles[isV2? 'v2' : '']} ${noTip ? Styles.noTip : ''}`} style={{ marginTop, marginBottom, justifyContent }}>
      <Icon icon={isV2 ? 'warning-2':'alert-solid'} size={isV2 ? 20 :16} color={'#D42701'} />
      <span>{errorText}</span>
    </div>
  )
}