import Images from "@Theme/Images";
import Styles from "./style.module.scss";

export default function Spinner({ width = "24px" }) {
  return (
    <img
      style={{ width }}
      className={Styles.loader}
      src={Images.LOADER_GRADIENT}
      alt="loading"
    />
  );
}
