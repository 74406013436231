import useQuery from "@Hooks/useQuery";
import PrivacyPolicy from "@Organism/PrivacyPolicy";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicyPage() {
  const navigate = useNavigate();
  const query = useQuery();
  return (
    <PrivacyPolicy
      isSinglePage={query.get("app") !== "true"}
      onClickBackBtn={
        query.get("app") === "true" ? () => navigate("/") : () => {}
      }
    />
  );
}
