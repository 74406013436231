import InputField from "@Atom/InputField";
import useWindowSize from "@Hooks/useWindowSize";
import React, { useCallback, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import DatePicker from "@Atom/DatePicker";
import Checkbox from "@Atom/Checkbox";
import {
  getDistricts,
  getProvinces,
  getRegencies,
  getVillages,
} from "@Services/region/getRegionIndonesia";
import { fetchListsBank } from "@Services/fetchListsBank";
export default function FormDataPersonal({
  setInputDataPersonal,
  inputDataPersonal,
  setIsLifeTimeNik = () => {},
  isLifeTimeNik,
  isTheSameAddress,
  setIsTheSameAddress = () => {},
  topRef,
  isErrorFormPersonalData,
  setIsErrorFormPersonalData = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const { width } = useWindowSize();
  const handleChangeInput = (name, value) => {
    const keys = name.split(".");
    if (value) {
      setIsErrorFormPersonalData((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
    if (
      name === "telephone" ||
      name === "nik" ||
      name === "bodyHeight" ||
      name === "npwp" ||
      name === "postCode" ||
      name === "bankNumber"
    ) {
      const isNumber = /^[+-]?\d*\.?\d*$/.test(value);
      if (isNumber) {
        setInputDataPersonal((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (keys.length > 1) {
      setInputDataPersonal((prev) => ({
        ...prev,
        [keys[0]]: {
          ...prev[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setInputDataPersonal((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // const [inputDataPersonal, setInputDataPersonal] = useState();

  const genders = ["Laki-laki", "Perempuan"];
  const religions = ["Islam", "Kristen", "Hindu", "Buddha", "Konghucu"];
  const maritalStatus = ["K0", "K1", "K2", "K3", "K4", "Belum Menikah"];
  //   const [provincy, setProvincy] = useState("");

  const {
    domicilies,
    dataFamiliesAndContact,
    familiesAndContactNotTheSameHouse,
  } = inputDataPersonal;
  //   const { domicilies: errorDomicilies } = isErrorFieldPersonal;
  //
  // domicilies
  //   const [dataMapProvinceDomicilies,setDataMapProvinceDomicilies] = useState([])
  //

  const [provinces, setProvinces] = useState([]);
  const [getIdProvince, setGetIdProvince] = useState("");

  const fetchProvinces = async () => {
    setLoading(true);
    try {
      const { data } = await getProvinces();
      setProvinces(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProvinces();
  }, []);

  const { province, regency, district, village } = inputDataPersonal;
  const getIdProvinces = useCallback(() => {
    return provinces?.data
      ?.filter((dataProvince) => dataProvince.name === province)
      ?.map((getId) => setGetIdProvince(getId?.code));
  }, [provinces?.data, province]);

  useEffect(() => {
    if (province) {
      getIdProvinces();
    }
  }, [getIdProvinces, province]);
  const [regencies, setRegencies] = useState([]);
  const fetchDataRegencies = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getRegencies(getIdProvince);
      setRegencies(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [getIdProvince]);
  useEffect(() => {
    if (getIdProvince) {
      fetchDataRegencies();
    }
  }, [fetchDataRegencies, getIdProvince]);

  const [getIdRegency, setGetIdRegency] = useState("");
  const fetchIdRegency = useCallback(() => {
    return regencies?.data
      ?.filter((dataRegencies) => dataRegencies.name === regency)
      ?.map((getId) => setGetIdRegency(getId?.code));
  }, [regencies?.data, regency]);
  useEffect(() => {
    if (province) {
      fetchIdRegency();
    }
  }, [fetchIdRegency, province]);

  const [dataDistricts, setDataDistricts] = useState([]);
  const fetchDistricts = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getDistricts(getIdRegency);
      setDataDistricts(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [getIdRegency]);
  useEffect(() => {
    if (getIdRegency) {
      fetchDistricts();
    }
  }, [fetchDistricts, getIdRegency]);
  //
  const [getIdDistrict, setGetIdDistrict] = useState("");
  const fetchIdDistrict = useCallback(() => {
    return dataDistricts?.data
      ?.filter((d) => d.name === district)
      ?.map((getId) => setGetIdDistrict(getId?.code));
  }, [dataDistricts?.data, district]);
  useEffect(() => {
    if (regency) {
      fetchIdDistrict();
    }
  }, [fetchIdRegency, fetchIdDistrict, province, regency]);
  const [dataVillages, setDataVillages] = useState([]);
  const fetcthVillages = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getVillages(getIdDistrict);
      setDataVillages(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [getIdDistrict]);
  useEffect(() => {
    if (getIdDistrict) {
      fetcthVillages();
    }
  }, [fetcthVillages, getIdDistrict]);
  //
  useEffect(() => {
    setInputDataPersonal((prev) => ({
      ...prev,
      isLifeTimeNik: isLifeTimeNik,
    }));
  }, [isLifeTimeNik, setInputDataPersonal]);
  useEffect(() => {
    if (province) {
      setInputDataPersonal((prev) => ({
        ...prev,
        province,
      }));
    }
    if (regency) {
      setInputDataPersonal((prev) => ({
        ...prev,
        regency,
      }));
    }
    if (district) {
      setInputDataPersonal((prev) => ({
        ...prev,
        district,
      }));
    }
    if (village) {
      setInputDataPersonal((prev) => ({
        ...prev,
        village,
      }));
    }
  }, [
    isLifeTimeNik,
    province,
    regency,
    district,
    village,
    setInputDataPersonal,
  ]);
  // useEffect(() => {
  //   setInputDataPersonal((prev) => ({
  //     ...prev,
  //     regency: "",
  //   }));
  // }, [province, setInputDataPersonal]);
  // useEffect(() => {
  //   setInputDataPersonal((prev) => ({
  //     ...prev,
  //     district: "",
  //   }));
  // }, [regency, province, setInputDataPersonal]);
  // useEffect(() => {
  //   setInputDataPersonal((prev) => ({
  //     ...prev,
  //     village: "",
  //   }));
  // }, [regency, province, district, setInputDataPersonal]);
  // same domicilies
  useEffect(() => {
    if (isTheSameAddress) {
      setInputDataPersonal((prev) => ({
        ...prev,
        domicilies: {
          address: prev.address,
          rt: prev.rt,
          rw: prev.rw,
          village: prev.village,
          district: prev.district,
          regency: prev.regency,
          province: prev.province,
          postCode: prev.postCode,
        },
      }));
    }
  }, [isTheSameAddress, setInputDataPersonal]);
  const [dataListBank, setDataListBank] = useState();

  const getListsBank = async () => {
    try {
      const response = await fetchListsBank();
      setDataListBank(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getListsBank();
  }, []);

  // domicilies fetch list region
  const [dataDomiciliesProvinces, setDataDomicilesProvinces] = useState();
  const [getIdProvinceDomicilies, setGetIdProvinceDomicilies] = useState("");

  const [dataDomiciliesRegencies, setDataDomiciliesRegencies] = useState();
  const [getIdRegenciesDomicilies, setGetIdRegenciesDomicilies] = useState("0");

  const [dataDomiciliesDistrict, setDataDomiciliesDistrict] = useState();
  const [getIdDistrictDomicilies, setGetIdDistrictDomicilies] = useState();

  const [dataVillagesDomicilies, setDataVillagesDomicilies] = useState();

  const fetchProvincesDomicilies = async () => {
    try {
      const { data } = await getProvinces();
      setDataDomicilesProvinces(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProvincesDomicilies();
  }, []);

  const getIdProvincesDomicilies = useCallback(() => {
    return dataDomiciliesProvinces?.data
      ?.filter((d) => d?.name === domicilies?.province)
      ?.map((c) => setGetIdProvinceDomicilies(c?.code));
  }, [dataDomiciliesProvinces?.data, domicilies?.province]);

  useEffect(() => {
    if (domicilies?.province) {
      getIdProvincesDomicilies();
    }
  }, [domicilies?.province, getIdProvincesDomicilies]);

  const fetchDataRegenciesDomicilies = useCallback(async () => {
    try {
      const { data } = await getRegencies(getIdProvinceDomicilies);
      setDataDomiciliesRegencies(data);
    } catch (error) {
      console.log(error);
    }
  }, [getIdProvinceDomicilies]);

  useEffect(() => {
    if (getIdProvinceDomicilies) {
      fetchDataRegenciesDomicilies();
    }
  }, [fetchDataRegenciesDomicilies, getIdProvinceDomicilies]);

  const getIdRegencyDomicilies = useCallback(() => {
    return dataDomiciliesRegencies?.data
      ?.filter((d) => d?.name === domicilies?.regency)
      ?.map((c) => setGetIdRegenciesDomicilies(c?.code));
  }, [dataDomiciliesRegencies?.data, domicilies?.regency]);

  useEffect(() => {
    if (getIdRegenciesDomicilies) {
      getIdRegencyDomicilies();
    }
  }, [getIdRegenciesDomicilies, getIdRegencyDomicilies]);

  const fetchDataDistrictDomicilies = useCallback(async () => {
    try {
      const { data } = await getDistricts(getIdRegenciesDomicilies);
      setDataDomiciliesDistrict(data);
    } catch (error) {
      console.log(error);
    }
  }, [getIdRegenciesDomicilies]);

  useEffect(() => {
    if (getIdRegenciesDomicilies) {
      fetchDataDistrictDomicilies();
    }
  }, [fetchDataDistrictDomicilies, getIdRegenciesDomicilies]);

  const getDataIdDistrictDomiclies = useCallback(() => {
    return dataDomiciliesDistrict?.data
      ?.filter((d) => d?.name === domicilies?.district)
      ?.map((c) => setGetIdDistrictDomicilies(c?.code));
  }, [dataDomiciliesDistrict?.data, domicilies?.district]);

  useEffect(() => {
    if (getIdRegenciesDomicilies) {
      getDataIdDistrictDomiclies();
    }
  }, [getDataIdDistrictDomiclies, getIdRegenciesDomicilies]);

  const fetchDataVillagesDomicilies = useCallback(async () => {
    try {
      const { data } = await getVillages(getIdDistrictDomicilies);
      setDataVillagesDomicilies(data);
    } catch (error) {
      console.log(error);
    }
  }, [getIdDistrictDomicilies]);

  useEffect(() => {
    if (getIdDistrictDomicilies) {
      fetchDataVillagesDomicilies();
    }
  }, [fetchDataVillagesDomicilies, getIdDistrictDomicilies]);

  // useEffect(() => {
  //   setInputDataPersonal((prev) => ({
  //     ...prev,
  //     domicilies: {
  //       ...prev.domicilies,
  //       regency: "",
  //     },
  //   }));
  // }, [domicilies.province, setInputDataPersonal]);

  // useEffect(() => {
  //   setInputDataPersonal((prev) => ({
  //     ...prev,
  //     domicilies: {
  //       ...prev.domicilies,
  //       district: "",
  //     },
  //   }));
  // }, [domicilies.regency, domicilies.province, setInputDataPersonal]);

  // useEffect(() => {
  //   setInputDataPersonal((prev) => ({
  //     ...prev,
  //     domicilies: {
  //       ...prev.domicilies,
  //       village: "",
  //     },
  //   }));
  // }, [
  //   domicilies.regency,
  //   domicilies.province,
  //   domicilies.district,
  //   setInputDataPersonal,
  // ]);
  //  end

  return (
    <section className={Styles.containInput}>
      <div
        style={{
          padding: "0",
          margin: "0",
          height: "10px",
          marginBottom: "-26px",
        }}
        ref={topRef}
      ></div>
      <InputField
        isLabel
        textLabel="Nama"
        isRequired
        placeholder="Nama Karyawan"
        setValue={(val) => handleChangeInput("name", val)}
        value={inputDataPersonal?.name}
        isError={isErrorFormPersonalData?.name}
        textError="Nama Karyawan Belum di isi"
      />
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="No HP"
          isRequired
          placeholder="08"
          setValue={(val) => handleChangeInput("telephone", val)}
          value={inputDataPersonal?.telephone}
          isError={isErrorFormPersonalData?.telephone}
          textError="No Hp Belum di isi"
        />
        <InputField
          isLabel
          textLabel="Email"
          isRequired
          placeholder="example@mail.com"
          type="email"
          setValue={(val) => handleChangeInput("email", val)}
          value={inputDataPersonal?.email}
          isError={isErrorFormPersonalData?.email}
          textError="Email Belum di isi atau format salah"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Nomor KTP"
          isRequired
          placeholder="NIK"
          setValue={(val) => handleChangeInput("nik", val)}
          value={inputDataPersonal?.nik}
          isError={isErrorFormPersonalData?.nik}
          textError="Nomor KTP Belum di isi"
        />
        <DatePicker
          textLabel="Masa Berlaku"
          type={"date"}
          isAddLabel
          placeholder="Pilih Tanggal"
          isRequired
          textError="Masa Berlaku NIK Belum di pilih"
          setValue={(val) => handleChangeInput("dateNik", val)}
          value={isLifeTimeNik ? "" : inputDataPersonal?.dateNik}
          isAddCheckbox
          setIsChecked={setIsLifeTimeNik}
          isChecked={inputDataPersonal?.isLifeTimeNik}
          isError={isLifeTimeNik ? false : isErrorFormPersonalData?.dateNik}
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          placeholder="Tempat Lahir"
          isRequired
          textLabel="Tempat lahir"
          setValue={(val) => handleChangeInput("placeOfBirth", val)}
          value={inputDataPersonal?.placeOfBirth}
          isError={isErrorFormPersonalData?.placeOfBirth}
          textError="Tempat Lahir Belum di isi"
        />
        <DatePicker
          textLabel="Tanggal Lahir"
          placeholder="Pilih Tanggal"
          isAddLabel
          isRequired
          setValue={(val) => handleChangeInput("dateOfBirth", val)}
          value={inputDataPersonal?.dateOfBirth}
          isError={isErrorFormPersonalData?.dateOfBirth}
          textError="Tanggal Lahir Belum di pilih"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Jenis Kelamin"
          isRequired
          placeholder="Pilih jenis kelamin"
          isDropdown
          dataMapDropdown={genders}
          setValue={(val) => handleChangeInput("gender", val)}
          value={inputDataPersonal?.gender}
          isError={isErrorFormPersonalData?.gender}
          textError="Jenis Kelamin Belum di pilih"
        />
        <InputField
          isLabel
          textLabel="Agama"
          isRequired
          placeholder="Pilih Agama"
          isDropdown
          setValue={(val) => handleChangeInput("religion", val)}
          value={inputDataPersonal?.religion}
          dataMapDropdown={religions}
          isError={isErrorFormPersonalData?.religion}
          textError="Agama Belum di pilih"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Tinggi Badan (Cm)"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("bodyHeight", val)}
          value={inputDataPersonal?.bodyHeight}
          isError={isErrorFormPersonalData?.bodyHeight}
          textError="Tinggi Badan Belum di isi"
        />
        <InputField
          isLabel
          textLabel="Golongan Darah"
          isRequired
          placeholder="Golongan darah"
          setValue={(val) => handleChangeInput("bloodType", val)}
          value={inputDataPersonal?.bloodType}
          isError={isErrorFormPersonalData?.bloodType}
          textError="Golongan Darah Belum di isi"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Status Kawin"
          isRequired
          placeholder="Pilih status kawin"
          isDropdown
          setValue={(val) => handleChangeInput("maritalStatus", val)}
          value={inputDataPersonal?.maritalStatus}
          dataMapDropdown={maritalStatus}
          isError={isErrorFormPersonalData?.maritalStatus}
          textError="Golongan Darah Belum di pilih"
        />
        <InputField
          isLabel
          textLabel="NPWP"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("npwp", val)}
          value={inputDataPersonal?.npwp}
          isError={isErrorFormPersonalData?.npwp}
          textError="NPWP Belum di isi"
        />
      </div>
      <div
        style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
      >
        <InputField
          isLabel
          textLabel="Nama Ibu Kandung"
          isRequired
          placeholder="Nama ibu kandung"
          setValue={(val) => handleChangeInput("birthMothersName", val)}
          value={inputDataPersonal?.birthMothersName}
          isError={isErrorFormPersonalData?.birthMothersName}
          textError="Nama Ibu Kandung Belum di isi"
        />
      </div>
      <div className={Styles.sticky}>
        <h2>Alamat </h2>
        <div className={Styles.divider}></div>
      </div>
      <h4 className={Styles.labelTextHeader}>Alamat KTP</h4>

      <InputField
        isLabel
        textLabel="Detail Alamat"
        isRequired
        placeholder="Nama"
        isTextArea
        setValue={(val) => handleChangeInput("address", val)}
        value={inputDataPersonal?.address}
        isError={isErrorFormPersonalData?.address}
        textError="Detail Alamat KTP Belum di isi"
      />

      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Provinsi"
          isRequired
          placeholder="Pilih Provinsi"
          isDropdown
          dataMapDropdown={provinces?.data?.map((p) => p.name)}
          setValue={(val) => handleChangeInput("province", val)}
          value={inputDataPersonal?.province}
          isError={isErrorFormPersonalData?.province}
          textError="Provinsi Belum di pilih"
          isLoading={loading}
        />
        <InputField
          isLabel
          textLabel="Kabupaten/Kota"
          isRequired
          placeholder="Pilih Kabupaten/Kota"
          isDropdown
          dataMapDropdown={
            !province
              ? ["Silahkan Pilih Provinsi Terlebih Dahulu"]
              : regencies?.data?.map((r) => r?.name)
          }
          value={inputDataPersonal?.regency}
          setValue={
            !province ? () => {} : (val) => handleChangeInput("regency", val)
          }
          isError={isErrorFormPersonalData?.regency}
          textError="Kabupaten/Kota Belum di pilih"
          isLoading={loading}
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Kecamatan"
          isRequired
          placeholder="Pilih Kecamatan"
          isDropdown
          dataMapDropdown={
            !regency
              ? ["Silahkan Pilih Kota Terlebih Dahulu"]
              : dataDistricts?.data?.map((d) => d?.name)
          }
          setValue={
            !regency ? () => {} : (val) => handleChangeInput("district", val)
          }
          value={inputDataPersonal?.district}
          isError={isErrorFormPersonalData?.district}
          textError="Kecamatan Belum di pilih"
          isLoading={loading}
        />
        <InputField
          isLabel
          textLabel="Kelurahan"
          isRequired
          placeholder="Pilih Kelurahan"
          isDropdown
          dataMapDropdown={
            !district
              ? ["Silahkan Pilih Kecamatan Terlebih Dahulu"]
              : dataVillages?.data?.map((v) => v?.name)
          }
          value={inputDataPersonal?.village}
          setValue={
            !district ? () => {} : (val) => handleChangeInput("village", val)
          }
          isError={isErrorFormPersonalData?.village}
          textError="Kelurahan / Desa Belum di isi"
          isLoading={loading}
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="RT"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("rt", val)}
          value={inputDataPersonal?.rt}
          isError={isErrorFormPersonalData?.rt}
          textError="RT Belum di isi"
        />
        <InputField
          isLabel
          textLabel="RW"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("rw", val)}
          value={inputDataPersonal?.rw}
          isError={isErrorFormPersonalData?.rw}
          textError="RW Belum di isi"
        />
      </div>
      <div
        style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
      >
        <InputField
          isLabel
          textLabel="Kode Pos"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("postCode", val)}
          value={inputDataPersonal?.postCode}
          isError={isErrorFormPersonalData?.postCode}
          textError="Kode Pos Belum di isi"
        />
      </div>
      <div className={Styles.sticky}>
        <h2>Alamat Domisili</h2>
        <div className={Styles.divider}></div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Checkbox
          isChecked={isTheSameAddress}
          setIsChecked={setIsTheSameAddress}
        />
        <span style={width < 851 ? { fontSize: "12px" } : { undefined }}>
          Apakah alamat domisili sama dengan alamat KTP?
        </span>
      </div>
      <InputField
        isLabel
        isDisabled={isTheSameAddress}
        value={domicilies.address}
        textLabel="Detail Alamat"
        isRequired
        placeholder="Nama Alamat"
        isTextArea
        setValue={(val) => handleChangeInput("domicilies.address", val)}
        isError={isErrorFormPersonalData?.domicilies?.address}
        textError="Alamat Domisili Belum di isi"
      />

      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Provinsi"
          isRequired
          placeholder="Pilih Provinsi"
          isDropdown
          value={domicilies.province}
          isDisabled={isTheSameAddress}
          isError={isErrorFormPersonalData?.domicilies?.province}
          textError="Provinsi Belum di pilih"
          dataMapDropdown={dataDomiciliesProvinces?.data?.map((p) => p.name)}
          setValue={(val) => handleChangeInput("domicilies.province", val)}
        />
        <InputField
          isLabel
          textLabel="Kabupaten/Kota"
          isRequired
          placeholder="Pilih Kabupaten/Kota"
          isDropdown
          isDisabled={isTheSameAddress}
          value={domicilies.regency}
          isError={isErrorFormPersonalData?.domicilies?.regency}
          textError="Kabupaten/Kota Belum di pilih"
          setValue={(val) => handleChangeInput("domicilies.regency", val)}
          dataMapDropdown={
            !domicilies?.province
              ? ["Silahkan Pilih Provinsi Terlebih Dahulu"]
              : dataDomiciliesRegencies?.data?.map((r) => r?.name)
          }
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Kecamatan"
          isRequired
          placeholder="Pilih Kecamatan"
          isDropdown
          value={domicilies.district}
          isDisabled={isTheSameAddress}
          dataMapDropdown={
            !domicilies.regency
              ? ["Silahkan Pilih Kota Terlebih Dahulu"]
              : dataDomiciliesDistrict?.data?.map((d) => d?.name)
          }
          setValue={
            !domicilies.regency
              ? () => {}
              : (val) => handleChangeInput("domicilies.district", val)
          }
          isError={isErrorFormPersonalData?.domicilies?.district}
          textError="Kecamatan Belum di pilih"
        />
        <InputField
          isLabel
          textLabel="Kelurahan"
          isRequired
          placeholder="Pilih Kelurahan"
          isDropdown
          value={domicilies.village}
          isDisabled={isTheSameAddress}
          dataMapDropdown={
            !domicilies.district
              ? ["Silahkan Pilih Kecamatan Terlebih Dahulu"]
              : dataVillagesDomicilies?.data?.map((d) => d?.name)
          }
          setValue={
            !domicilies.district
              ? () => {}
              : (val) => handleChangeInput("domicilies.village", val)
          }
          isError={isErrorFormPersonalData?.domicilies?.village}
          textError="Kelurahan Belum di pilih"
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          isDisabled={isTheSameAddress}
          value={domicilies.rt}
          textLabel="RT"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("domicilies.rt", val)}
          isError={isErrorFormPersonalData?.domicilies?.rt}
          textError="RT Belum di isi"
        />
        <InputField
          isLabel
          isDisabled={isTheSameAddress}
          value={domicilies.rw}
          textLabel="RW"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("domicilies.rw", val)}
          isError={isErrorFormPersonalData?.domicilies?.rw}
          textError="RW Belum di isi"
        />
      </div>
      <div
        style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
      >
        <InputField
          isLabel
          isDisabled={isTheSameAddress}
          value={domicilies.postCode}
          textLabel="Kode Pos"
          isRequired
          placeholder="0"
          setValue={(val) => handleChangeInput("domicilies.postCode", val)}
          isError={isErrorFormPersonalData?.domicilies?.postCode}
          textError="Kode Pos Belum di isi"
        />
      </div>
      <div className={Styles.sticky}>
        <h2>Data Keluarga & Kontak </h2>
        <div className={Styles.divider}></div>
      </div>
      <h4 className={Styles.labelTextHeader}>Informasi Umum</h4>

      <InputField
        isLabel
        textLabel="Nomor KK"
        isRequired
        placeholder="Nomor KK"
        value={dataFamiliesAndContact.kk}
        setValue={(val) => handleChangeInput("dataFamiliesAndContact.kk", val)}
        isError={isErrorFormPersonalData?.dataFamiliesAndContact?.kk}
        textError="Nomor KK Belum di isi"
      />
      <h4 className={Styles.labelTextHeader}>Kontak Darurat</h4>
      <InputField
        isLabel
        textLabel="Nama"
        isRequired
        placeholder="Nama"
        value={dataFamiliesAndContact.name}
        setValue={(val) =>
          handleChangeInput("dataFamiliesAndContact.name", val)
        }
        isError={isErrorFormPersonalData?.dataFamiliesAndContact?.name}
        textError="Nama Kontak Darurat Belum di isi"
      />
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Hubungan Kontak Darurat"
          isRequired
          placeholder="Hubungan"
          value={dataFamiliesAndContact.contactRelations}
          setValue={(val) =>
            handleChangeInput("dataFamiliesAndContact.contactRelations", val)
          }
          isError={
            isErrorFormPersonalData?.dataFamiliesAndContact?.contactRelations
          }
          textError="Hubungan Kontak Darurat Belum di isi"
        />
        <InputField
          isLabel
          textLabel="Nomor HP"
          isRequired
          placeholder="+62"
          value={dataFamiliesAndContact.telephone}
          setValue={(val) =>
            handleChangeInput("dataFamiliesAndContact.telephone", val)
          }
          isError={isErrorFormPersonalData?.dataFamiliesAndContact?.telephone}
          textError="Nomor HP Kontak Darurat Belum di isi"
        />
      </div>
      <InputField
        isLabel
        textLabel="Detail Alamat"
        isRequired
        placeholder="Nama Alamat"
        isTextArea
        value={dataFamiliesAndContact.address}
        setValue={(val) =>
          handleChangeInput("dataFamiliesAndContact.address", val)
        }
        isError={isErrorFormPersonalData?.dataFamiliesAndContact?.address}
        textError="Detail Alamat Belum di isi"
      />
      <h4 className={Styles.labelTextHeader}>Kontak Keluarga Tidak Serumah</h4>
      <InputField
        isLabel
        textLabel="Nama"
        isRequired
        placeholder="Nama"
        value={familiesAndContactNotTheSameHouse.name}
        setValue={(val) =>
          handleChangeInput("familiesAndContactNotTheSameHouse.name", val)
        }
        isError={
          isErrorFormPersonalData?.familiesAndContactNotTheSameHouse?.name
        }
        textError="Nama Belum di isi"
      />
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Hubungan Kontak Darurat"
          isRequired
          placeholder="Hubungan"
          value={familiesAndContactNotTheSameHouse.contactRelations}
          setValue={(val) =>
            handleChangeInput(
              "familiesAndContactNotTheSameHouse.contactRelations",
              val
            )
          }
          isError={
            isErrorFormPersonalData?.familiesAndContactNotTheSameHouse
              ?.contactRelations
          }
          textError="Hubungan Kontak Darurat Belum di isi"
        />
        <InputField
          isLabel
          textLabel="Nomor HP"
          isRequired
          placeholder="+62"
          value={familiesAndContactNotTheSameHouse.telephone}
          setValue={(val) =>
            handleChangeInput(
              "familiesAndContactNotTheSameHouse.telephone",
              val
            )
          }
          isError={
            isErrorFormPersonalData?.familiesAndContactNotTheSameHouse
              ?.telephone
          }
          textError="Nomor HP Belum di isi"
        />
      </div>
      <InputField
        isLabel
        textLabel="Detail Alamat"
        isRequired
        placeholder="Nama"
        isTextArea
        value={familiesAndContactNotTheSameHouse.address}
        setValue={(val) =>
          handleChangeInput("familiesAndContactNotTheSameHouse.address", val)
        }
        isError={
          isErrorFormPersonalData?.familiesAndContactNotTheSameHouse?.address
        }
        textError="Detail Alamat Belum di isi"
      />
      <div className={Styles.sticky}>
        <h2>Data Lainya </h2>
        <div className={Styles.divider}></div>
      </div>
      <h4 className={Styles.labelTextHeader}>Data Bank</h4>

      <InputField
        isLabel
        textLabel="Nama Pemilik Rekening"
        isRequired
        placeholder="Nama pemilik rekening"
        value={inputDataPersonal.nameAccountBank}
        setValue={(val) => handleChangeInput("nameAccountBank", val)}
        isError={isErrorFormPersonalData?.nameAccountBank}
        textError="Nama Pemilik Rekening Belum di isi"
      />
      <div
        // className={Styles.betweenInput}
        // is-mobile={(width < 851).toString()} // inih
        style={{
          display: "flex",
          gap: width < 768 ? "8px" : "16px",
        }}
      >
        <InputField
          isLabel
          textLabel="Bank "
          isRequired
          placeholder="Pilih Bank"
          isDropdown
          value={inputDataPersonal.bank}
          setValue={(val) => handleChangeInput("bank", val)}
          isError={isErrorFormPersonalData?.bank}
          textError="Bank Belum di pilih"
          dataMapDropdown={dataListBank?.data?.response.map((el) => el?.name)}
        />
        <InputField
          isLabel
          textLabel="Nomor Rekening"
          isRequired
          placeholder="0"
          value={inputDataPersonal.bankNumber}
          setValue={(val) => handleChangeInput("bankNumber", val)}
          isError={isErrorFormPersonalData?.bankNumber}
          textError="Nomor Rekening Belum di isi"
        />
      </div>
      <h4 className={Styles.labelTextHeader}>Sosial Media</h4>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Facebook"
          placeholder="Username"
          value={inputDataPersonal.facebook}
          setValue={(val) => handleChangeInput("facebook", val)}
        />
        <InputField
          isLabel
          textLabel="Instagram"
          placeholder="Username"
          value={inputDataPersonal.instagram}
          setValue={(val) => handleChangeInput("instagram", val)}
        />
      </div>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Twitter"
          placeholder="Username"
          value={inputDataPersonal.twitter}
          setValue={(val) => handleChangeInput("twitter", val)}
        />
        <InputField
          isLabel
          textLabel="Linkedin"
          placeholder="Username"
          value={inputDataPersonal.linkedIn}
          setValue={(val) => handleChangeInput("linkedIn", val)}
        />
      </div>
      <h4 className={Styles.labelTextHeader}>Lainya</h4>
      <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
        <InputField
          isLabel
          textLabel="Hobi"
          placeholder="Hobi"
          value={inputDataPersonal.hobby}
          setValue={(val) => handleChangeInput("hobby", val)}
        />
        <InputField
          isLabel
          textLabel="Klinik/Puskesmas Terdekat"
          isRequired
          placeholder="Klinik/Puskesmas Terdekat"
          value={inputDataPersonal.clinic}
          setValue={(val) => handleChangeInput("clinic", val)}
          isError={isErrorFormPersonalData?.clinic}
          textError="Klinik/Puskesmas Terdekat Belum di isi"
        />
      </div>
    </section>
  );
}
