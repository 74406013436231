import { API } from "configs";

export function getProvinces() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/areaData/provinces`);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getRegencies(provinceId = "0") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/areaData/regencies/${provinceId}`);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDistricts(regencyId = "0") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/areaData/districts/${regencyId}`);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
export function getVillages(districtId = "0") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/areaData/villages/${districtId}`);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
