import router from "@Navigators/index";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import "moment/locale/id";
import moment from "moment";
moment.locale("id");

function App() {
  return <RouterProvider router={router} />;
}

export default App;
