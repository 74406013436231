export const dataFamilies = [
  {
    contactRelations: "",
    name: "",
    placeOfBirth: "",
    dateOfBirth: "",
    nik: "",
    gender: "",
    jobStatus: "",
  },
];
