import Card from '@Atom/Card'
import Styles from './style.module.scss'

export default function Dropdown({
  variant = 'default',
  className = '',
  options = [],
  borderRadius = '8px',
  ...props
}) {

  // const options: [
  //     {
  //       title: '',
  //       isActive: false,
  //       onClick: ()=> {},
  //     }
  //   ]

  return (
    <Card
      {...props}
      borderRadius={borderRadius}
      variant={variant}
      className={`${Styles.container} ${className}`}
    >
      {
        options?.map((o, oI) => (
          <div
            key={oI}
            className={Styles.each}
            isActive={o?.isActive ? 'true' : 'false'}
            onClick={o?.onClick}
          >
            {o?.title}
          </div>
        ))
      }
    </Card>
  )
}