import Styles from './style.module.scss'

export default function Card({
  children,
  className = '',
  borderRadius = '8px',
  backgroundColor = '#fff',
  boxShadow = '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
  ...props
}) {

  return (
    <div
      {...props}
      className={`${Styles.container} ${className}`}
      style={{
        borderRadius,
        backgroundColor,
        boxShadow
      }}
    >
      {children}
    </div>
  )
}