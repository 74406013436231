import React, { memo, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import InputField from "@Atom/InputField";
import RadioButton from "@Atom/RadioButton";
// import { cretateAttendenceSchema } from "@Services/AttendenceSchema/cretateAttendenceSchema";
function ModalAddSchemaAbsence({
  onClose = () => {},
  isOpen = false,
  onSubmit = () => {},
  isDisabled = false,
  isFilled = false,
  fieldRadioButtons = ["2 Shift Kerja", "3 Shift Kerja", "Non Shift"],
  setActiveShift = () => {},
  activeShift,
  setDataToSend = () => {},
  dataToSend = {
    name: "",
    amountShiftings: {
      morningShift: {
        startTime: "",
        endTime: "",
      },
      afterNoonShift: {
        startTime: "",
        endTime: "",
      },
      nightShift: {
        startTime: "",
        endTime: "",
      },
      nonShift: {
        startTime: "",
        endTime: "",
      },
    },
    timeIntervalPatrol: "",
    timeIntervalActivity: "",
  },
  setShiftStartTimeMorning = () => {},
  shiftStartTimeMorning = { time: "" },
  setShiftEndTimeMorning = () => {},
  shiftEndTimeMorning = { time: "" },
  setStartimeAfterNoonShift = () => {},
  startimeAfterNoonShift = { time: "" },
  setEndTimeAfterNoonShift = () => {},
  endTimeAfterNoonShift = { time: "" },
  setStartimeNightShift = () => {},
  startimeNightShift = { time: "" },
  setEndTimeNightShift = () => {},
  endTimeNightShift = { time: "" },
  setStarTimeNonShift = () => {},
  starTimeNonShift = { time: "" },
  setEndTimeNonShift = () => {},
  endTimeNonShift = { time: "" },
  isLoadingHit = false,
  onClickDeleteIcon = () => {},
}) {
  const handleChangeInput = (name, val) => {
    const inputNumber = val.replace(/\D/g, "");
    if (name === "timeIntervalPatrol" || name === "timeIntervalActivity") {
      setDataToSend((prev) => ({
        ...prev,
        [name]: inputNumber,
      }));
    } else {
      setDataToSend((prev) => ({
        ...prev,
        [name]: val,
      }));
    }
  };

  const [isTransition, setIsTransition] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsTransition(true);
      }, 100);
    } else {
      setIsTransition(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={`${Styles.containerModalAddSchemaAbsence} ${
          isTransition && Styles.right
        } `}
      >
        <div className={Styles.headerModal}>
          <strong>Tambah Skema Absensi</strong>
          <Icon
            icon={"close-circle"}
            onClick={onClose}
            className={Styles.closeCircle}
          />
        </div>

        <div className={Styles.inputField}>
          <InputField
            isLabel
            textLabel="Nama Skema"
            isRequired
            placeholder="Masukkan skema"
            setValue={(e) => handleChangeInput("name", e)}
            value={dataToSend.name}
          />
          <div className={Styles.viewRowInput}>
            <div className={Styles.label}>
              <label htmlFor="">
                Jumlah Shift Kerja <span>*</span>{" "}
              </label>
            </div>
            <div className={Styles.radio}>
              {fieldRadioButtons.map((r, idx) => (
                <div key={`radio-checked-${idx}`}>
                  <div className={Styles.radioButton}>
                    <RadioButton
                      isActive={activeShift === r}
                      setIsActive={() => setActiveShift(r)}
                    />
                    <span>{r}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {activeShift !== fieldRadioButtons[2] && (
            <div className={Styles.selectTime}>
              <label htmlFor="">
                Jam Kerja Shift Pagi<span>*</span>
              </label>
              <div>
                <InputField
                  placeholder="Jam Mulai"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setShiftStartTimeMorning}
                  value={shiftStartTimeMorning.time}
                />
                <InputField
                  placeholder="Jam Berakhir"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setShiftEndTimeMorning}
                  value={shiftEndTimeMorning.time}
                />
              </div>
            </div>
          )}
          {/* SIANG */}
          {activeShift === fieldRadioButtons[1] && (
            <div className={Styles.selectTime}>
              <label htmlFor="">
                Jam Kerja Shift Siang<span>*</span>
              </label>
              <div>
                <InputField
                  placeholder="Jam Mulai"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setStartimeAfterNoonShift}
                  value={startimeAfterNoonShift.time}
                />
                <InputField
                  placeholder="Jam Berakhir"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setEndTimeAfterNoonShift}
                  value={endTimeAfterNoonShift.time}
                />
              </div>
            </div>
          )}
          {/* MALAM */}
          {activeShift !== fieldRadioButtons[2] && (
            <div className={Styles.selectTime}>
              <label htmlFor="">
                Jam Kerja Shift Malam<span>*</span>
              </label>
              <div>
                <InputField
                  placeholder="Jam Mulai"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setStartimeNightShift}
                  value={startimeNightShift.time}
                />
                <InputField
                  placeholder="Jam Berakhir"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setEndTimeNightShift}
                  value={endTimeNightShift.time}
                />
              </div>
            </div>
          )}
          {/* NON SHIFT */}
          {(activeShift === fieldRadioButtons[0] ||
            activeShift === fieldRadioButtons[2] ||
            activeShift === fieldRadioButtons[1]) && (
            <div className={Styles.selectTime}>
              <label htmlFor="">
                Jam Kerja Shift Non Shift<span>*</span>
              </label>
              <div>
                <InputField
                  placeholder="Jam Mulai"
                  isselecttime
                  isFilled={isFilled}
                  setValue={setStarTimeNonShift}
                  value={starTimeNonShift.time}
                />
                <InputField
                  placeholder="Jam Berakhir"
                  isselecttime
                  setValue={setEndTimeNonShift}
                  value={endTimeNonShift.time}
                  isFilled={isFilled}
                />
              </div>
            </div>
          )}
          <div className={Styles.selectTime}>
            <div>
              <InputField
                isLabel
                textLabel="Jarak Waktu Laporan Patroli"
                placeholder="Contoh: 20"
                isAddElement
                textElement="Menit"
                isRequired
                setValue={(e) => handleChangeInput("timeIntervalPatrol", e)}
                value={dataToSend.timeIntervalPatrol}
              />
              <InputField
                isLabel
                textLabel="Jarak Waktu Laporan Aktifitas"
                placeholder="Contoh: 20"
                isAddElement
                textElement="Menit"
                isRequired
                setValue={(e) => handleChangeInput("timeIntervalActivity", e)}
                value={dataToSend.timeIntervalActivity}
              />
            </div>
          </div>
        </div>
        <div className={`${Styles.footerModal} ${isFilled && Styles.between} `}>
          {isFilled && (
            <Icon
              icon={"trash"}
              onClick={onClickDeleteIcon}
              size={"24"}
              className={Styles.trashIcon}
            />
          )}
          <Button
            text="Simpan Skema Absensi"
            isDisabled={isDisabled}
            onClick={onSubmit}
            isLoadingIndicator={isLoadingHit}
          />
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalAddSchemaAbsence);
// export default ModalAddSchemaAbsence;
