import Skeleton from "@Atom/Skeleton";
import React from "react";
import Styles from "./style.module.scss";
import SkeletonTable from "@Molecule/Skeleton/Table";
export function SkeletonListScheduleLocation() {
  return (
    <div className={Styles.viewerSkeletonCards}>
      <div className={Styles.between}>
        <Skeleton h="30px" w="250px" />
        <Skeleton h="30px" w="100px" />
      </div>

      <SkeletonTable listLength={12} />
    </div>
  );
}
