import React, { useEffect } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
export default function Toast({
  color = "green",
  text = "lorem ipsum dolor sit amet ",
  onClose = () => {},
  isopen = false,
  timeOut = 3000,
}) {
  useEffect(() => {
    if (isopen) {
      const timer = setTimeout(() => {
        onClose(!isopen);
      }, timeOut);
      return () => clearTimeout(timer);
    }
  }, [isopen, onClose, timeOut]);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        right: "50%",
      }}
    >
      <div
        className={Styles.wrapperToast}
        color={color}
        is_open={isopen?.toString()}
      >
        <div className={Styles.mainToast}>
          <div>
            <div className={Styles.containerIcon}>
              <Icon
                icon={
                  color === "green"
                    ? "tick-circle"
                    : color === "red"
                    ? "danger"
                    : color === "yellow" || color === "blue"
                    ? "info-circle"
                    : "tick-circle"
                }
                size={"20"}
                color={color === "yellow" ? "black" : "white"}
              />
            </div>
            <span style={{ color: color === "yellow" ? "black" : "" }}>
              {text}
            </span>
          </div>
          <Icon
            icon={"close-circle"}
            size={"12"}
            color={color === "yellow" ? "black" : "white"}
            className={Styles.iconX}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
}
