import { API } from "configs";

export function getAllMembersForSetSquad(
  type = "staff",
  clientCode = "",
  page = 1,
  limit = 10,
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/member/sync/${type}?clientCode=${clientCode}&page=${page}&limit=${limit}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
