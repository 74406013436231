import Skeleton, { BoxSkeleton, CircleSkeleton } from "@Atom/Skeleton";
import React from "react";
import Styles from "./style.module.scss";
export function SkeletonDetailPatrolPoint() {
  return (
    <div className={Styles.viewerSkeletonCards}>
      <div className={Styles.between}>
        <Skeleton w="200px" />
        <CircleSkeleton h="30px" w="30px" />
      </div>
      <div className={Styles.between}>
        <div className={Styles.viewRow}>
          <Skeleton h="30px" w="130px" />
          <Skeleton h="30px" w="130px" />
        </div>
        <div>
          <Skeleton h="30px" w="200px" />
        </div>
      </div>
      <div className={Styles.wrapCards}>
        {Array.from({ length: 2 }).map((_, idx) => (
          <BoxSkeleton h="300px" w="100%" key={idx} />
        ))}
      </div>
      <div className={Styles.wrapCards}>
        <BoxSkeleton h="260px" w="100%" />
      </div>
    </div>
  );
}
