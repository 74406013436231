import { API } from "configs";

export function getDetailGuardPost(guardPostID = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/guardpoint/${guardPostID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
