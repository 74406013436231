import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalAddGuardPos from "@Molecule/_modal/AddGuardPos";
import { fileBaseUrl } from "configs";
import { createGuardPost } from "@Services/GuardPost/createGuardPost";
import Toast from "@Atom/Toast";
import Button from "@Atom/Button";
import GuardPostIcon from "@Assets/Images/guard-post-icon.png";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import Spinner from "@Atom/Spinner";
export default function ListGuardPos({ data }) {
  const { height } = useWindowSize();
  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
  const { id: locationID } = useParams();
  const navigate = useNavigate();
  const [isOpenAddPoint, setIsOpenAddPoint] = useState(false);
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const query = useQuery();
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [inputLocation, setInputLocation] = useState({
    image: "",
    name: "",
    personil: "",
    fieldPosInventory: [
      {
        id: "123",
        nameInventory: "",
        amount: "",
      },
      {
        id: "234",
        nameInventory: "",
        amount: "",
      },
    ],
    guardRadius: "",
    description: "",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: +data?.latitude || 0,
    longitude: +data?.longitude || 0,
  });
  const fileRef = useRef(null);

  const [loadingPost, setLoadingPost] = useState(false);

  const [toastStatus, setToastStatus] = useState({
    success: false,
    error: false,
  });

  useEffect(() => {
    const requiredFields = [
      inputLocation.image,
      inputLocation.name,
      inputLocation.personil,
      inputLocation.guardRadius,
      inputLocation.description,
      selectedLocation.latitude,
      selectedLocation.longitude,
      inputLocation.fieldPosInventory.every((f) => f.nameInventory && f.amount),
    ];

    const isAnyFieldEmpty = requiredFields.some((field) => !field);
    setIsDisabledButton(isAnyFieldEmpty);
  }, [inputLocation, selectedLocation]);

  const handleSubmit = async () => {
    setLoadingPost(true);
    setIsOpenAddPoint(false);
    const formData = new FormData();
    formData.append("locationID", locationID);
    formData.append("file", inputLocation.image);
    formData.append("name", inputLocation.name?.trim());
    formData.append("totalPersonnel", inputLocation.personil);

    for (let f = 0; f < inputLocation.fieldPosInventory.length; f++) {
      const inventory = inputLocation.fieldPosInventory[f];
      formData.append(`inventories[${f}][name]`, inventory.nameInventory);
      formData.append(`inventories[${f}][amount]`, inventory.amount);
    }

    formData.append("description", inputLocation.description?.trim());
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("radius", inputLocation.guardRadius);

    try {
      const res = await createGuardPost(formData);
      if (res.status === 201) {
        setToastStatus((prev) => ({
          ...prev,
          success: true,
        }));
        setLoadingPost(false);
        setInputLocation({
          image: "",
          name: "",
          personil: "",
          fieldPosInventory: [
            {
              id: "123",
              nameInventory: "",
              amount: "",
            },
            {
              id: "234",
              nameInventory: "",
              amount: "",
            },
          ],
          guardRadius: "",
          description: "",
        });
        navigate(
          `${pathname}?page=${1}&limit=${10}&clientCode=${CLIENT_KEY?.code}`,
          {
            replace: true,
          }
        );
      }
    } catch (error) {
      console.log(`error add location: ${error}`);
      setLoadingPost(false);
      setToastStatus((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  const [limit, setLimit] = useState(10);
  const page = 1;
  const [searchQuery, setSearchQuery] = useState("");

  const limitQuery = query.get("limit") || 10;

  const debounceQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    navigate(`${pathname}?page=${page}&limit=${limit}&search=${debounceQuery}`);
  }, [limit, navigate, page, pathname, debounceQuery]);

  const listInnerRef = useRef();
  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < data.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [data.totalData, limit]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  return (
    <>
      {(toastStatus.error || toastStatus.success) && (
        <Toast
          isopen
          text={
            toastStatus.error
              ? "Gagal Menambahkan Lokasi"
              : "Berhasil Menambahkan Lokasi"
          }
          color={toastStatus.error ? "red" : "green"}
          onClose={() =>
            setToastStatus((prev) => ({
              ...prev,
              error: false,
              success: false,
            }))
          }
        />
      )}

      {loadingPost && !isOpenAddPoint && (
        <LoadingSpinner text="Sedang menyimpan..." />
      )}

      <div className={Styles.wrapperListPatrolPoint}>
        {isOpenAddPoint && (
          <ModalAddGuardPos
            isOpen={isOpenAddPoint}
            onClose={() => setIsOpenAddPoint(false)}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            inputLocation={inputLocation}
            fileRef={fileRef}
            onSubmit={() => handleSubmit()}
            setInputLocation={setInputLocation}
            isDisabled={isDisabledButton}
          />
        )}

        <div
          className={`${Styles.mainContainListPatrolPoint} ${
            data?.data?.length === 0 && Styles.isEmpty
          } `}
        >
          <div className={Styles.between}>
            <div className={Styles.back}>
              <Icon
                icon={"arrow-chevron-left"}
                size={"20"}
                className={Styles.backIcon}
                onClick={() => navigate("/work-location")}
              />
              <span>
                {capitalizeEachWord(
                  sessionStorage.getItem("nameDetailInfoWorkLocation")
                )}
              </span>
            </div>
            <Button
              text="Tambah Lokasi"
              onClick={() => setIsOpenAddPoint(true)}
              isLeftIcon
              isAddButton
            />
          </div>
          {data?.data?.length === 0 && !searchQuery ? (
            <CustomEmptyHandlerPage
              title="Lokasi Pos Jaga Belum Ditambahkan"
              description="Silahkan lakukan penambahan lokasi pos jaga"
              btnText="Tambah Lokasi Pos Jaga"
              images={Images.RAILWAY_STATION}
              onClick={() => setIsOpenAddPoint(true)}
            />
          ) : (
            <div className={Styles.viewFilter}>
              <SearchBar value={searchQuery} setValue={setSearchQuery} />
            </div>
          )}

          {data?.data?.length === 0 && searchQuery ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "60vh",
                alignItems: "center",
              }}
            >
              <h1>Data tidak di temukan</h1>
            </div>
          ) : (
            <div
              className={Styles.cardLocation}
              onScroll={onScroll}
              ref={listInnerRef}
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {data?.data?.map((el, idx) => (
                <Fragment key={idx}>
                  <div className={Styles.card}>
                    <div className={Styles.img}>
                      <img
                        alt="image-location"
                        src={fileBaseUrl + el?.imageURI}
                      />
                    </div>
                    <div className={Styles.description}>
                      <div className={Styles.personil}>
                        <strong>{capitalizeEachWord(el?.name)}</strong>
                        <div>
                          <img
                            src={GuardPostIcon}
                            className={Styles.peopleIcon}
                            alt="icon"
                          />
                          <span>{el?.totalPersonnel} Personil jaga</span>
                        </div>
                      </div>
                      <div
                        className={Styles.btnCard}
                        onClick={() =>
                          navigate(`${el?._id}?guardPostName=${el?.name}`)
                        }
                      >
                        <span>Lihat Detail</span>
                        <Icon
                          icon={"arrow-back"}
                          className={Styles.arrowIcon}
                        />
                      </div>
                    </div>
                    {width < 768 ? (
                      <PDFBarcodeDownload
                        checkpoint={el?.name}
                        id={el?._id}
                        description=""
                        headerSubText="QR Code Pos Penjagaan"
                      >
                        <div className={Styles.detailQr}>
                          <span>Download QR</span>
                          <Icon
                            icon={"scan-barcode"}
                            color={"#2C5364"}
                            size={"16"}
                          />
                        </div>
                      </PDFBarcodeDownload>
                    ) : (
                      <div
                        className={Styles.detailQr}
                        onClick={() =>
                          window.open(
                            `/pdf-preview?dataQuery=barcode&id=${el?._id}&postName=${el?.name}&headerSubText=QR Code Pos Penjagaan&description=`,
                            "_blank"
                          )
                        }
                      >
                        <span>Detail QR</span>
                        <Icon
                          icon={"scan-barcode"}
                          color={"#2C5364"}
                          size={"16"}
                        />
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
          {+limitQuery !== limit && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "1rem",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
