import React from "react";
import Styles from "./style.module.scss";
import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";
export default function ListSkeletonInfoLocation() {
  return (
    <div className={Styles.wrapDetailSkeletonInfoLocation}>
      <Skeleton w="300px" h="40px" />
      <div className={Styles.contain}>
        {Array.from({ length: 8 }).map((_, idx) => (
          <BoxSkeleton w="100%" h="300px" key={idx} />
        ))}
      </div>
    </div>
  );
}
