import { API } from "configs";

export function getAllDataActivePersonil(page = 1, limit = 10, search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
      const { data } = await API.get(
        `/useraccess?clientCode=${CLIENT_KEY?.code}&page=${page}&limit=${limit}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
