import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useNavigate, useParams } from "react-router-dom";
import ExcelIcon from "@Assets/Images/Excel-Icon.png";
import SearchBar from "@Atom/SearchBar";
import useOutsideClick from "@Hooks/useOutsideClick";
import ModalUploadEmployee from "@Atom/ModalUploadEmployee";
import Checkbox from "@Atom/Checkbox";
export default function SettingWorkSchedule() {
  const navigate = useNavigate();
  const { id } = useParams();
  const listDataPersonal = useMemo(() => {
    return [
      {
        id: 1,
        profilePicture: "https://placehold.co/24x24/lightblue/black",
        name: "Bambang",
        position: "Staff",
        isNonShift: true,
      },
      {
        id: 2,
        profilePicture: "https://placehold.co/24x24/black/white",
        name: "Bambang",
        position: "Staff",
      },
      {
        id: 3,
        profilePicture: "https://placehold.co/24x24/lightblue/black",
        name: "Bambang",
        position: "Staff",
        isNonShift: true,
      },
      {
        id: 4,
        profilePicture: "https://placehold.co/24x24/black/white",
        name: "Bambang",
        position: "Staff",
      },
      {
        id: 5,
        profilePicture: "https://placehold.co/24x24/lightblue/black",
        name: "Bambang",
        position: "Staff",
      },
    ];
  }, []);

  const listShift = ["Pagi", "Siang", "Malam", "Off"];

  const [openShift, setOpenShift] = useState(false);
  const refChooseShift = useRef();
  const outsideClick = useOutsideClick(refChooseShift);

  useEffect(() => {
    if (openShift && outsideClick) {
      setOpenShift(false);
    }
  }, [openShift, outsideClick]);

  const [selectedShift, setSelectedShift] = useState({});
  const handleSetShift = (date, id, shift) => {
    setSelectedShift((prev) => ({
      ...prev,
      [date + id]: shift,
    }));
    setOpenShift(null);
  };

  const [file, setFile] = useState();
  const [isOpenModalEmployee, setIsModalEmployee] = useState(false);
  const handleOpenModalUpload = () => {
    setIsModalEmployee(!isOpenModalEmployee);
  };

  const [isOpenDropdownFilter, setIsOpenDropdownFilter] = useState(false);

  const listFilterTeam = ["Semua", "Dandru", "Staff", "Non Shift"];

  const listDays = ["MIN", "SEN", "SEL", "RAB", "KAM", "JUM", "SAB"];

  // Get the first date of the current month
  const firstDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth()
  ).getDate();

  // Get the last date of the current month
  const lastDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate();

  let datesLength = [];
  for (let index = firstDate; index <= lastDate; index++) {
    datesLength.push(index);
  }

  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const listDateDays = datesLength.map((date) => {
    const dayOfWeek = new Date(year, month, date).getDay();
    return {
      date: date.toString(), // Date as string
      day: listDays[dayOfWeek], // Day name from listDays array
    };
  });

  const blocks = [];
  let currentBlock = [];

  listDateDays.forEach((dateObj) => {
    currentBlock.push(dateObj);
    if (dateObj.day === "MIN") {
      blocks.push(currentBlock);
      currentBlock = []; // Reset blok
    }
  });
  if (currentBlock.length > 0) {
    blocks.push(currentBlock); // Menambahkan blok terakhir jika ada sisa hari
  }

  const filterRef = useRef(null);

  const outsideClickFilter = useOutsideClick(filterRef);

  useEffect(() => {
    if (isOpenDropdownFilter && outsideClickFilter) {
      setIsOpenDropdownFilter(false);
    }
  }, [isOpenDropdownFilter, outsideClickFilter]);

  return (
    <div className={Styles.maxCenterContain}>
      <div className={Styles.wrapperDetailWorkSchedule}>
        <header className={`${Styles.header}`}>
          <div>
            <div className={Styles.back}>
              <Icon
                icon={"arrow-chevron-left"}
                onClick={() => navigate("/work-schedule/detail/" + id)}
                className={Styles.back}
                size={"20"}
              />
              <h3>Modena Gancy</h3>
            </div>
            <div className={Styles.wrapDateTime}>
              <div>-</div>
              <div className={Styles.date}>
                <h3>April 2024</h3>
                <Icon
                  icon={"calendar"}
                  className={Styles.back}
                  size={"20"}
                  color={"#919EB0"}
                />
              </div>
            </div>
            <div className={Styles.status}>
              <span>Belum Lengkap</span>
            </div>
          </div>
          <div
            className={Styles.uploadSchedule}
            onClick={() => setIsModalEmployee(true)}
          >
            <img src={ExcelIcon} alt="excel-icon" />
            <span>Upload Jadwal</span>
          </div>
          <ModalUploadEmployee
            isOpen={isOpenModalEmployee}
            onClose={handleOpenModalUpload}
            setFile={setFile}
            file={file}
            month={new Date().getMonth() + 1}
            year={new Date().getFullYear()}
          />
        </header>
        <div className={Styles.mainContainDetailWorkSchedule}>
          <div className={Styles.listPersonil}>
            <div className={Styles.textListPersonil}>
              <div>
                <h3>Daftar Anggota</h3>
                <Icon
                  icon={"setting-3"}
                  size={"24"}
                  onClick={() => setIsOpenDropdownFilter(!isOpenDropdownFilter)}
                  className={Styles.icon}
                />
                {isOpenDropdownFilter && (
                  <div className={Styles.viewDropdownFiltered} ref={filterRef}>
                    {listFilterTeam.map((l, idx) => (
                      <div key={idx}>
                        <Checkbox />
                        <span>{l}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <SearchBar size="s" />
            </div>
            <div className={Styles.personil}>
              {listDataPersonal.map((el, idx) => (
                <div className={Styles.between} key={idx}>
                  <div>
                    <img src={el.profilePicture} alt="profile-picture" />
                    <div className={Styles.names}>
                      <span>{el.name}</span>
                      <p>{el.position}</p>
                    </div>
                  </div>
                  {el.isNonShift && (
                    <div className={Styles.nonShift}>
                      <span>Non shift</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={Styles.schedules}>
            <div className={Styles.month}>
              <span>April 2024</span>
            </div>
            <div className={Styles.wrapperDateTwoWeeks}>
              {blocks.map((datas, idx) => (
                <div
                  className={Styles.week}
                  key={idx}
                  style={{
                    borderRight:
                      idx < blocks.length ? "1px solid #dee2e7" : "unset",
                  }}
                >
                  <div
                  // style={
                  //   datas.length < 6
                  //     ? {
                  //         justifyContent: "center",
                  //         gap: "2rem",
                  //       }
                  //     : {}
                  // }
                  >
                    {datas.map((el, idx) => (
                      <div className={Styles.days} key={idx}>
                        <span>{el.day}</span>
                        <span>{el.date}</span>
                      </div>
                    ))}
                  </div>
                  <div className={Styles.selectedDay}>
                    {listDataPersonal.map((lp, idxLp) => (
                      <div className={Styles.bullets} key={idxLp}>
                        {datas.map((dateAndDay, idx) => (
                          <div key={idx}>
                            <span
                              onClick={
                                lp.isNonShift
                                  ? () => {}
                                  : () => setOpenShift(dateAndDay.date + lp.id)
                              }
                              style={{
                                background: lp.isNonShift ? "#DEE2E7" : "",
                                cursor: lp.isNonShift ? "not-allowed" : "",
                              }}
                              is_open={(
                                openShift === datas[idx].date
                              )?.toString()}
                              is_already_shift={
                                selectedShift[dateAndDay.date + lp.id]
                                  ? "true"
                                  : "false"
                              }
                            >
                              {selectedShift[dateAndDay.date + lp.id] ===
                              "Pagi" ? (
                                <span>P</span>
                              ) : selectedShift[dateAndDay.date + lp.id] ===
                                "Siang" ? (
                                <span>S</span>
                              ) : selectedShift[dateAndDay.date + lp.id] ===
                                "Malam" ? (
                                <span>M</span>
                              ) : selectedShift[dateAndDay.date + lp.id] ===
                                "Off" ? (
                                <span style={{ color: "#D42701" }}>Off</span>
                              ) : (
                                ""
                              )}
                            </span>
                            {openShift === dateAndDay.date + lp.id && (
                              <div
                                className={Styles.chooseShift}
                                ref={refChooseShift}
                              >
                                {listShift.map((shift, idx) => (
                                  <ul key={idx}>
                                    <li
                                      style={{
                                        color: shift === "Off" ? "#D42701" : "",
                                      }}
                                      onClick={() =>
                                        handleSetShift(
                                          dateAndDay.date,
                                          lp.id,
                                          shift
                                        )
                                      }
                                    >
                                      {shift}
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
