import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Tabs from "@Molecule/Tabs";
import SearchBar from "@Atom/SearchBar";
import Table from "@Molecule/Table";
import useWindowSize from "@Hooks/useWindowSize";
import TabHeader from "@Atom/TabHeader";
import Icon from "@Atom/Icon";
import DropdownMoreCircle from "@Atom/DropdownMoreCircle";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileEmployee from "@Atom/ProfileEmployee";
import moment from "moment";
import Modal from "@Atom/Modal";
import Button from "@Atom/Button";
import FaqImage from "@Assets/Icon/qustionLogo.svg";
import { deleteEmployee } from "@Services/employee/deleteEmployee";
import { changeStatusEmployee } from "@Services/employee/changeStatusEmployee";
import Toast from "@Atom/Toast";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import ModalAddMembers from "@Molecule/_modal/AddMembers";
import AvatarImage from "@Assets/Images/ava-default.png";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import Spinner from "@Atom/Spinner";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
export default function ListMembersPage({ data, dataSquad, isEmpty = false }) {
  moment.locale("id");
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const query = useQuery();
  const nameLocation = sessionStorage.getItem("nameDetailInfoWorkLocation");
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const [tabsActive, setTabsActive] = useState(
    +sessionStorage.getItem("tabsActive") || 0
  );
  useEffect(() => {
    sessionStorage.setItem("tabsActive", tabsActive);
  }, [tabsActive]);

  const listTabs = ["Daftar Personil", "Daftar Regu"];

  const limitQuery = query.get("limit") || 10;
  const pageQuery = query.get("page") || 1;
  // const searchQuery = query.get("search") || "";

  const [limitPersonil, setLimitPersonil] = useState(+limitQuery);
  const [pagePersonil, setPagePersonil] = useState(+pageQuery);

  const [limitSquad, setLimitSquad] = useState(+limitQuery);
  const [pageSquad, setPageSquad] = useState(+pageQuery);

  const [searchQueryPersonil, setSearchQueryPersonil] = useState("");
  const [searchQuerySquad, setSearchQuerySquad] = useState("");
  const debouncedPersonil = useDebounce(searchQueryPersonil, 500);
  const debouncedSquad = useDebounce(searchQuerySquad, 500);

  useEffect(() => {
    setLimitPersonil(+limitQuery);
    setLimitSquad(+limitQuery);
    setPagePersonil(+pageQuery);
    setPageSquad(+pageQuery);
    // setSearchQueryPersonil(searchQuery);
    // setSearchQuerySquad(searchQuery);
  }, [limitQuery, pageQuery]);

  useEffect(() => {
    const prevPagePersonil = Math.min(
      data?.totalPage ? data.totalPage : 1,
      pagePersonil
    );
    const prevPageSquad = Math.min(
      data?.totalPage ? data.totalPage : 1,
      pageSquad
    );
    if (tabsActive === 0) {
      navigate(
        `${pathname}?q=q&page=${prevPagePersonil}&limit=${limitPersonil}&search=${debouncedPersonil}`
      );
    } else {
      navigate(
        `${pathname}?q=q&page=${prevPageSquad}&limit=${limitSquad}&search=${debouncedSquad}`
      );
    }
  }, [
    data?.totalPage,
    debouncedPersonil,
    debouncedSquad,
    limitPersonil,
    limitSquad,
    navigate,
    pagePersonil,
    pageSquad,
    pathname,
    tabsActive,
  ]);

  useEffect(() => {
    if (+pageQuery !== pagePersonil) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [debouncedPersonil, pagePersonil, pageQuery]);

  // eslint-disable-next-line no-unused-vars
  const [openChoose, setOopenChoose] = useState();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState();

  const [sort, setSort] = useState({
    column: "name",
    type: "ASC" || "DESC",
  });

  // const sortedData = useMemo(() => {
  //   const sorted = [...dataToShow?.data].sort((a, b) => {
  //     if (sort.column) {
  //       if (sort.type === "ASC") {
  //         return a.name?.localeCompare(b.name);
  //       } else {
  //         return b.name?.localeCompare(a.name);
  //       }
  //     }
  //     return 0;
  //   });

  //   return sorted;
  // }, [dataToShow?.data, sort]);
  // console.log("first", data);

  const templateDataPersonil = useMemo(() => {
    return {
      data:
        data?.data?.map((m) => ({
          id: m?._id || "-",
          employeeID: m?.employeeID || "-",
          name: capitalizeEachWord(m?.name) || "-",
          profilePicture: m?.profilePicture || AvatarImage,
          level: capitalizeEachWord(m?.role) || "-",
          assignment: capitalizeEachWord(m?.assignAs) || "-",
          squad: capitalizeEachWord(m?.group?.name) || "-" || "-",
        })) || [],
      totalData: data?.totalData || "-",
      columns: [
        {
          name: "name",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Nama"
              column={"name"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <ProfileEmployee
              name={row?.name}
              idEmployee={row.employeeID}
              image={row?.profilePicture}
            />
          ),
        },

        {
          name: "level",
          rowExpanded: false,
          width: width > 851 ? "max-content" : "200px",
          clickExpand: false,
          title: (
            <TabHeader
              text="Jabatan"
              column={"level"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => <span>{row?.level}</span>,
          hideOnMobile: true,
        },

        {
          name: "assignment",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Penugasan" />,
          hideOnMobile: true,
          renderData: (row) => <span>{row?.assignment}</span>,
        },

        {
          name: "squad",
          width: width > 851 ? "max-content" : "200px",
          textAlign: "end",
          title: (
            <TabHeader
              text="Regu"
              column={"contractType"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          hideOnMobile: true,
          renderData: (row) => <span>{row?.squad}</span>,
        },
        {
          name: "",
          renderData: (row) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <DropdownMoreCircle
                id={row?.id}
                // setIsOpenModalArchive={() => setIsOpenModalArchive(true)}
                // setIsModalDelete={() => setIsOpenModalDelete(true)}
                openChoose={openChoose}
                // setOopenChoose={setOopenChoose}
                tabs={tabsActive}
              ></DropdownMoreCircle>
            </div>
          ),
        },
      ],
    };
  }, [data?.data, data?.totalData, width, sort, openChoose, tabsActive]);

  const templateListSquad = useMemo(() => {
    return {
      data:
        dataSquad?.data?.map((ds) => ({
          id: ds?._id || "-",
          squadName: capitalizeEachWord(ds?.groupName) || "-",
          totalPersonils: ds?.memberCount || "-",
          idEmployee: ds.latestDanru?.employeeId || "-",
          name: capitalizeEachWord(ds.latestDanru?.name) || "-",
          profilePicture: ds.latestDanru?.profilePicture || AvatarImage,
        })) || [],
      totalData: dataSquad.totalData || 0,
      columns: [
        {
          name: "squadName",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Nama Regu"
              column={"squadName"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => <span>{row?.squadName}</span>,
        },

        {
          name: "totalPersonils",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Jumlah Anggota" />,
          hideOnMobile: true,
          renderData: (row) => <span>{row?.totalPersonils}</span>,
        },

        {
          name: "",
          width: width > 851 ? "max-content" : "200px",
          textAlign: "end",
          title: <TabHeader text="Danru" />,
          renderData: (row) => (
            <ProfileEmployee
              idEmployee={row.idEmployee}
              image={row.profilePicture}
              name={row.name}
            />
          ),
          hideOnMobile: true,
        },

        {
          name: "",
          renderData: (row) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "16px",
                cursor: "not-allowed",
              }}
            >
              <DropdownMoreCircle
                id={row?.id}
                // setIsOpenModalArchive={() => setIsOpenModalArchive(true)}
                // setIsModalDelete={() => setIsOpenModalDelete(true)}
                openChoose={openChoose}
                // setOopenChoose={setOopenChoose}
                tabs={tabsActive}
              ></DropdownMoreCircle>
            </div>
          ),
        },
      ],
    };
  }, [
    dataSquad?.data,
    dataSquad.totalData,
    width,
    sort,
    openChoose,
    tabsActive,
  ]);

  const [selectedName, setSelectedName] = useState({});

  useEffect(() => {
    if (openChoose) {
      const selectedNames = templateDataPersonil.data.find(
        (el) => el?.id === openChoose
      );
      setSelectedName(selectedNames);
    }
  }, [templateDataPersonil.data, openChoose]);

  const [isOpenModalPersonil, setIsOpenModalPersonil] = useState(false);
  const [isTransition, setIsTransition] = useState(false);
  const handleOpenModalUpload = () => {
    setIsOpenModalPersonil(!isOpenModalPersonil);
  };

  useEffect(() => {
    if (isOpenModalPersonil) {
      setTimeout(() => {
        setIsTransition(true);
      }, 100);
    }
  }, [isOpenModalPersonil]);

  const [isOpenModalArchive, setIsOpenModalArchive] = useState();

  const handleCloseModal = () => {
    setIsOpenModalArchive(false);
    setIsOpenModalDelete(false);
  };

  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [isChangeStatusFail, setIsChangeStatusFail] = useState(false);

  const [isDeleteEmployee, setIsDeleteEmployee] = useState(false);
  const [isDeleteEmployeeFail, setIsDeleteEmployeeFail] = useState(false);

  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const handleDeleteEmployee = async () => {
    handleCloseModal();
    try {
      setIsLoadingHit(true);
      const response = await deleteEmployee(selectedName?.id);
      if (response.status === 200) {
        setIsDeleteEmployee(true);
        setIsLoadingHit(false);
        console.log(response?.data.message);
        navigate(pathname, { replace: true });
      }
    } catch (error) {
      console.log(error);
      setIsLoadingHit(false);
      setIsDeleteEmployeeFail(true);
    }
  };
  const handleChangeStatusEmployee = async () => {
    handleCloseModal();
    try {
      setIsLoadingHit(true);
      const response = await changeStatusEmployee(selectedName?.id);
      if (response.status === 200) {
        setIsChangeStatus(true);
        setIsLoadingHit(false);
        console.log(response?.data.message);
        navigate(pathname, { replace: true });
      }
    } catch (error) {
      setIsChangeStatusFail(true);
      console.log(error);
      setIsLoadingHit(false);
    }
  };
  // const [file, setFile] = useState();
  // const [isOpenAddMobile, setIsOpeAddnMobile] = useState(false);
  useEffect(() => {
    setIsChangeStatus(false);
    setIsChangeStatusFail(false);
  }, [tabsActive]);
  setTimeout(() => {
    setIsChangeStatus(false);
    setIsChangeStatusFail(false);
    setIsDeleteEmployee(false);
    setIsDeleteEmployee(false);
  }, 5000);

  return (
    <>
      <Toast
        isopen={isDeleteEmployeeFail}
        color="red"
        text={`${selectedName?.name} Gagal Di Hapus`}
        onClose={() => setIsDeleteEmployeeFail(false)}
      />

      <Toast
        isopen={isDeleteEmployee}
        color="green"
        text={`${selectedName?.name} Berhasil Di Hapus`}
        onClose={() => setIsDeleteEmployee(false)}
      />

      <Toast
        isopen={isChangeStatusFail}
        color="red"
        text={
          tabsActive === 0
            ? `${selectedName?.name} Gagal Di non aktifkan`
            : `${selectedName?.name} Gagal Di Aktifkan`
        }
        onClose={() => setIsChangeStatusFail(false)}
      />

      <Toast
        isopen={isChangeStatus}
        color="green"
        text={
          tabsActive === 0
            ? `${selectedName?.name} Berhasil Di non aktifkan`
            : `${selectedName?.name} Berhasil Di Aktifkan`
        }
        onClose={() => setIsChangeStatus(false)}
      />

      {isLoadingHit && <LoadingSpinner />}

      {isOpenModalPersonil && ( // & REGU
        <ModalAddMembers
          isOpen={isOpenModalPersonil}
          onClose={() => {
            setIsOpenModalPersonil(false);
            setIsTransition(false);
          }}
          isTransition={isTransition}
          tabsActive={tabsActive}
          isLoading={isLoadingHit}
        />
      )}

      {isEmpty ? (
        <CustomEmptyHandlerPage
          title="Belum Ada Daftar Personil & Regu Ditambahkan"
          description="Silahkan lakukan penambahan personil untuk ditugaskan di lokasi ini"
          btnText="Tambah Personil"
          onClick={handleOpenModalUpload}
          images={Images.EMPTY_PERSONAL_GUARD}
        />
      ) : (
        <>
          <Modal isOpen={!!isOpenModalDelete || isOpenModalArchive}>
            <div className={Styles.containerModal}>
              <div className={Styles.wrapperModalDropdown}>
                <div className={Styles.headerModal}>
                  <img src={FaqImage} alt="faq" className={Styles.icon} />
                  <h2>
                    {isOpenModalDelete
                      ? "Hapus"
                      : tabsActive === 1
                      ? "Aktifkan"
                      : "Non Aktifkan"}{" "}
                    Karyawan {selectedName?.name}
                  </h2>
                  <div className={Styles.closeModal} onClick={handleCloseModal}>
                    <Icon icon={"close-circle"} color={"#000"} size={12} />
                  </div>
                </div>
                <div className={Styles.desc}>
                  {tabsActive === 0 ? (
                    <p className={Styles.paragraph}>
                      Apakah anda yakin ingin menghapus data karyawan ini?
                      Setelah anda menghapus data ini anda tidak bisa
                      mengembalikan data yang ada.
                    </p>
                  ) : (
                    <p className={Styles.paragraph}>
                      Apakah anda yakin ingin mengaktifkan kembali karyawan ini?
                    </p>
                  )}
                </div>
                <div className={Styles.wrapperButton}>
                  <Button
                    bgColor={"white"}
                    color={"#2C5364"}
                    text="Kembali"
                    onClick={handleCloseModal}
                  />
                  <Button
                    text={
                      isOpenModalDelete
                        ? "Ya, Hapus"
                        : tabsActive === 0
                        ? "Ya, Arsipkan"
                        : "Ya, Aktifkan"
                    }
                    onClick={
                      isOpenModalDelete
                        ? () => handleDeleteEmployee()
                        : () => handleChangeStatusEmployee()
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>

          <section className={Styles.containerListEmployee}>
            {/* <div
              style={{
                background: "salmon",
                position: "fixed",
                top: "50%",
                zIndex: "9999",
              }}
            >
              <Spinner width="50px" />
            </div> */}
            <div className={Styles.wrapperListEmployee}>
              <div className={Styles.headerList}>
                <div className={Styles.header}>
                  <Icon
                    icon={"arrow-chevron-left"}
                    color={"#3E4856"}
                    size={"20"}
                    className={Styles.backIcon}
                    onClick={() => navigate("/work-location")}
                  />
                  <h2 className={Styles.headerText}>{nameLocation}</h2>
                </div>
                <Tabs
                  setIsActive={setTabsActive}
                  isActive={tabsActive}
                  listTabs={listTabs}
                  style={{ marginBottom: "12px" }}
                />
                <div className={Styles.fieldTable}>
                  <div className={Styles.filtering}>
                    <div style={{ width: "295px" }}>
                      <SearchBar
                        setValue={
                          tabsActive === 0
                            ? setSearchQueryPersonil
                            : setSearchQuerySquad
                        }
                        value={
                          tabsActive === 0
                            ? searchQueryPersonil
                            : searchQuerySquad
                        }
                        size="m"
                      />
                    </div>
                    {/* {width >= 851 && (
                      <div className={Styles.filter}>
                        <span>Filter</span>
                        <Icon
                          icon={"setting-4"}
                          color={"#2C5364"}
                          size={"16"}
                          className={Styles.iconFilter}
                        />
                      </div>
                    )}
                    <div className={Styles.wrapFilterMobile}>
                      {width <= 851 && (
                        <div className={Styles.filter}>
                          <span>Filter</span>
                          <Icon
                            icon={"setting-4"}
                            color={"#2C5364"}
                            size={"16"}
                            className={Styles.iconFilter}
                          />
                        </div>
                      )}
                    </div> */}
                  </div>
                  {width > 851 && (
                    <div
                      className={Styles.btnAdd}
                      onClick={() => setIsOpenModalPersonil(true)}
                    >
                      <Icon
                        icon={"add-circle"}
                        color={"#2C5364"}
                        size={"16"}
                        className={Styles.iconAdd}
                      />
                      <span>
                        Tambah {tabsActive === 0 ? "Personil" : "Regu"}
                      </span>
                    </div>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  {(loading ||
                    ((tabsActive === 0
                      ? searchQueryPersonil.length > 1
                      : searchQuerySquad.length > 1) &&
                      (debouncedPersonil || debouncedSquad) !==
                        query.get("search"))) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        zIndex: "9999",
                      }}
                    >
                      <Spinner width="50px" />
                    </div>
                  )}
                  <Table
                    className={Styles.table}
                    data={
                      tabsActive === 0
                        ? templateDataPersonil.data
                        : templateListSquad.data
                    }
                    columns={
                      tabsActive === 0
                        ? templateDataPersonil.columns
                        : templateListSquad.columns
                    }
                    page={tabsActive === 0 ? pagePersonil : pageSquad}
                    limit={tabsActive === 0 ? limitPersonil : limitSquad}
                    totalData={
                      tabsActive === 0
                        ? templateDataPersonil.totalData
                        : templateListSquad.totalData
                    }
                    setLimit={
                      tabsActive === 0 ? setLimitPersonil : setLimitSquad
                    }
                    setPage={tabsActive === 0 ? setPagePersonil : setPageSquad}
                    totalPage={
                      tabsActive === 0
                        ? Math.ceil(
                            templateDataPersonil.totalData / limitPersonil
                          )
                        : Math.ceil(templateListSquad.totalData / limitSquad)
                    }
                    withPagination
                    withNumbering
                    widthNumber="20px"
                    heightEmpty="47px"
                    isCustomEmptyHandler
                    customEmptyHandler={() =>
                      tabsActive === 0 ? (
                        <div
                          style={{
                            height: "100%",
                            padding: "40px",
                          }}
                        >
                          <CustomEmptyHandlerPage
                            images={Images.EMPTY_PERSONAL_GUARD}
                            title="Belum Ada Daftar Personil"
                            description="Silahkan lakukan penambahan personil untuk ditugaskan di lokasi ini"
                            btnText="Tambah Personil"
                            onClick={() => setIsOpenModalPersonil(true)}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            padding: "40px",
                          }}
                        >
                          <CustomEmptyHandlerPage
                            images={Images.EMPTY_PERSONAL_GUARD}
                            title="Tidak Ada Data Regu"
                            description="Silahkan lakukan penambahan data regu di lokasi ini"
                            btnText="Tambah Regu"
                            onClick={() => setIsOpenModalPersonil(true)}
                          />
                        </div>
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
