import { API } from "configs";

export function getAllSchemaByClientCode(page = 1, limit = 10) {
  return new Promise(async (resolve, reject) => {
    try {
      const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));

      const { data } = await API.get(
        `/attendanceschema?page=${page}&limit=${limit}&clientCode=${CLIENT_KEY?.code}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
