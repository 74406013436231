import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import Button from "@Atom/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "@Atom/Modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { fileBaseUrl } from "configs";
import { useDebounce } from "@Hooks/useDebounce";
import { updateSequencePatrolPoint } from "@Services/PatrolPoint/updateSequence";
import Toast from "@Atom/Toast";
import { addPatrolPoint } from "@Services/PatrolPoint/AddPatrollPoint";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import LoadingSpinner from "@Molecule/LoadingSpinner";

import ModalAddPatrolPoint from "@Molecule/_modal/AddPatrolPoint";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import useQuery from "@Hooks/useQuery";
import Spinner from "@Atom/Spinner";

const ItemType = {
  CARD: "card",
};

// Komponen untuk setiap Card di dalam modal
const SortableCard = ({ card, index, moveCard, isUnsetBorder = false }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType.CARD,
    hover(item) {
      if (item.index !== index) {
        moveCard(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CARD,
    item: { id: card._id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      className={Styles.listCard}
      ref={ref}
      style={{
        backgroundColor: isDragging ? "#f0f0f0" : "#ffffff",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Icon
        icon={"burger-menu"}
        className={Styles.burgerMenuIcon}
        size={"24"}
      />
      <div
        className={`${Styles.wrapDivider} ${
          isUnsetBorder ? Styles.unsetDivider : ""
        } `}
      >
        <div className={Styles.wrapImage}>
          <img src={fileBaseUrl + card.imageURI} alt="image-location-patrol" />
        </div>
        <div className={Styles.nameLocation}>
          <strong>{capitalizeEachWord(card.name)} </strong>
          <span>{capitalizeEachWord(card.description)}</span>
        </div>
      </div>
    </div>
  );
};

export default function ListPatrolPoint({ data }) {
  const query = useQuery();
  const limitQuery = query.get("limit");
  const { height } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { width } = useWindowSize();

  const [isOpenAddPoint, setIsOpenAddPoint] = useState(false);
  const [isOpenSortPatrol, setIsOpenSortPatrol] = useState(false);
  const [cardOrder, setCardOrder] = useState([]);

  const [isTransition, setTransition] = useState(false);
  const [isLoadingHit, setIsLoadingHit] = useState(false);
  useEffect(() => {
    if (isOpenSortPatrol && !isTransition) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    }
  }, [isOpenSortPatrol, isTransition]);

  const moveCard = (fromIndex, toIndex) => {
    if (fromIndex === toIndex) return;

    setCardOrder((prevCardOrder) => {
      const updatedCardOrder = [...prevCardOrder];
      const [movedCard] = updatedCardOrder.splice(fromIndex, 1);
      updatedCardOrder.splice(toIndex, 0, movedCard);
      return updatedCardOrder;
    });
  };

  useEffect(() => {
    if (data?.data) setCardOrder(data.data);
  }, [data?.data]);

  const handleBack = () => {
    navigate("/work-location");
    sessionStorage.setItem("activeSubMenu", 0);
  };

  const page = 1;
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const debounceQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    navigate(`${pathname}?page=${page}&limit=${limit}&search=${debounceQuery}`);
  }, [limit, navigate, page, pathname, debounceQuery, id]);

  const [isSuccessChangeSequence, setIsSuccessChangeSequence] = useState(false);
  const [isErrorChangeSequence, setIsErrorChangeSequence] = useState(false);

  // update sequence
  const handleSortPatrolPoint = async () => {
    const dataToSend = new URLSearchParams();
    const dataCurrent = data?.data;
    cardOrder.forEach((card, idx) => {
      if (idx === 0) {
        dataToSend.append(`locationID`, id);
      }
      dataToSend.append(`checkpoints[${idx}][checkpointID]`, card?._id);
      dataToSend.append(`checkpoints[${idx}][currentNum]`, card?.num);
      dataToSend.append(`checkpoints[${idx}][newNum]`, dataCurrent[idx]?.num);
    });

    try {
      const res = await updateSequencePatrolPoint(dataToSend);
      if (res.status === 200) {
        setIsSuccessChangeSequence(true);
        // navigate(pathname, { replace: true });
        navigate(`${pathname}?page=1&limit=10&search=`, { replace: true });
        setIsOpenSortPatrol(false);
      }
    } catch (error) {
      console.log("error sort change patrol point: ", error);
      setIsErrorChangeSequence(true);
    }
  };

  // create
  const [isSuccessCreatePatrolPoint, setIsSuccessCreatePatrolPoint] =
    useState(false);
  const [isErrorCreatePatrolPoint, setIsErrorCreatePatrolPoint] =
    useState(false);

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [inputPatrolPoint, setInputPatrolPoint] = useState({
    name: "",
    image: "",
  });

  const fileRef = useRef(null);

  const handleAddPatrolPoint = async () => {
    setIsLoadingHit(true);
    setIsOpenAddPoint(false);
    const formData = new FormData();
    formData.append("locationID", id);
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("description", inputPatrolPoint.name);
    formData.append("file", inputPatrolPoint.image);

    try {
      const res = await addPatrolPoint(formData);
      if (res.status === 201) {
        navigate(pathname, { replace: true });
        setSelectedLocation({
          latitude: 0,
          longitude: 0,
        });
        setInputPatrolPoint({
          name: "",
          image: "",
        });
        setIsSuccessCreatePatrolPoint(true);
        setIsLoadingHit(false);
      }
    } catch (error) {
      setIsLoadingHit(false);
      console.log("error add patrol point", error);
      setIsErrorCreatePatrolPoint(true);
    }
  };

  const locationName = sessionStorage.getItem("nameDetailInfoWorkLocation");

  const listInnerRefMainContain = useRef();
  const listInnerRefSortContain = useRef();

  const onScroll = useCallback(() => {
    if (listInnerRefMainContain.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefMainContain.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < data.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [data.totalData, limit]);

  const onScrollSort = useCallback(() => {
    if (listInnerRefSortContain.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefSortContain.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < data.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [data.totalData, limit]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
      onScrollSort();
    }
  }, [height, onScroll, onScrollSort]);

  return (
    <>
      <Toast
        isopen={isSuccessChangeSequence || isSuccessCreatePatrolPoint}
        color="green"
        onClose={() => {
          setIsSuccessChangeSequence(false);
          setIsSuccessCreatePatrolPoint(false);
        }}
        text={
          isSuccessChangeSequence
            ? "Berhasil memperbarui urutan"
            : "Berhasil menambahkan lokasi "
        }
      />

      <Toast
        isopen={isErrorChangeSequence || isErrorCreatePatrolPoint}
        color="red"
        onClose={() => {
          setIsErrorChangeSequence(false);
          setIsErrorCreatePatrolPoint(false);
        }}
        text={
          isErrorChangeSequence
            ? "Gagal memperbarui urutan"
            : "Gagal menambahkan lokasi"
        }
      />

      {isLoadingHit && <LoadingSpinner text="Sedang menyimpan..." />}

      {isOpenAddPoint && (
        <ModalAddPatrolPoint
          isOpen={isOpenAddPoint}
          onClose={() => setIsOpenAddPoint(false)}
          fileRef={fileRef}
          onSubmit={() => handleAddPatrolPoint()}
          inputLocation={inputPatrolPoint}
          setInputLocation={setInputPatrolPoint}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      )}

      <div className={Styles.wrapperListPatrolPoint}>
        <div
          className={`${Styles.mainContainListPatrolPoint} ${
            cardOrder.length === 0 && Styles.isEmpty
          } `}
        >
          <div className={Styles.between}>
            <div className={Styles.back}>
              <Icon
                icon={"arrow-chevron-left"}
                size={"20"}
                className={Styles.backIcon}
                onClick={() => handleBack()}
              />
              <span>{capitalizeEachWord(locationName)}</span>
            </div>
            <Button
              text="Tambah Lokasi"
              isAddButton
              isLeftIcon
              onClick={() => setIsOpenAddPoint(true)}
            />
          </div>

          {cardOrder.length === 0 && !debounceQuery ? (
            <CustomEmptyHandlerPage
              title="Lokasi Titik Patroli Belum Ditambahkan"
              description="Silahkan lakukan penambahan lokasi titik patroli"
              onClick={() => setIsOpenAddPoint(true)}
              images={Images.POINT_NAVIGATION}
              btnText="Tambah Titik Lokasi Patroli"
            />
          ) : (
            <div className={Styles.viewFilter}>
              <SearchBar setValue={setSearchQuery} value={searchQuery} />
              <Button
                text="Atur Urutan Patroli"
                icon={"arrow-swap"}
                colorIcon={"#3E4856"}
                style={{ background: "transparent", color: "#3E4856" }}
                onClick={() => setIsOpenSortPatrol(!isOpenSortPatrol)}
              />

              {isOpenSortPatrol && (
                <DndProvider backend={HTML5Backend}>
                  <Modal isOpen onClose={() => setIsOpenSortPatrol(false)}>
                    <div
                      className={Styles.wrapSortPatrol}
                      is_transition={isTransition.toString()}
                    >
                      <div className={Styles.header}>
                        <span>Atur Titik Patroli</span>
                        <Icon
                          icon={"close-circle"}
                          size={"14"}
                          className={Styles.iconClose}
                          onClick={() => {
                            setIsOpenSortPatrol(false);
                            setTransition(false);
                          }}
                        />
                      </div>

                      <div className={Styles.wrapBetween}>
                        <div
                          className={Styles.mainContain}
                          ref={listInnerRefSortContain}
                          onScroll={onScrollSort}
                        >
                          {cardOrder.map((el, idx) => (
                            <SortableCard
                              key={`sort-${el._id}`}
                              card={el}
                              index={idx}
                              moveCard={moveCard}
                              isUnsetBorder={data?.data?.length - 1 === idx}
                            />
                          ))}
                          {+limitQuery !== limit && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner />
                            </div>
                          )}
                        </div>
                        <div className={Styles.btnSave}>
                          <Button
                            text="Simpan Perubahan"
                            onClick={() => handleSortPatrolPoint()}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </DndProvider>
              )}
            </div>
          )}
          {cardOrder.length === 0 && debounceQuery ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "60vh",
                alignItems: "center",
              }}
            >
              <h1>Data tidak di temukan...</h1>
            </div>
          ) : (
            <div
              className={Styles.cardLocation}
              onScroll={onScroll}
              ref={listInnerRefMainContain}
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {data?.data?.map((el, idx) => (
                <Fragment key={idx}>
                  <div className={Styles.card}>
                    <div className={Styles.img}>
                      <img
                        alt="image-location"
                        src={fileBaseUrl + el?.imageURI}
                      />
                    </div>

                    <div className={Styles.description}>
                      <div className={Styles.personil}>
                        <strong>{capitalizeEachWord(el?.name)}</strong>
                        <div>
                          <span>{capitalizeEachWord(el.description)}</span>
                        </div>
                      </div>
                      <div
                        className={Styles.btnCard}
                        onClick={() =>
                          navigate(
                            `${el?._id}?patrolPointName=${capitalizeEachWord(
                              el?.name
                            )}`
                          )
                        }
                      >
                        <span>Lihat Detail</span>
                        <Icon
                          icon={"arrow-back"}
                          className={Styles.arrowIcon}
                        />
                      </div>
                    </div>

                    {width < 768 ? (
                      <PDFBarcodeDownload
                        checkpoint={el?.name}
                        id={el?._id}
                        description={el?.description}
                        headerSubText="QR Code Titik Patroli"
                      >
                        <div className={Styles.detailQr}>
                          <span>Download QR</span>
                          <Icon
                            icon={"scan-barcode"}
                            color={"#2C5364"}
                            size={"16"}
                          />
                        </div>
                      </PDFBarcodeDownload>
                    ) : (
                      <div
                        className={Styles.detailQr}
                        onClick={() =>
                          window.open(
                            `/pdf-preview?dataQuery=barcode&id=${el?._id}&postName=${el?.name}&headerSubText=QR Code Titik Patroli &description=${el?.description}`,
                            "_blank"
                          )
                        }
                      >
                        <span>Detail QR</span>
                        <Icon
                          icon={"scan-barcode"}
                          color={"#2C5364"}
                          size={"16"}
                        />
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
          {+limitQuery !== limit && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "1rem",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
