import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Map from "@Molecule/Map";
import Geocode from "react-geocode";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { editWorkInfoLocation } from "@Services/WorkLocation/editInfoWorkLocation";
import Toast from "@Atom/Toast";
import { getAllSchemaByClientCode } from "@Services/AttendenceSchema/getAllSchemaByClientCode";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";

export default function DetailLocation({ data }) {
  console.log("DETAIL", data);
  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
  const { pathname } = useLocation();
  useEffect(() => {
    sessionStorage.setItem(
      "nameDetailInfoWorkLocation",
      capitalizeEachWord(data?.name)
    );
  }, [data?.name]);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("id");
  Geocode.setRegion("ID");

  const navigate = useNavigate();
  const [isOpenInfoLocation, setIsOpenInfoLocation] = useState(false);
  const templateProgress = useMemo(() => {
    return [
      {
        id: 1,
        progressPatrol: 70,
      },
      {
        id: 2,
        progressPatrol: 50,
      },
    ];
  }, []);

  const [coordinate, setCoordinateAdd] = useState({
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  useEffect(() => {
    Geocode.fromLatLng(coordinate.latitude, coordinate.longitude).then(
      (respose) => {},
      (error) => {
        console.error("Geocode error", error);
      }
    );
    setCoordinateAdd({
      latitude: parseFloat(coordinate.latitude) || -6.2,
      longitude: parseFloat(coordinate.longitude) || 106.816666,
    });
  }, [coordinate.latitude, coordinate.longitude]);

  const [loadingPost, setLoadingPost] = useState(false);

  const [toastStatus, setToastStatus] = useState({
    success: false,
    error: false,
  });

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  const [inputLocation, setInputLocation] = useState({
    image: data?.imageURI,
    name: data?.name,
    nameLead: data?.pic.name,
    leadPhoneNumber: data?.pic.phoneNumber,
    address: data?.address,
    guardRadius: data?.radius,
    schemaAbsence: { list: data.schema?.name, id: data.schema?._id },
  });

  useEffect(() => {
    setInputLocation({
      image: data?.imageURI,
      name: data?.name,
      nameLead: data?.pic.name,
      leadPhoneNumber: data?.pic.phoneNumber,
      address: data?.address,
      guardRadius: data?.radius,
      schemaAbsence: { list: data.schema?.name, id: data.schema?._id },
    });
    setSelectedLocation({
      latitude: data?.latitude,
      longitude: data?.longitude,
    });
  }, [data]);

  const fileRef = useRef(null);

  const [selectedDropdownID, setSelectedDropdownID] = useState(data.schema?.ID);

  const [dataListDropdown, setDataListDropdown] = useState([
    {
      name: "",
      id: "",
    },
    {
      name: "",
      id: "",
    },
  ]);

  const getDataSchemaByClientCode = async () => {
    try {
      const res = await getAllSchemaByClientCode();
      setDataListDropdown(
        res.data.map((el) => ({
          list: el.name,
          id: el._id,
        }))
      );
    } catch (error) {
      console.log("error get schema", error);
    }
  };

  useEffect(() => {
    getDataSchemaByClientCode();
  }, []);

  const handleSubmit = async () => {
    setLoadingPost(true);
    const formData = new FormData();
    formData.append("name", inputLocation.name?.trim());
    formData.append("address", inputLocation.address?.trim());
    formData.append("pic[name]", inputLocation.nameLead?.trim());
    formData.append("pic[phoneNumber]", inputLocation.leadPhoneNumber?.trim());
    formData.append("radius", inputLocation.guardRadius?.trim());
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("client[ID]", CLIENT_KEY.id);
    formData.append("client[name]", CLIENT_KEY?.name);
    formData.append("client[code]", CLIENT_KEY?.code);
    formData.append("file", inputLocation.image);
    formData.append("schema[ID]", selectedDropdownID);
    formData.append("schema[name]", inputLocation.schemaAbsence.list);
    try {
      const res = await editWorkInfoLocation(data?._id, formData);
      if (res.status === 200) {
        setToastStatus((prev) => ({
          ...prev,
          success: true,
        }));
        setLoadingPost(false);
        setInputLocation({});
        setIsOpenInfoLocation(false);
        navigate(pathname, {
          replace: true,
        });
      }
    } catch (error) {
      console.log(`error edit info location: ${error}`);
      setLoadingPost(false);
      setToastStatus((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  return (
    <div className={Styles.containerDetailLocation}>
      {isOpenInfoLocation && (
        <ModalAddLocation
          isOpen={isOpenInfoLocation}
          isFilled
          onClose={() => setIsOpenInfoLocation(false)}
          inputLocation={inputLocation}
          setInputLocation={setInputLocation}
          fileRef={fileRef}
          onSubmit={() => handleSubmit()}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          latitude={data.latitude}
          longitude={data.longitude}
          dataListDropdown={dataListDropdown}
          setSelectedDropdownID={setSelectedDropdownID}
          isDisabled={loadingPost}
        />
      )}
      <header className={Styles.header}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/work-location")}
            className={Styles.back}
          />
          <h3>{capitalizeEachWord(data?.name)}</h3>
        </div>
        <Icon
          icon={"setting-2"}
          className={Styles.settings}
          onClick={() => setIsOpenInfoLocation(true)}
        />
      </header>
      <section className={Styles.viewerMaps}>
        <Map
          coordinate={{
            latitude: data.latitude,
            longitude: data.longitude,
          }}
          zoom={14}
          options={{
            mapTypeControl: true,
            zoomControl: true,
          }}
          isRadius
          radius={+data?.radius}
          isPreview
        />
      </section>
      <div className={Styles.informationPersonil}>
        <div className={Styles.card}>
          <div>
            <Icon icon={"people"} color={"#2C5364"} size={"32"} />
          </div>
          <div>
            <span>Jumlah Personil</span>
            <h3>{data?.members?.length || 0}</h3>
          </div>
        </div>
        <div
          className={Styles.card}
          style={{ borderBottom: "3px solid #22970F" }}
        >
          <div style={{ background: "#E4F8E0" }}>
            <Icon icon={"people"} color={"#22970F"} size={"32"} />
          </div>
          <div>
            <span>Personil yang Berada Di Lokasi (Sekarang)</span>
            <h3>40</h3>
          </div>
        </div>
        <div
          className={Styles.card}
          style={{ borderBottom: "3px solid #D42701" }}
        >
          <div style={{ background: "#F2E5E2" }}>
            <Icon icon={"people"} color={"#D42701"} size={"32"} />
          </div>
          <div>
            <span>Panggilan Darurat (30 Hari Terakhir)</span>
            <h3>40</h3>
          </div>
        </div>
      </div>

      {(toastStatus.error || toastStatus.success) && (
        <Toast
          isopen
          text={
            toastStatus.error
              ? "Gagal mengubah lokasi"
              : "Berhasil mengubah lokasi"
          }
          color={toastStatus.error ? "red" : "green"}
          onClose={() =>
            setToastStatus((prev) => ({
              ...prev,
              error: false,
              success: false,
            }))
          }
        />
      )}

      {loadingPost && <LoadingSpinner text="Sedang menyimpan..." />}

      <div className={Styles.cardProgress}>
        {templateProgress.map((el, idx) => (
          <div className={Styles.card} key={idx}>
            <strong>Progres Patroli (Hari Ini)</strong>
            <div className={Styles.borderProgress}>
              <svg
                width="313"
                height="313"
                viewBox="0 0 36 36"
                className={Styles.circularChart}
              >
                <path
                  className={Styles.circleBg}
                  strokeDasharray="50"
                  d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  className={Styles.circle}
                  strokeDasharray={`${(el.progressPatrol / 50) * 25}, 100`}
                  d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
              </svg>
              <div className={Styles.descPercentage}>
                <strong>{el.progressPatrol}/100</strong>
                <span>Patroli Diselesaikan</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
