import React, { useEffect, useRef, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Images from "@Theme/Images";

export default function PDFBarcodeDownload({
  id = "12345",
  headerSubText = "Header SubText",
  description = "Deskripsi",
  checkpoint = "Checkpoint",
  children,
}) {
  // eslint-disable-next-line no-unused-vars
  const [windowSize, setWindowSize] = useState([
    window?.innerWidth,
    window?.innerHeight,
  ]);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const canvasRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window?.innerWidth, window?.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const qrUrl = canvasRef.current.toDataURL("image/png");
      setQrCodeUrl(qrUrl);
    }
  }, [id]);

  const MyDocument = (
    <Document title={checkpoint}>
      <Page size="A4" style={Styles.page}>
        <View style={Styles.container}>
          <View style={Styles.dotLeft}>
            <Image src={Images.GROUP_DOT_PDF} style={Styles.dotLeft} />
          </View>

          <View style={Styles.rectangleRight}>
            <Image src={Images.RECTANGLE_PDF_RIGHT} />
          </View>

          <View style={Styles.header}>
            <Image src={Images.SECURITY_LOGO} style={Styles.leftImage} />
            <View style={Styles.wrapTextHeader}>
              <Text style={Styles.headerTitle}>Permata Security</Text>
              <Text style={Styles.textSubHead}>{headerSubText}</Text>
            </View>
            <Image src={Images.MWS_LOGO} style={Styles.rightImage} />
          </View>

          <View style={Styles.body}>
            <Text style={Styles.checkpointTitle}>{checkpoint}</Text>
            <Text style={Styles.locationText}>{description}</Text>
          </View>

          <View style={Styles.qrContainer}>
            {qrCodeUrl && (
              <Image
                src={qrCodeUrl}
                style={{
                  width: "500px",
                  height: "450px",
                }}
              />
            )}
            <View style={Styles.qrCode}>
              <Text style={Styles.qrID}>{`ID:${id}`}</Text>
            </View>
          </View>

          <View style={Styles.dotRightBottom}>
            <Image src={Images.GROUP_DOT_PDF} style={Styles.dotLeft} />
          </View>
          <View style={Styles.rectangleLeft}>
            <Image src={Images.RECTANGLE_PDF_LEFT} />
          </View>
        </View>
        <View style={Styles.footerText}>
          <Text>Dicetak Oleh PT. Permata Indo Sejahtera</Text>
        </View>
      </Page>
    </Document>
  );
  return (
    <>
      <QRCodeCanvas
        value={id}
        marginSize={1}
        renderas="canvas"
        ref={canvasRef}
        style={{ display: "none" }}
      />

      <PDFDownloadLink
        document={MyDocument}
        fileName={`Barcode ${checkpoint}.pdf`}
        style={{ width: "100%" }}
      >
        {children}
      </PDFDownloadLink>
    </>
  );
}

const Styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 20,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  dotLeft: {
    position: "absolute",
    width: 300,
    height: 300,
    left: "-40px",
    top: "50px",
    objectFit: "contain",
  },
  dotRightBottom: {
    position: "absolute",
    width: 300,
    height: 300,
    right: "0",
    bottom: "-740px",
    objectFit: "contain",
    transform: "rotate(240deg)",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    paddingTop: 10,
    paddingRight: 25,
    paddingLeft: 25,
    borderBottom: "1px solid #3E4856",
    width: "100%",
  },
  leftImage: {
    width: 52,
    height: 52,
    objectFit: "contain",
  },
  wrapTextHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  rectangleRight: {
    position: "absolute",
    width: 300,
    height: 300,
    right: "-100px",
    top: "100px",
    objectFit: "contain",
    transform: "rotate(100deg)",
  },
  rectangleLeft: {
    position: "absolute",
    width: 300,
    height: 300,
    bottom: "-608px",
    left: "-130px",
    objectFit: "contain",
    transform: "rotate(25deg)",
    zIndex: 100,
    // backgroundColor: "salmon",
  },

  headerTitle: {
    fontSize: 24,
    fontWeight: "700",
    textAlign: "center",
  },
  textSubHead: {
    fontSize: "14",
    fontWeight: "400",
    color: "#3E4856",
  },
  rightImage: {
    width: 42,
    height: 52,
    objectFit: "contain",
  },
  body: {
    textAlign: "center",
    marginTop: "52px",
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
    alignItems: "center",
  },
  checkpointTitle: {
    fontSize: 32,
    fontWeight: "600",
  },
  locationText: {
    fontSize: 16,
    // marginTop: 5,
    color: "#3E4856",
  },
  qrContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    // padding: "20px",
    top: "230px",
    width: "0",
    height: "465px",
  },
  qrCode: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: "salmon",
    position: "relative",
    right: "-50px",
  },
  qrID: {
    fontSize: 12,
    color: "#3E4856",
  },
  footerText: {
    borderTop: "1px solid #3E4856",
    width: "100%",
    textAlign: "center",
    fontSize: 14,
    color: "#3E4856",
    position: "absolute",
    bottom: "0",
    padding: 10,
    left: "0",
    zIndex: 999,
    backgroundColor: "#fff",
  },
});
