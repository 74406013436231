import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import { makeRandomString } from "helpers/makeRandomString";
import { fileBaseUrl } from "configs";
export default function ModalAddGuardPos({
  isOpen = false,
  fileRef = null,
  onSubmit = () => {},
  onClose = () => {},
  setInputLocation = () => {},
  inputLocation = {
    image: "",
    name: "",
    personil: "",
    fieldPosInventory: [
      {
        id: "123",
        nameInventory: "",
        amount: "",
      },
      {
        id: "234",
        nameInventory: "",
        amount: "",
      },
    ],
    guardRadius: "",
    description: "",
  },
  isFilled = false,
  selectedLocation = {
    latitude: "",
    longitude: "",
  },
  setSelectedLocation = () => [],
  isDisabled = false,
}) {
  const handleChangeInput = (name, value, id) => {
    if (id && name === "amount") {
      const inputNumber = value.replace(/\D/g, "");
      setInputLocation((prev) => ({
        ...prev,
        fieldPosInventory: prev?.fieldPosInventory?.map((el) =>
          id === el.id
            ? {
                ...el,
                [name]: inputNumber,
              }
            : el
        ),
      }));
    } else if (id) {
      setInputLocation((prev) => ({
        ...prev,
        fieldPosInventory: prev?.fieldPosInventory?.map((el) =>
          id === el.id
            ? {
                ...el,
                [name]: value,
              }
            : el
        ),
      }));
    } else if (name === "personil" || name === "guardRadius") {
      const inputNumber = value.replace(/\D/g, "");
      setInputLocation((prev) => ({
        ...prev,
        [name]: inputNumber,
      }));
    } else {
      setInputLocation((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [isTransition, setTransition] = useState(false);
  if (isFilled || isOpen) {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }

  const handleAddFieldInventory = () => {
    setInputLocation((prev) => ({
      ...prev,
      fieldPosInventory: [
        ...prev.fieldPosInventory,
        {
          id: makeRandomString(6),
          field: "",
        },
      ],
    }));
  };

  const handleDeleteFieldInventory = (idDelete) => {
    setInputLocation((prev) => ({
      ...prev,
      fieldPosInventory: prev.fieldPosInventory?.filter(
        (el) => el.id !== idDelete
      ),
    }));
  };

  const [prevImage, setPrevImage] = useState();
  useEffect(() => {
    setPrevImage(inputLocation?.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={Styles.modalAddWorkLocation}
        is_transition={isTransition.toString()}
      >
        <div className={Styles.headerModal}>
          <strong>{isFilled ? "Pengaturan Lokasi" : "Tambah Pos Jaga"}</strong>
          <Icon
            icon={"close-circle"}
            onClick={onClose}
            className={Styles.closeCircle}
          />
        </div>
        <div className={Styles.uploadLocation}>
          <strong>
            Foto Pos Jaga <span>*</span>{" "}
          </strong>
          <div className={Styles.chooseFile}>
            <input
              type="file"
              hidden
              accept=".png, .jpg, .jpeg"
              ref={fileRef}
              onChange={(e) => handleChangeInput("image", e.target.files[0])}
            />

            {inputLocation?.image ? (
              <img
                alt="image-location"
                onClick={() => fileRef.current.click()}
                src={
                  typeof inputLocation?.image === "object" &&
                  isFilled &&
                  prevImage !== inputLocation?.image
                    ? URL.createObjectURL(inputLocation?.image)
                    : isFilled && typeof inputLocation?.image === "string"
                    ? fileBaseUrl + inputLocation?.image
                    : URL.createObjectURL(inputLocation?.image)
                }
              />
            ) : (
              <>
                <input
                  type="file"
                  hidden
                  accept=".png, .jpg, .jpeg"
                  ref={fileRef}
                  onChange={(e) =>
                    handleChangeInput("image", e.target.files[0])
                  }
                />
                <Icon icon={"document-upload"} color={"#2C5364"} size={"48"} />
                <div className={Styles.viewerCol}>
                  <span>Unggah Foto Pos</span>
                  <p>Unggah file dengan format .JPEG, PNG & JPG </p>
                </div>
                <Button
                  text="Browse File"
                  onClick={() => fileRef.current.click()}
                />
              </>
            )}
          </div>
        </div>
        <div className={Styles.inputField}>
          <InputField
            setValue={(v) => handleChangeInput("name", v)}
            textLabel="Nama Pos"
            isLabel
            isRequired
            placeholder="Masukkan nama Pos"
            value={inputLocation?.name}
          />
          <InputField
            setValue={(v) => handleChangeInput("personil", v)}
            textLabel="Jumlah Personil Jaga"
            isLabel
            isRequired
            placeholder="Minimal 1"
            isAddElement
            textElement="Personil"
            value={inputLocation?.personil}
          />
          <div className={Styles.inputOptional}>
            <label htmlFor="field-inventoris">
              Barang Inventaris Pos
              <span>*</span>
            </label>
            {inputLocation?.fieldPosInventory.map((el, idx) => (
              <div className={Styles.withIcon} key={idx}>
                <div style={{ display: "flex", width: "100%", gap: "8px" }}>
                  <InputField
                    setValue={(v) =>
                      handleChangeInput("nameInventory", v, el.id)
                    }
                    isRequired
                    placeholder={`Barang ${idx + 1}`}
                    value={inputLocation?.fieldPosInventory[idx].nameInventory}
                  />
                  <div style={{ width: "20%" }}>
                    <InputField
                      setValue={(v) => handleChangeInput("amount", v, el.id)}
                      isRequired
                      placeholder={`Jumlah`}
                      value={inputLocation?.fieldPosInventory[idx].amount}
                    />
                  </div>
                </div>
                <Icon
                  icon={"trash"}
                  color={idx === 0 ? "#BBC3CE" : "#D42701"}
                  onClick={() =>
                    idx === 0 ? () => {} : handleDeleteFieldInventory(el.id)
                  }
                  size={"24"}
                  style={{ cursor: idx === 0 ? "not-allowed" : "pointer" }}
                />
              </div>
            ))}

            <Button
              text="Tambah Barang"
              isAddButton
              isLeftIcon
              style={{
                background: "#fff",
                color: "#2C5364",
                border: "none",
                padding: "0",
              }}
              colorIcon={"#2C5364"}
              onClick={() => handleAddFieldInventory()}
            />
          </div>
          <InputField
            setValue={(v) => handleChangeInput("guardRadius", v)}
            textLabel="Radius Penjagaan"
            isLabel
            isRequired
            placeholder="Radius Penjagaan"
            isAddElement
            textElement="Meter"
            value={inputLocation?.guardRadius}
          />
          <InputField
            setValue={(v) => handleChangeInput("description", v)}
            textLabel="Deskripsi Lokasi"
            isLabel
            isRequired
            placeholder={`Contoh: "Lantai 1: Lobby Utama"`}
            value={inputLocation?.description}
          />
          <div className={Styles.containerMap}>
            <Map
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              isRadius
              radius={+inputLocation?.guardRadius}
              isAutoComplete
              coordinate={{
                latitude: selectedLocation.latitude,
                longitude: selectedLocation.longitude,
              }}
              zoom={18}
            />
          </div>
        </div>
        <div className={Styles.footerModal}>
          <Button
            text="Simpan Pos Jaga"
            isDisabled={isDisabled}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}
