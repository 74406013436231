import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "@Atom/Toast";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { fileBaseUrl } from "configs";
import { addWorkInfoLocation } from "@Services/WorkLocation/addLocation";
import useQuery from "@Hooks/useQuery";
import Button from "@Atom/Button";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import { getAllSchemaByClientCode } from "@Services/AttendenceSchema/getAllSchemaByClientCode";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import Spinner from "@Atom/Spinner";
import useWindowSize from "@Hooks/useWindowSize";

export default function ListLocation({ data }) {
  const { height } = useWindowSize();
  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: +data?.latitude || 0,
    longitude: +data?.longitude || 0,
  });
  const [isOpenAddLocation, setIsOpenAddLocation] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [inputLocation, setInputLocation] = useState({
    name: "",
    address: "",
    guardRadius: "",
    image: "",
    nameLead: "",
    leadPhoneNumber: "",
    schemaAbsence: { list: "", id: "" },
  });
  const fileRef = useRef(null);

  const [loadingPost, setLoadingPost] = useState(false);

  const [toastStatus, setToastStatus] = useState({
    success: false,
    error: false,
  });

  useEffect(() => {
    const requiredFields = [
      inputLocation.address,
      inputLocation.guardRadius,
      inputLocation.image,
      inputLocation.leadPhoneNumber,
      inputLocation.name,
      inputLocation.nameLead,
      // inputLocation.schemaAbsence,
      selectedLocation.latitude,
      selectedLocation.longitude,
    ];

    const isAnyFieldEmpty = requiredFields.some((field) => !field);
    setIsDisabledButton(isAnyFieldEmpty);
  }, [inputLocation, selectedLocation]);

  const [dataListDropdown, setDataListDropdown] = useState([
    {
      name: "",
      id: "",
    },
    {
      name: "",
      id: "",
    },
  ]);
  const [selectedDropdownID, setSelectedDropdownID] = useState();

  const getDataSchemaByClientCode = async () => {
    try {
      const res = await getAllSchemaByClientCode();
      setDataListDropdown(
        res.data.map((el) => ({
          list: el.name,
          id: el._id,
        }))
      );
    } catch (error) {
      console.log("error get schema", error);
    }
  };

  useEffect(() => {
    getDataSchemaByClientCode();
  }, []);

  const handleSubmit = async () => {
    setLoadingPost(true);
    setIsOpenAddLocation(false);
    const formData = new FormData();
    formData.append("name", inputLocation.name?.trim());
    formData.append("address", inputLocation.address?.trim());
    formData.append("pic[name]", inputLocation.nameLead?.trim());
    formData.append("pic[phoneNumber]", inputLocation.leadPhoneNumber?.trim());
    formData.append("radius", inputLocation.guardRadius?.trim());
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("client[ID]", CLIENT_KEY.id);
    formData.append("client[name]", CLIENT_KEY?.name);
    formData.append("client[code]", CLIENT_KEY?.code);
    formData.append("file", inputLocation.image);
    formData.append("schema[ID]", selectedDropdownID);
    formData.append("schema[name]", inputLocation.schemaAbsence.list);

    try {
      const res = await addWorkInfoLocation(formData);
      if (res.status === 201) {
        setToastStatus((prev) => ({
          ...prev,
          success: true,
        }));
        setLoadingPost(false);
        setInputLocation({});
        navigate(
          `${pathname}?page=${page}&limit=${limit}&clientCode=${CLIENT_KEY?.code}`,
          {
            replace: true,
          }
        );
      }
    } catch (error) {
      console.log(`error add location: ${error}`);
      setLoadingPost(false);
      setToastStatus((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  const limitQuery = query.get("limit");

  const page = 1;
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    navigate(
      `${pathname}?page=${page}&limit=${limit}&clientCode=${CLIENT_KEY?.code}`
    );
  }, [pathname, page, limit, navigate, CLIENT_KEY?.code]);

  const listInnerRef = useRef();
  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < data.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [data.totalData, limit]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  return (
    <>
      {(toastStatus.error || toastStatus.success) && (
        <Toast
          isopen
          text={
            toastStatus.error
              ? "Gagal Menambahkan Lokasi"
              : "Berhasil Menambahkan Lokasi"
          }
          color={toastStatus.error ? "red" : "green"}
          onClose={() =>
            setToastStatus((prev) => ({
              ...prev,
              error: false,
              success: false,
            }))
          }
        />
      )}
      {loadingPost && !isOpenAddLocation && (
        <LoadingSpinner text="Sedang menyimpan..." />
      )}

      {isOpenAddLocation && (
        <ModalAddLocation
          isOpen={isOpenAddLocation}
          onClose={() => setIsOpenAddLocation(false)}
          fileRef={fileRef}
          inputLocation={inputLocation}
          setInputLocation={setInputLocation}
          onSubmit={() => handleSubmit()}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          isDisabled={isDisabledButton}
          // dataListDropdown={[
          //   { list: "place 1", id: "123" },
          //   { list: "place2", id: "456" },
          // ]}
          dataListDropdown={dataListDropdown}
          setSelectedDropdownID={setSelectedDropdownID}
        />
      )}

      {data?.data?.length === 0 ? (
        <CustomEmptyHandlerPage
          title="Data Lokasi Kerja Belum Ditambahkan"
          description="Silahkan lakukan penambahan lokasi kerja"
          btnText="Tambah Lokasi Kerja"
          onClick={() => setIsOpenAddLocation(true)}
        />
      ) : (
        <div className={Styles.containerListLocation}>
          <div className={Styles.between}>
            <strong>Lokasi kerja</strong>
            <Button
              isAddButton
              text="Tambah Lokasi"
              onClick={() => setIsOpenAddLocation(true)}
              isLeftIcon
            />
          </div>
          <div
            className={Styles.cardLocation}
            onScroll={onScroll}
            ref={listInnerRef}
            style={{ maxHeight: "100%", overflowY: "auto" }}
          >
            {data?.data?.map((el, idx) => (
              <Fragment key={idx}>
                <div className={Styles.card}>
                  <div className={Styles.image}>
                    <img
                      alt="image-location"
                      src={fileBaseUrl + el?.imageURI}
                    />
                  </div>
                  <div className={Styles.description}>
                    <div className={Styles.personil}>
                      <strong>{capitalizeEachWord(el?.name)}</strong>
                      <div>
                        <Icon icon={"people"} className={Styles.peopleIcon} />
                        <span>{el?.members?.length || 0} Personil</span>
                      </div>
                    </div>
                    <div
                      className={Styles.btnCard}
                      onClick={() => navigate(`detail/${el?._id}`)}
                    >
                      <span>Lihat Detail</span>
                      <Icon icon={"arrow-back"} className={Styles.arrowIcon} />
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          {+limitQuery !== limit && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "1rem",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      )}
    </>
  );
}
