import MainLayout from "@Organism/Main";
import { SkeletonListGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/List";
import SkeletonDetailInfoLocation from "@Organism/Skeletons/WorkLocations/Information/Detail";
import { SkeletonListPatrolPoint } from "@Organism/Skeletons/WorkLocations/PatrolPoint/List";
import LoginPage from "@Pages/Auth/Login";
import AddEmployeePage from "@Pages/Employee/Add";
import ReportPage from "@Pages/Report";
import ReportActivityPage from "@Pages/Report/Activity";
import DetailReportActivityPage from "@Pages/Report/Activity/Detail";
import ReportIncidentPage from "@Pages/Report/Incident";
import DetailReportIncidentPage from "@Pages/Report/Incident/Detail";
import ReportPatrolPage from "@Pages/Report/Patrol";
import DetailReportPatrolPage from "@Pages/Report/Patrol/Detail";
import ReportSOSPage from "@Pages/Report/SOS";
import DetailReportSOSPage from "@Pages/Report/SOS/Detail";
import LocationPage from "@Pages/WorkLocation";
import { loader as getDataDetailGuardPost } from "@Pages/WorkLocation/GuardPos/Detail";
import loginLoader from "pages/Auth/Login";
import { loader as getDataDetailPatrolPoint } from "@Pages/WorkLocation/PatrolPoint/Detail";
import { loader as getAllDataSchedule } from "@Pages/WorkLocation/WorkSchedule/Detail";
import { loader as getDataWorkSchedulePage } from "@Pages/WorkLocation/WorkSchedule/List";
import DetailWorkSchedulePage from "@Pages/WorkSchedule/Detail";
import ListWorkSchedulePage from "@Pages/WorkSchedule/List";
import SettingWorkSchedulePage from "@Pages/WorkSchedule/Setting";
import { lazy, Suspense } from "react";
import { loader as getAllDataPatrolPoint } from "@Pages/WorkLocation/PatrolPoint/List";
import { loader as getAllDataInfoWorkLocation } from "@Pages/WorkLocation/ListLocation/List";
import { loader as getDataDetailInfoWorkLocation } from "@Pages/WorkLocation/ListLocation/Detail";
import { loader as getDataMembersPersonil } from "@Pages/WorkLocation/ListMembers";
import { loader as getAllDataGuardPost } from "@Pages/WorkLocation/GuardPos/List";
import ChooseClientPage from "@Organism/Client";
import PDFPreview from "@Organism/PDF/Preview";
import PrivacyPolicyPage from "@Pages/PrivacyPolicy";
import { SkeletonDetailGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/Detail";
import { SkeletonDetailPatrolPoint } from "@Organism/Skeletons/WorkLocations/PatrolPoint/Detail";
import { SkeletonListMembersLocation } from "@Organism/Skeletons/WorkLocations/ListMembers/List";
import { SkeletonListScheduleLocation } from "@Organism/Skeletons/WorkLocations/WorkSchedules/List";
import { loader as getAllDataPersonil } from "@Pages/Employee/List";
import SkeletonListEmployee from "@Molecule/Skeleton/Employee/ListEmployee";
import SettingsPage from "@Pages/Settings";
import { loader as getAllDataSchemaSetting } from "@Pages/Settings/Schema";
import { SkeletonSchemaSetting } from "@Organism/Skeletons/Settings/Schema";

const ListLocationPage = lazy(() =>
  import("@Pages/WorkLocation/ListLocation/List")
);
const DetailLocationPage = lazy(() =>
  import("@Pages/WorkLocation/ListLocation/Detail")
);
const ListGuardPosPage = lazy(() =>
  import("@Pages/WorkLocation/GuardPos/List")
);
const DetailGuardPosPage = lazy(() =>
  import("@Pages/WorkLocation/GuardPos/Detail")
);
const ListPatrolPointPage = lazy(() =>
  import("@Pages/WorkLocation/PatrolPoint/List")
);
const DetailPatrolPointPage = lazy(() =>
  import("@Pages/WorkLocation/PatrolPoint/Detail")
);
const ListMembersPage = lazy(() => import("@Pages/WorkLocation/ListMembers"));

const ListLocationWorkSchedulePage = lazy(() =>
  import("@Pages/WorkLocation/WorkSchedule/List")
);
const DetailLocationWorkSchedulePage = lazy(() =>
  import("@Pages/WorkLocation/WorkSchedule/Detail")
);

const ListEmployeePage = lazy(() => import("@Pages/Employee/List"));
const SchemaAbsenceSettingPage = lazy(() => import("@Pages/Settings/Schema"));

const { createBrowserRouter, redirect } = require("react-router-dom");

const router = createBrowserRouter([
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "pdf-preview",
    element: <PDFPreview />,
  },
  {
    path: "/login",
    loader: loginLoader,
    element: <LoginPage />,
  },
  {
    path: "client",
    element: <ChooseClientPage />,
  },
  {
    path: "/",
    loader: () => {
      if (!localStorage.getItem("accessToken")) {
        throw redirect("/login");
      }
      return null;
    },
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <h1>Halaman Dashboard</h1>,
        loader: () => {
          throw redirect("/work-location");
        },
      },
      {
        path: "detail-client",
        element: <h1>Halaman Detail Klien</h1>,
      },
      {
        path: "work-location",
        element: <LocationPage />,
        children: [
          {
            index: true,
            loader: getAllDataInfoWorkLocation,
            element: (
              <Suspense fallback={<SkeletonListGuardPos />}>
                <ListLocationPage />
              </Suspense>
            ),
          },
          {
            path: "detail/:id",
            children: [
              {
                index: true,
                loader: () => {
                  throw redirect("information");
                },
              },
              {
                path: "information",
                loader: getDataDetailInfoWorkLocation,
                element: (
                  <Suspense fallback={<SkeletonDetailInfoLocation />}>
                    <DetailLocationPage />
                  </Suspense>
                ),
              },
              {
                path: "guard-post",
                children: [
                  {
                    index: true,
                    loader: getAllDataGuardPost,
                    element: (
                      <Suspense fallback={<SkeletonListGuardPos />}>
                        <ListGuardPosPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":guardPostID",
                    loader: getDataDetailGuardPost,
                    element: (
                      <Suspense fallback={<SkeletonDetailGuardPos />}>
                        <DetailGuardPosPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "patrol-point",
                children: [
                  {
                    index: true,
                    loader: getAllDataPatrolPoint,
                    element: (
                      <Suspense fallback={<SkeletonListPatrolPoint />}>
                        <ListPatrolPointPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":patrolPointID",
                    loader: getDataDetailPatrolPoint,
                    element: (
                      <Suspense fallback={<SkeletonDetailPatrolPoint />}>
                        <DetailPatrolPointPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "list-members",
                children: [
                  {
                    index: true,
                    loader: getDataMembersPersonil,
                    element: (
                      <Suspense fallback={<SkeletonListMembersLocation />}>
                        <ListMembersPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "work-schedule",
                children: [
                  {
                    index: true,
                    loader: getDataWorkSchedulePage,
                    element: (
                      <Suspense fallback={<SkeletonListScheduleLocation />}>
                        <ListLocationWorkSchedulePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "detail/:month",
                    element: (
                      <Suspense fallback={<SkeletonListScheduleLocation />}>
                        <DetailLocationWorkSchedulePage />
                      </Suspense>
                    ),
                    loader: getAllDataSchedule,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "list-members",
        children: [
          {
            index: true,
            loader: getAllDataPersonil,
            element: (
              <Suspense fallback={<SkeletonListEmployee />}>
                <ListEmployeePage />
              </Suspense>
            ),
          },
          {
            path: "add-employee",
            element: <AddEmployeePage />,
          },
          {
            path: "team",
            element: <h1>Halaman Regu</h1>,
          },
        ],
      },
      {
        path: "work-schedule",
        children: [
          {
            index: true,
            element: <ListWorkSchedulePage />,
          },
          {
            path: "detail/:id",
            children: [
              {
                index: true,
                element: <DetailWorkSchedulePage />,
              },
              {
                path: "setting/:detailSettingID",
                element: <SettingWorkSchedulePage />,
              },
            ],
          },
        ],
      },
      {
        path: "report",
        element: <ReportPage />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("activity");
            },
          },
          {
            path: "activity",
            children: [
              {
                index: true,
                element: <ReportActivityPage />,
              },
              {
                path: "detail/:id",
                element: <DetailReportActivityPage />,
              },
            ],
          },
          {
            path: "patrol",
            children: [
              {
                index: true,
                element: <ReportPatrolPage />,
              },
              {
                path: "detail/:id",
                element: <DetailReportPatrolPage />,
              },
            ],
          },
          {
            path: "incident",
            children: [
              {
                index: true,
                element: <ReportIncidentPage />,
              },
              {
                path: "detail/:id",
                element: <DetailReportIncidentPage />,
              },
            ],
          },
          {
            path: "sos",
            children: [
              {
                index: true,
                element: <ReportSOSPage />,
              },
              {
                path: "detail/:id",
                element: <DetailReportSOSPage />,
              },
            ],
          },
        ],
      },
      {
        path: "content-manage",
        element: <h1>Halaman Pengelolaan Konten</h1>,
      },
      {
        path: "salary",
        element: <h1>Halaman Slip Gaji</h1>,
      },
      {
        path: "employee-benefit",
        element: <h1>Halaman Tunjangan Karyawan</h1>,
      },
      {
        path: "settings",
        element: <SettingsPage />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("company");
            },
          },
          {
            path: "company",
            element: <h1>Halaman pengaturan perushaan</h1>,
          },
          {
            path: "schema",
            loader: getAllDataSchemaSetting,
            element: (
              <Suspense fallback={<SkeletonSchemaSetting />}>
                <SchemaAbsenceSettingPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
