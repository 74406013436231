import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
export default function DetailWorkSchedule() {
  const navigate = useNavigate();
  const query = useQuery();
  const scheduleName = query.get("scheduleName");
  const [isOpenFilterListYear, setIsOpenFilterListYear] = useState(false);
  const template = useMemo(() => {
    return {
      data: [
        {
          id: 1,
          month: "Januari",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "-",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 2,
          month: "Februari",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "-",
          status: "Belum Lengkap",
        },
        {
          id: 3,
          month: "Maret",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "-",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 4,
          month: "April",
          amountInMorning: "-",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Belum Diatur",
        },
        {
          id: 5,
          month: "Mei",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "-",
          status: "Sudah Diatur",
        },
        {
          id: 6,
          month: "Juni",
          amountInMorning: "-",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 7,
          month: "Juli",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 8,
          month: "Agustus",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 9,
          month: "September",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Belum Diatur",
        },
        {
          id: 10,
          month: "Oktober",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 11,
          month: "November",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
        {
          id: 12,
          month: "Desember",
          amountInMorning: "2",
          amountInAfternoon: "16",
          amountInEvening: "3",
          amountOff: "10",
          amountPersonil: "23",
          status: "Sudah Diatur",
        },
      ],
      columns: [
        {
          name: "month",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Bulan" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.month}</span>
            </div>
          ),
        },
        {
          name: "amountInMorning",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Masuk Pagi" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInMorning}</span>
            </div>
          ),
        },
        {
          name: "amountInAfternoon",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Masuk Siang" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInAfternoon}</span>
            </div>
          ),
        },
        {
          name: "amountInEvening",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Masuk Malam" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInEvening}</span>
            </div>
          ),
        },
        {
          name: "amountOff",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Off" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountOff}</span>
            </div>
          ),
        },
        {
          name: "amountPersonil",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jml Anggota" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountPersonil}</span>
            </div>
          ),
        },
        {
          name: "status",
          width: "150px",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Status" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.status} status={row.status.toLowerCase()}>
              <span>{row?.status}</span>
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
  }, []);

  const refYear = useRef(null);
  const outsideClick = useOutsideClick(refYear);

  useEffect(() => {
    if (isOpenFilterListYear && outsideClick) {
      setIsOpenFilterListYear(false);
    }
  }, [isOpenFilterListYear, outsideClick]);

  const listYearFilter = [2024, 2025, 2026, 2027];
  const [selectedYear, setSelectedYear] = useState(listYearFilter[0]);

  return (
    <div className={Styles.maxCenteringContain}>
      <div className={Styles.wrapperListWorkSchedule}>
        <header className={`${Styles.header}`}>
          <div>
            <Icon
              icon={"arrow-chevron-left"}
              onClick={() => navigate("/work-schedule")}
              className={Styles.back}
            />
            <h3>{scheduleName}</h3>
          </div>
          <div
            className={Styles.selectedDate}
            onClick={() => setIsOpenFilterListYear(!isOpenFilterListYear)}
            is_open={isOpenFilterListYear.toString()}
          >
            <Icon icon={"calendar"} className={Styles.settings} />
            <span>{selectedYear}</span>
            {isOpenFilterListYear && (
              <div className={Styles.wrapFilterYear} ref={refYear}>
                {listYearFilter.map((el, idx) => (
                  <span
                    is_active={(el === selectedYear).toString()}
                    onClick={() => setSelectedYear(el)}
                    key={idx}
                  >
                    {el}
                  </span>
                ))}
              </div>
            )}
          </div>
        </header>
        <Table
          className={Styles.tableRadius}
          data={template.data}
          columns={template.columns}
          widthNumber="20px"
          onClickRow={(row) => navigate(`setting/${row.id}`)}
          expandComponent={(row) => (
            <div className={Styles.wrapperExpand}>
              <div className={Styles.listTableMobile}>
                <div className={Styles.totalVoucherM}>
                  <h2>Total Voucher</h2>
                  <p>{row?.totalVoucher}</p>
                </div>
                <di v className={Styles.priceM}>
                  <h2>Price</h2>
                  <p>{row?.price}</p>
                </di>
              </div>
              <div className={Styles.buttonGenerate}>Generate</div>
            </div>
          )}
        />
      </div>
    </div>
  );
}
